import React, { useEffect, useState } from "react";
import Breadcumb from "./components/Breadcumb";
import Loader from "./../../template/Loader";
import { useTranslation } from "react-i18next";

import { getBirthState, getState, getMunicipality } from "./../../model/booking";
import { Link } from "react-router-dom";
import { Table } from "react-virtualized";

const CardEmitValiDtata = (props) => {
  const { t, i18n } = useTranslation();

  const [birthState, setBirthState] = useState(false);
  const [state, setState] = useState(false);
  const [municipality, setMunicipality] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    populateData();
  }, []);

  const populateData = async () => {
    const _birthState = await getBirthState();
    const _state = await getState();
    const _municipality = await getMunicipality();

    setBirthState(_birthState.data);
    setState(_state.data);
    setMunicipality(_municipality.data);
  };

  return (
    <div className="has-background-warning p-3 resources">
      <Loader isLoading={isLoading} />

      <div className="container">
        <Breadcumb />

        <h2 className="title is-2">Caricamento massivo</h2>
        <h4 className="subtitle is-4">
          <span className="csvImporterInfo">{t("Dati validi per tipologia di campo.")} </span>
        </h4>

        <input className="input is-large mb-5" type="text" onChange={(e) => setFilter(e.target.value)} value={filter} placeholder="filtra" />

        <div className="columns">
          <div className="column">
            {birthState && (
              <table className="table is-narrow is-size-6 is-fullwidth">
                <th>Stati di nascita</th>
                {birthState
                  .filter((e) => e.denominazione.toLowerCase().includes(filter.toLowerCase()))
                  .map((item) => {
                    return (
                      <tr>
                        <td>{item.denominazione}</td>
                      </tr>
                    );
                  })}
              </table>
            )}
          </div>

          <div className="column">
            {state && (
              <table className="table is-narrow is-size-6 is-fullwidth">
                <th>Stati di residenza</th>
                {state
                  .filter((e) => e.denominazione.toLowerCase().includes(filter.toLowerCase()))
                  .map((item) => {
                    return (
                      <tr>
                        <td>{item.denominazione}</td>
                      </tr>
                    );
                  })}
              </table>
            )}
          </div>

          <div className="column">
            {municipality && (
              <table className="table is-narrow is-size-6 is-fullwidth">
                <th>Comuni di residenza</th>
                {municipality
                  .filter((e) => e.denominazione.toLowerCase().includes(filter.toLowerCase()))
                  .map((item) => {
                    return (
                      <tr>
                        <td>{item.denominazione}</td>
                      </tr>
                    );
                  })}
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardEmitValiDtata;
