import axios from "axios";
import config from "../config/config.json";
import _ from "lodash";
import { AppContext } from "./../store/AppContext";

const base = process?.env?.NODE_ENV === "development" ? config.devApiBaseUrl : config.apiBaseUrl;

const fetchAdminApi = axios.create();

fetchAdminApi.interceptors.request.use(async (axiosConfig) => {
  const localData = await window.localStorage.getItem(config.appContext);
  const localState = await JSON.parse(localData);

  axiosConfig.baseURL = base;
  axiosConfig.headers.authorization = `Bearer ${localState.token}`;
  return axiosConfig;
});

const getBaseConfig = async () => {
  try {
    const response = await fetchAdminApi.get("clubs/baseconfig");
    const data = response?.data;
    return data?.baseConfig;
  } catch (e) {
    console.log(e);
    if (e.response.status === 403) {
      alert("Login scaduto, effettua nuovamento l'accesso");
      window.localStorage.clear();
      window.location.reload();
    }
    return { status: e.response.status };
  } finally {
  }
};

const editBaseConfig = async (field, value) => {
  const body = { field, value };
  try {
    const response = await fetchAdminApi.post("clubs/baseconfig", body);
    return { status: "OK", message: response?.data?.message, baseConfig: response?.data?.baseConfig };
  } catch (e) {
    const error = e?.response?.data;
    const errors = _.values(error);
    const errorMessage = _.join(errors, " ");
    return { status: "KO", message: errorMessage };
  } finally {
  }
};

const uploadLogo = async (formData) => {
  try {
    const response = await fetchAdminApi.post("clubs/logo", formData, { headers: { "Content-Type": "multipart/form-data" } });
    return { status: "OK", message: response?.data?.message, baseConfig: response?.data?.baseConfig };
  } catch (e) {
    const error = e?.response?.data;
    const errors = _.values(error);
    const errorMessage = _.join(errors, " ");
    return { status: "KO", message: errorMessage };
  } finally {
  }
};

const getResources = async () => {
  try {
    const response = await fetchAdminApi.get("clubs/resources");
    const data = response?.data;
    return data?.resources;
  } catch (e) {
    console.log(e);
    return [];
  } finally {
  }
};

const addResource = async (name, description) => {
  const body = { name, description };
  try {
    const response = await fetchAdminApi.post("clubs/resource/", body);
    return { status: "OK", message: response?.data?.success };
  } catch (e) {
    const error = e?.response?.data;
    const errors = _.values(error);
    const errorMessage = _.join(errors, " ");
    return { status: "KO", message: errorMessage };
  } finally {
  }
};

const deleteResource = async (id) => {
  const params = { data: { resource_id: id } };
  try {
    const response = await fetchAdminApi.delete("clubs/resource/", params);
    if (response?.status === 200) {
      return { status: "OK", message: response?.data?.success };
    }
  } catch (e) {
    return { status: "KO", message: e?.response?.data?.generic };
  } finally {
  }
};

const editResource = async (name, description, resource_id) => {
  const body = { name, description, resource_id };
  try {
    const response = await fetchAdminApi.put("clubs/resource/", body);
    if (response?.status === 200) {
      return { status: "OK", message: response?.data?.success };
    }
  } catch (e) {
    console.log(e);
    const error = e?.response?.data;
    const errors = _.values(error);
    const errorMessage = _.join(errors, " ");
    return { status: "KO", message: errorMessage };
  } finally {
  }
};

const getServices = async (id) => {
  try {
    const response = await fetchAdminApi.get("clubs/services/" + id);
    const data = response?.data;
    return data;
  } catch (e) {
    console.log(e);
    if (e.response.status === 403) {
      return { status: "FORBIDDEN" };
    }
    return [];
  } finally {
  }
};

const addService = async (name, description, duration, min_pax, max_pax, min_age, max_age, max_total_pax, pax_price, resource_id) => {
  const body = { name, description, duration, min_pax, max_pax, min_age, max_age, max_total_pax, pax_price, resource_id };
  try {
    const response = await fetchAdminApi.post("clubs/service/", body);
    return { status: "OK", message: response?.data?.success };
  } catch (e) {
    const error = e?.response?.data;
    const errors = _.values(error);
    const errorMessage = _.join(errors, " ");
    return { status: "KO", message: errorMessage };
  } finally {
  }
};

const deleteService = async (id) => {
  const params = { data: { service_id: id } };
  try {
    const response = await fetchAdminApi.delete("clubs/service/", params);
    if (response?.status === 200) {
      return { status: "OK", message: response?.data?.success };
    }
  } catch (e) {
    return { status: "KO", message: e?.response?.data?.generic };
  } finally {
  }
};

const editService = async (name, description, duration, min_pax, max_pax, min_age, max_age, max_total_pax, pax_price, resource_id, service_id) => {
  const body = { name, description, duration, min_pax, max_pax, min_age, max_age, max_total_pax, pax_price, resource_id, service_id };
  try {
    const response = await fetchAdminApi.put("clubs/service/", body);
    if (response?.status === 200) {
      return { status: "OK", message: response?.data?.success };
    }
  } catch (e) {
    const error = e?.response?.data;
    const errors = _.values(error);
    const errorMessage = _.join(errors, " ");
    return { status: "KO", message: errorMessage };
  } finally {
  }
};

const getSlots = async (id) => {
  try {
    const response = await fetchAdminApi.get("clubs/slots/" + id);
    const data = response?.data;
    return data;
  } catch (e) {
    console.log(e);
    if (e.response.status === 403) {
      return { status: "FORBIDDEN" };
    }
    return [];
  } finally {
  }
};

const addSlot = async (service_id, start_date, end_date, recurrent, weekday, hours) => {
  const body = { service_id, start_date, end_date, recurrent: recurrent ? 1 : 0, weekday, hours };
  try {
    const response = await fetchAdminApi.post("clubs/slot/", body);
    return { status: "OK", message: response?.data?.success };
  } catch (e) {
    const error = e?.response?.data;
    const errors = _.values(error);
    const errorMessage = _.join(errors, " ");
    return { status: "KO", message: errorMessage };
  } finally {
  }
};

const deleteSlot = async (id) => {
  const params = { data: { slot_id: id } };
  try {
    const response = await fetchAdminApi.delete("clubs/slot/", params);
    if (response?.status === 200) {
      return { status: "OK", message: response?.data?.success };
    }
  } catch (e) {
    return { status: "KO", message: e?.response?.data?.generic };
  } finally {
  }
};

const fetchBookings = async (from, to) => {
  try {
    const response = await fetchAdminApi.get(`clubs/bookings?from=${from}&to=${to}`);
    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};

const exportBookings = async (from, to) => {
  try {
    const response = await fetchAdminApi.get(`clubs/bookings-export?from=${from}&to=${to}`);
    console.log(response);
    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "bookings.csv";
    link.click();

    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};

const fetchPrivacyUrl = async (member_id, booking_id) => {
  try {
    const response = await fetchAdminApi.get(`clubs/privacyUrl?member_id=${member_id}&booking_id=${booking_id}`);
    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};

const fetchEmitCard = async (cardsData) => {
  try {
    const response = await fetchAdminApi.post(`clubs/emitCard`, cardsData);
    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};

const exportBulkCards = async (from, to, affiliationCode = false) => {
  try {
    const response = await fetchAdminApi.get(`clubs/bulk-cards-export?from=${from}&to=${to}${affiliationCode ? '&affiliationCode='+affiliationCode : ''}`);

    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "cards.csv";
    link.click();

    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};

const getCardsList = async (from, to, affiliationCode = false) => {
  try {
    const response = await fetchAdminApi.get(`clubs/bulk-cards-export?from=${from}&to=${to}&format=json${affiliationCode ? '&affiliationCode='+affiliationCode : ''}`);

    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};

const getPaymentsList = async (from, to) => {
  try {
    const response = await fetchAdminApi.get(`clubs/paymentsList?from=${from}&to=${to}&format=json`);

    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};

const editCard = async (cardData) => {
  try {
    const response = await fetchAdminApi.post(`clubs/editCard`, cardData);
    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};

const deleteCard = async (cardId) => {
  const params = { data: { cardId } };
  try {
    const response = await fetchAdminApi.delete(`clubs/editCard`, params);
    if (response.status === 200) {
      return { status: "OK", data: response.data };
    } else {
      return { status: "KO", error: response.data.error };
    }
  } catch (e) {
    return { status: "KO", error: e?.response?.data.error };
  }
};




export {
  fetchAdminApi,
  getBaseConfig,
  editBaseConfig,
  uploadLogo,
  getResources,
  addResource,
  editResource,
  deleteResource,
  getServices,
  addService,
  deleteService,
  editService,
  getSlots,
  addSlot,
  deleteSlot,
  fetchBookings,
  exportBookings,
  fetchPrivacyUrl,
  fetchEmitCard,
  exportBulkCards,
  getCardsList,
  getPaymentsList,
  editCard,
  deleteCard
};
