import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "./../store/AppContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loader from "./Loader";
import AdmHead from "./AdmHead";
import AdmFoot from "./AdmFoot";
import GuestFoot from "./GuestFoot";
import GuestHead from "./GuestHead";
import Home from "./Home";
import Error404 from "./Error404";
import Login from "./../pages/auth/Login";
import Logout from "./../pages/auth/Logout";
import LostPassword from "./../pages/auth/LostPassword";
import ChangePassword from "./../pages/auth/ChangePassword";
import Resources from "./../pages/admin/Resources";
import CardEmit from "./../pages/admin/CardEmit";
import CardsList from "./../pages/admin/CardsList";
import PaymentsList from "./../pages/admin/PaymentsList";
import CardEmitValiData from "./../pages/admin/CardEmitValidData";
import Services from "./../pages/admin/Services";
import BookingsList from "./../pages/admin/BookingsList";
import Slots from "./../pages/admin/Slots";
import Dashboard from "./../pages/admin/Dashboard";
import Booking from "./../pages/booking/Booking";
import Register from "./../pages/register/Register";
import Card from "./../pages/card/Card";
import Privacy from "./../pages/privacy/Privacy";
import ManageBooking from "./../pages/manage_booking/ManageBooking";
import UtentiPrenotazione from "./../pages/terms/UtentiPrenotazione";
import TesseratiTuristiAmatori from "./../pages/terms/TesseratiTuristiAmatori";
import Tutori from "./../pages/terms/Tutori";

const Main = () => {
  const { context } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    setIsLoading(false);
  },[])

  if(isLoading) return <Loader isLoading={isLoading} />;

  return (
    <div className="App">
      <Router>
        { (context.token === false || context.token === undefined) ? (
          // GUEST
          <>
            <Route path="/register/*" component={GuestHead} />
            <Route path="/book/*" component={GuestHead} />
            <Route path="/card/*" component={GuestHead} />
            <Route path="/terms/*" component={GuestHead} />
            <Route path="/privacy/*" component={GuestHead} />
            <Route path="/manage-booking/*" component={GuestHead} />

            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/admin/login/:type?" component={Login} />
              <Route path="/admin/lostpassword" component={LostPassword} />
              <Route path="/admin/changepassword/:passwordToken?" component={ChangePassword} />
              <Route path="/admin/*" component={Login} />
              <Route path="/register/:slug?/:registration_slug?" component={Register} />
              <Route path="/book/:slug?" component={Booking} />
              <Route path="/card/:nome/:cognome/:uuid" component={Card} />
              <Route path="/privacy/:token" component={Privacy} />
              <Route path="/manage-booking/:id" component={ManageBooking} />
              <Route path="/terms/utenti-prenotazione" component={UtentiPrenotazione} />
              <Route path="/terms/tesserati-turisti-amatori" component={TesseratiTuristiAmatori} />
              <Route path="/terms/tutori" component={Tutori} />
              <Route path="*" component={()=><Error404 mode="guest" />} />
            </Switch>
            <Route path="/register/*" component={GuestFoot} />
            <Route path="/book/*" component={GuestFoot} />
            <Route path="/manage-booking/*" component={GuestFoot} />
          </>
          // GUEST
        ) : (
          // ADMIN
          <>
            <Route path="/register/*" component={GuestHead} />
            <Route path="/admin/*" component={AdmHead} />
            <Route path="/book/*" component={GuestHead} />
            <Route path="/card/*" component={GuestHead} />
            <Route path="/terms/*" component={GuestHead} />
            <Route path="/privacy/*" component={GuestHead} />
            <Route path="/manage-booking/*" component={GuestHead} />
            

            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/admin/cardsList" component={CardsList} />
              <Route path="/admin/paymentsList" component={PaymentsList} />
              <Route path="/admin/cardEmit" component={CardEmit} />
              <Route path="/admin/cardEmitValidData" component={CardEmitValiData} />
              <Route path="/admin/dashboard/:message?" component={Dashboard} />
              <Route path="/admin/resources" component={Resources} />
              <Route path="/admin/services/:resource_id" component={Services} />
              <Route path="/admin/slots/:service_id" component={Slots} />
              <Route path="/admin/bookings" component={BookingsList} />
              <Route path="/admin/logout" component={Logout} />
              <Route path="/admin/login/:type?" component={Login} />
              <Route path="/book/:slug?" component={Booking} />
              <Route path="/register/:slug?/:registration_slug?" component={Register} />
              <Route path="/privacy/:token" component={Privacy} />
              <Route path="/card/:nome/:cognome/:uuid" component={Card} />
              <Route path="/manage-booking/:id" component={ManageBooking} />
              <Route path="/terms/utenti-prenotazione" component={UtentiPrenotazione} />
              <Route path="/terms/tesserati-turisti-amatori" component={TesseratiTuristiAmatori} />
              <Route path="/terms/tutori" component={Tutori} />
              <Route path="*" component={()=><Error404 mode="admin" />} />
            </Switch>
            <Route path="/register/*" component={GuestFoot} />
            <Route path="/admin/*" component={AdmFoot} />
            <Route path="/book/*" component={GuestFoot} />
            <Route path="/manage-booking/*" component={GuestFoot} />
          </>
          // ADMIN
        )}
      </Router>
    </div>
  );
};

export default Main;

// switch render esclusivo (solo quel componente)
// altrimenti inclusico
