import React, { useContext } from "react";
import {AppContext} from "./../../store/AppContext"

const Logout = () => {
  const {context, dispatch} = useContext(AppContext);

  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch({type: "CLEAR_TOKEN"})

  };

  return (
      <div className="hero is-fullheight is-link">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              {/* <h3 className="title has-text-white">Login</h3>
              <hr className="login-hr" />
              <p className="subtitle has-text-white">Please login to see our cool stuff!</p> */}
              <div className="box">
                <img src="/img/firaft-logo.png" className="authLogo" alt="logo" />
                <p className="title has-text-grey is-5">Clicca per effettuare il logout</p>
                <form>
                  <button className={`button is-block is-danger is-medium is-fullwidth`} onClick={handleLogout}>
                    Logout
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Logout;
