import React, { useState, useEffect } from "react";
import Error404 from "./../../template/Error404";
import { fetchConfig, fetchResources, fetchServices } from "./../../model/booking";
//CALENDAR
import BookingCalendar from "./components/BookingCalendar";
import BookingForm from "./components/BookingForm";
import BookingPayment from "./components/BookingPayment";
import Loader from "./../../template/Loader";

import { useTranslation } from 'react-i18next';
//CALENDAR
//import dayjs from "dayjs";

const Booking = (props) => {

  const { t } = useTranslation();

  const [club, setClub] = useState(false);

  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState(-1);
  const [isOpenResources, setIsOpenResources] = useState(false);

  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(-1);
  const [isOpenService, setIsOpenService] = useState(false);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [bookingData, setBookingData] = useState(false);
  const [membersData, setMembersData] = useState(false);
  const [appConfig, setAppConfig] = useState({});

  const handleFetchConfig = async () => {
    let response = await fetchConfig();
    setAppConfig(response.data);
  };

  useEffect(() => {
    if (props.match?.params?.slug) {
      handleFetchConfig();
    }
  }, [props.match?.params?.slug]);

  const handleGetResources = async (slug) => {
    let response = await fetchResources(slug);
    if (response.status === "OK") {
      setResources(response.data.resources);
      setClub(response.data.club);   
    } else {
      setClub(-1);
      setResources([]);
    }
  };

  const handleGetServices = async (slug) => {
    setIsOpenService(false);
    setSelectedService(-1);
    setIsLoadingServices(true);
    let response = await fetchServices(slug, resources[selectedResource].resource_id);
    if (response.status === "OK") {
      setServices(response.data.services);
    } else {
      setServices([]);
    }
    setIsLoadingServices(false);
  };

  const onChangeResources = (i) => {
    // setSelectedService(-1);
    // setServices([]);
    setSelectedResource(i);
    setIsOpenResources(false);
  };

  const onChangeService = (i) => {
    setSelectedService(i);
    setIsOpenService(false);
  };

  useEffect(() => {
    if (props.match?.params?.slug) {
      handleGetResources(props.match.params.slug);
    }
  }, [props.match?.params?.slug]);

  useEffect(() => {
    if (selectedResource !== -1) {
      handleGetServices(props.match.params.slug);
    }
  }, [selectedResource]);

  const openBookingForm = (slot) => {
    setBookingData({ service: services[selectedService], resource: resources[selectedResource], slot });
  };

  const Dropdown = ({ options, selected, isOpen, toggle, onChange, emptyText, type }) => {
    return (
      <div className={`dropdown ${isOpen && "is-active"}`}>
        <div className="dropdown-trigger" onClick={toggle}>
          <button className="button" aria-haspopup="true" aria-controls="dropdown-menu2">
            {selected !== -1 ? (
              <span className="is-ellipsis">
                {options[selected].name}
                {/* {options[selected].description} */}
              </span>
            ) : (
              <span>{emptyText}</span>
            )}
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu2" role="menu">
          <div className="dropdown-content">
            {(options || []).map((item, index) => {
              return (
                <div key={item.name + "-" + index} className="dropdown-item" onClick={() => onChange(index)}>
                  <p className={"has-text-weight-semibold has-text-primary"}>{item.name}</p>
                  <p className={"has-text-grey"}>{item.description}</p>
                  <code className={"has-text-grey"}>#{type === "resource" ? item.resource_id : item.service_id}</code>
                  <hr className="dropdown-divider" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const ResourceAndServiceSelector = ({ itemClassName }) => {
    return (
      <>
        <div className={itemClassName}>
          <p className="title has-text-grey is-5">{t("Scegli risorsa")}</p>
          <Dropdown
            type={"resource"}
            emptyText={t("Scegli risorsa")}
            options={resources}
            isOpen={isOpenResources}
            toggle={() => setIsOpenResources(!isOpenResources)}
            selected={selectedResource}
            onChange={onChangeResources}
          />
        </div>

        {selectedResource !== -1 && (
          <div className={itemClassName}>
            <p className="title has-text-grey is-5">{t("Scegli servizio")}</p>
            {isLoadingServices ? (
              <button className="button is-fullwidth is-loading">loading</button>
            ) : services.length > 0 ? (
              <Dropdown
                type={"service"}
                emptyText={t("Scegli servizio")}
                options={services}
                isOpen={isOpenService}
                toggle={() => setIsOpenService(!isOpenService)}
                selected={selectedService}
                onChange={onChangeService}
              />
            ) : (
              <p className="title has-text-grey is-6">{t("Nessun servizio per questa risorsa")}</p>
            )}
          </div>
        )}
      </>
    );
  };

  const clearMembersOnly = () => {
    setMembersData(false);
  };

  const clearBooking = () => {
    setBookingData(false);
    setMembersData(false);
  };

  const handleSetMembersData = (data) => {
    setMembersData(data);
  };

  if (!props.match.params.slug) return <Error404 />;

  if (club === -1) return <Error404 />;
  
  if (bookingData !== false && membersData !== false)
    return (
      <BookingPayment
        appConfig={appConfig}
        bookingData={bookingData}
        membersData={membersData}
        clearBooking={clearBooking}
        clearMembersOnly={clearMembersOnly}
      />
    );
  if (bookingData !== false)
    return <BookingForm appConfig={appConfig} bookingData={bookingData} clearBooking={clearBooking} handleSetMembersData={handleSetMembersData} />;

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="column is-12">
            {club !== false ? (
              <>
                <div className={"is-flex is-flex-direction-row is-align-items-center"}>
                  {club?.logoUrl !== null && (
                    <>
                      <img src={club.logoUrl} alt="logo" style={{ width: 32, marginRight: 10 }} />{" "}
                    </>
                  )}
                  <h2 className="subtitle is-3">{club?.name}</h2>
                </div>
                {club?.display_name && <h3 className="subtitle is-5">{club?.display_name}</h3>}
                {club?.info_text && <p className="has-text-grey">{club?.info_text}</p>}
              </>
            ) : (
              <div className="loader-wrapper-parent">
                <Loader isLoading={true} />
              </div>
            )}

            <hr style={club?.primary_color !== null ? { backgroundColor: club.primary_color } : { backgroundColor: "#999" }} />

            <div className="columns">
              <ResourceAndServiceSelector itemClassName="column is-6" />
            </div>
            <div className="columns">
              <div className="column is-12">
                {selectedService !== -1 && (
                  <BookingCalendar slug={props.match.params.slug} service_id={services[selectedService].service_id} openBookingForm={openBookingForm} reservationCutoffHours={club?.reservation_cutoff_hours !== null ? parseInt(club?.reservation_cutoff_hours) : 2 } />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
