const legal_booking_confirmation_de = 
`
Der/die Unterfertigte erklärt mit Versand des vorliegenden Formulars, dass
- 1. Er/sie von den Organisatoren vorab über die Besonderheiten und Risiken im Zusammenhang mit Tätigkeiten in Flüssen unterrichtet worden ist und Absicht zum Ausdruck gebracht hat, willentlich und auf eigenem Risiko teilnehmen zu wollen. Hiermit wird bestätigt, dass er/sie informiert worden ist und alle technischen und verhaltensbezogenen ( [theoretische Videostunde](https://www.youtube.com/watch?v=llxAPuH_JwU) ) Anweisungen erhalten wird, die in Notsituationen zu befolgen sind, einschließlich der Möglichkeit vor Durchführung der Tätigkeit, im Fluss schwimmen zu müssen, auch wenn nur zum alleinigen Zweck der Ausbildung. Der/die Unterfertigte verpflichtet sich dem Bootsführer („Guide“) mitzuteilen, falls Anweisungen nicht verstanden worden sind;
- 2. Er/sie keinerlei Kenntnis einer Pathologie hat, welche eine Kontraindikation für die Tätigkeit darstellen könnte, für welche eine Tesserierung angefragt worden ist;
- 3. Er/sie sich verpflichtet, der Unterrichtung, die vor Beginn der Flussaktivität stattfinden wird und die nach unbestreitbarem Urteil des Technikers als ungeeignet für das Einsteigen angesehen werden kann, größte Aufmerksamkeit zu schenken, wenn davon ausgegangen wird, dass er die technischen Verhaltensanweisungen nicht verstanden hat und/oder dass er/sie diese nicht in die Praxis umsetzen könnte;
- 4. Er/sie darüber informiert wurde, dass er/sie mit der spezifischen Sicherheitsausrüstung ausgestattet wird, die von der W.R.F. (World Rafting Federation) und den Selbstregulierungsvorschriften der F.I.Raft (Italienischer Raftingverband), welche beim Sitz der Organisation ausgestellt wird;
- 5. Er/sie umfassend über den Inhalt dieser Erklärung informiert worden ist, diese sorgfältig gelesen und freiwillig aus eigenem Willen unterzeichnet hat;
- 6. Er/sie sich von jeglicher Haftung und Schadensersatzansprüchen gegenüber dem Italienischen Raftingverband, den mit der F.I.Raft verbundenen Vereinen und dessen Technikern (Bootsführern, Trainern) entzieht, außer im Falle eines nachgewiesenen und offensichtlichen Fehlverhaltens oder grober Fahrlässigkeit, gegenüber ihm/ihr für physische und materielle Schäden, die vor, während und nach dem Abstieg entstanden sind, auch wenn dies auf die Tatsache Dritter oder anderer Teilnehmer zurückzuführen ist.

In Anbetracht der bisherigen Ausführungen und mit Versand dieses Formulars (verpflichtende Voraussetzung für die Teilnahme an der gebuchten sportlichen Tätigkeit) unterzeichnet man die in den Ziffern 1 bis 6 beschriebenen Inhalt gemäß Artikel 1341 ff. des Italienischen Zivilgesetzbuches
`;

const legal_booking_confirmation_underage_de = 
`
**Der/die Unterzeichnende erklärt in seiner/ihrer Eigenschaft als Erziehungsberechtigter** mit der Übersendung dieses
Formulars, **dass er/sie**:
- 1. im Namen und im Auftrag des Minderjährigen **zu unterschreiben, wie im Mitgliedsantrag und/oder in der
informierten Zustimmung zu der oben genannten Aktivität angegeben**;
- 2. in die Verarbeitung ihrer personenbezogenen Daten gemäß Artikel 13 der DSGVO einzuwilligen, nachdem sie die
 [**Informationen**](https://firaftbooking.it/terms/tutori) in der unter Punkt 1 angegebenen Weise und zu den dort
genannten Zwecken gelesen haben.
In Anbetracht der bisherigen Ausführungen und mit Versand dieses Formulars (verpflichtende Voraussetzung für die
Teilnahme an der gebuchten sportlichen Tätigkeit) unterzeichnet man die in den Ziffern 1 und 2 beschriebenen Inhalt gemäß
Artikel 1341 ff. des Italienischen Zivilgesetzbuches.
`;

module.exports = {legal_booking_confirmation_de, legal_booking_confirmation_underage_de};
