import axios from "axios";
import config from "../config/config.json";
import _ from "lodash";

const base = process?.env?.NODE_ENV === "development" ? config.devApiBaseUrl : config.apiBaseUrl;

const fetchBookingApi = axios.create();
fetchBookingApi.interceptors.request.use(async (axiosConfig) => {

  const localData = await window.localStorage.getItem(config.appContext);
  const localState = await JSON.parse(localData);
  const language = _.isUndefined(localState.language) ? "it" : localState.language;

  axiosConfig.baseURL = base;
  axiosConfig.headers.language = language;

  return axiosConfig;
});

const devOnlyBookingApi = axios.create();
devOnlyBookingApi.interceptors.request.use(async (axiosConfig) => {

  const localData = await window.localStorage.getItem(config.appContext);
  const localState = await JSON.parse(localData);
  const language = _.isUndefined(localState.language) ? "it" : localState.language;

  axiosConfig.baseURL = config.devApiBaseUrl;
  axiosConfig.headers.language = language;

  return axiosConfig;
});


const fetchConfig = async(slug)=>{
  try{
    const response = await fetchBookingApi.get(`booking/config`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}


const fetchResources = async(slug)=>{
  try{
    const response = await fetchBookingApi.get(`booking/${slug}/resources`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}

const fetchServices = async(slug, resource_id)=>{
  try{
    const response = await fetchBookingApi.get(`booking/${slug}/services/${resource_id}`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}


const fetchEvents = async(slug, service_id, from, to)=>{
  try{
    const response = await fetchBookingApi.get(`booking/${slug}/slots/${service_id}?from=${from}&to=${to}`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}

const getBirthState = async(members)=>{
  const response = await fetchBookingApi.post('_cnnp', {method: "GET", url:"/tesseramento/stati_nascita"});
  try{
    let _data = [{denominazione:"ITALIA"},...response.data.data];
    return {status: "OK", data: _data};    
  }catch(e){
    return {status: "KO", error: e?.response?.data.data.error}
  }  
}

const getState = async(members)=>{
  const response = await fetchBookingApi.post('_cnnp', {method: "GET", url:"/tesseramento/stati_residenza"});
  try{
    return {status: "OK", data: response.data.data};    
  }catch(e){
    return {status: "KO", error: e?.response?.data.data.error}
  }  
}

const getMunicipality = async(members)=>{
  const response = await fetchBookingApi.post('_cnnp', {method: "GET", url:"/tesseramento/comuni_residenza"});
  try{
    return {status: "OK", data: response.data.data};    
  }catch(e){
    return {status: "KO", error: e?.response?.data.data.error}
  }  
}

const validateSubscriptions = async(members)=>{

  let requests =[];
  for(let i=0; i < members.length; i++){
    let params = _.map(members[i], (value, key) => `${key}=${value}`).join("&");
    requests.push( fetchBookingApi.post('_cnnp', {method: "GET", url:"/tesseramento/tesserati?"+params}) )
  }
  
  const responseArr = await axios.all(requests);
  
  let responseArrFormatted = []
  for(let i=0; i < responseArr.length; i++){
    if (responseArr[i].data.status !== 200 ){
      responseArrFormatted.push({status:"KO", message: responseArr[i].data.data})
    }else{
      responseArrFormatted.push({status:"OK", alreadyMember: responseArr[i].data.data.includes("non tesserata") ? false : true})
    }
  }

  return responseArrFormatted;

}


const fetchPaymentIntent = async(body)=>{
  try{
    const response = await fetchBookingApi.post(`payments/paymentIntent`,{body});
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}

const saveCard = async(body)=>{
  try{
    const response = await fetchBookingApi.post(`cards`,body);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}


const fetchActiveBooking = async(uuid)=>{
  try{
    const response = await fetchBookingApi.get(`booking/-rl-/${uuid}`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}

const cancelActiveBooking = async(uuid)=>{
  try{
    const response = await fetchBookingApi.get(`booking/-rl-/cancel/${uuid}`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}



const fakePayment = async(body)=>{
  try{
    const response = await devOnlyBookingApi.post(`payments/webhook`, body);
    if(response.status === 200){
      return {status: "OK" };
    }else{
      return {status: "KO" };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}


export {fetchPaymentIntent, fetchConfig, fetchResources, fetchServices, fetchEvents, getBirthState, getState, getMunicipality, validateSubscriptions, saveCard, fetchActiveBooking, cancelActiveBooking, fakePayment};