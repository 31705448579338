import React from "react";

const Forbidden = (props) => {
  
  return (
    <div className="hero is-fullheight is-warning">
      <div className="hero-body">
      <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              <h2 className="title is-2">Non sei autorizzato ad accedere all'attività</h2>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Forbidden;
