import React from "react";
import { Link } from "react-router-dom";

const Breadcumb = (props) => {
  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <Link to="/admin/dashboard">
            <span className="icon">
              <i className="fas fa-home" aria-hidden="true"></i>
            </span>
          </Link>
        </li>
        <li>
          <Link to="/admin/resources">Attività</Link>
        </li>
        {props.service && (
          <li>
            <Link to={`/admin/services/${props.service.resource_id}`}>Attività #{props.service.resource_id}</Link>
          </li>
        )}

        {props.slot && (
          <li>
            <Link to={`/admin/slots/${props.slot.service_id}`}>Servizio #{props.slot.service_id}</Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Breadcumb;
