import React, { useEffect, useState, Suspense } from "react";
import Breadcumb from "./components/Breadcumb";
import Loader from "./../../template/Loader";
import { useTranslation } from "react-i18next";
import { CSVReader } from "react-papaparse";
import dayjs from "dayjs";
import { getResources, addResource, editResource, deleteResource, fetchEmitCard, exportBulkCards } from "./../../model/admin";
import { Link } from "react-router-dom";

//import SyntaxHighlighter from 'react-syntax-highlighter';
import { atelierSeasideLight, vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';
const SyntaxHighlighter = React.lazy(() => import('react-syntax-highlighter'));


const CardEmit = (props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [theResults, setTheResults] = useState([]);
  const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {}, []);

  const exportCsv = async (e) => {
    e.preventDefault();

    try {
      let csvData = `Info: 1 utente per riga (max 50 per foglio) - gli utenti in eccesso rispetto al massimo consentito in questo servizio non verranno importati\n#Email,#Nome,#Cognome,#Data di nascita (es:21/12/1970),#Residente in Italia (si/no),#Codice Fiscale (solo se res Italia),#Sesso (F/M se non presente Codice Fiscale),#Stato di nascita (se non residente in Italia), #Indirizzo, #Comune, #Cap (formatta celle come testo se necessario), #Stato residenza, #Consenso al tesseramento e all’attività già acquisito (si/no), #Consenso marketing già acquisito (si/no), Responsabile minore nome (Se minorenne), #Responsabile minore cognome, #Telefono`;

      const type = "text/csv; charset=utf-8";
      const blob = new Blob([csvData], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "cardEmit.csv";
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

  const handleBeforeFirstChunk = (data) => {
    setSkip(false);
    setTheResults([]);
  };

  const handleOnComplete = async () => {};

  const handleOnDrop = async () => {};

  const handleOnStep = async (parsed, parser) => {
    parser.pause();

    const raw = parsed.data;

    if (raw[0].startsWith("Info: ") || raw[0].startsWith("#Email")) {
      parser.resume();
      return;
    }else if(raw[0].length < 1){
      setTheResults((_theResults) => [{ error: "Riga vuota, meglio evitarla nei prossimi csv" }, ..._theResults]);
      parser.resume();
      return;
    }

    const keys = [
      "email",
      "nome",
      "cognome",
      "data_nascita",
      "nationalita_italiana",
      "codice_fiscale",
      "sesso",
      "stato_nascita",
      "indirizzo",
      "comune",
      "cap",
      "stato_residenza",
      "consenso_e_privacy_espressi",
      "consenso_marketing_espresso",
      "responsabile_minore_nome",
      "responsabile_minore_cognome",
      "telefono"
    ];
    let cardsData = {};
    raw.map((item, i) => {
      if (item.startsWith("\n")){item=item.replace('\n', '')};
      cardsData[keys[i]] = item;
    });
    console.log(raw.length, keys.length, raw.length > keys.length);
    if (raw.length > keys.length) {
      setTheResults((_theResults) => [{ error: "Errore di compilazione, ricorda che le virgole (,) non sono supportate nel formato csv, verifica l'indirizzo e rimuovi le virgole." }, ..._theResults]);
    }else if(cardsData.data_nascita.length !== 10){
      setTheResults((_theResults) => [{ error: "Errore formato data di nascita" }, ..._theResults]);
    }else {

      cardsData.straniero = cardsData.nationalita_italiana.trim().toLowerCase() === "si" ? 0 : cardsData.nationalita_italiana.toLowerCase() === "no" ? 1 : undefined;
      cardsData.sesso = cardsData.sesso.toUpperCase();
      cardsData.consenso_e_privacy_espressi =
      cardsData.consenso_e_privacy_espressi.trim().toLowerCase() === "si" ? 1 : cardsData.consenso_e_privacy_espressi.toLowerCase() === "no" ? 0 : undefined;
      cardsData.consenso_marketing_espresso =
      cardsData.consenso_marketing_espresso.trim().toLowerCase() === "si" ? 1 : cardsData.consenso_marketing_espresso.toLowerCase() === "no" ? 0 : undefined;
      cardsData.privacy_commerciale = cardsData.consenso_marketing_espresso;
      cardsData.data_nascita = dayjs(cardsData.data_nascita.split("/").reverse().join("-")).format("YYYY-MM-DD");
  
      let response = false;
      if (cardsData.consenso_attivita_espresso) {
        response = await fetchEmitCard(cardsData);
      } else {
        response = await fetchEmitCard(cardsData);
      }
      cardsData.rowIndex = rowIndex;

      // response = {status:"OK", data:{cardsData}}
      if (response) {
        response.cardsData = cardsData;
        setTheResults((_theResults) => [response, ..._theResults]);
      }
    }

    setRowIndex(rowIndex+1);
    await sleep(500);
    parser.resume();

  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleExport = async () =>{
    await exportBulkCards(dayjs().startOf('year').format('YYYY-MM-DD'), dayjs().endOf('year').format('YYYY-MM-DD'));
  }

  return (
    <div className="has-background-warning p-3 resources">
      <Loader isLoading={isLoading} />

      <div className="container">
        <Breadcumb />

        <h2 className="title is-2">Caricamento massivo        
        </h2>
        <h4 className="subtitle is-5">
          <span className="csvImporterInfo">
            {"Utilizza un csv per tesserare:"}{" "}
            <a href="#" onClick={exportCsv} className="has-text-weight-bold">
              {i18n.t("scarica il template")} <i className="fas fa-file-csv"></i>
            </a>
          </span>
          {" | "} 
          <span className="csvImporterInfo ">
            {" esporta il "}
            <a href="#" onClick={handleExport} className="has-text-success has-text-weight-bold">
              {i18n.t("report")} <i className="fas fa-list-ol"></i>
            </a>
          </span>
        </h4>
        <div>
          <b>Istruzioni</b><br/>
          In fase di caricamento massivo sono previste due opzioni:
          <div className="content pb-5">
          <ol type="A">
            <li>
              al caricamento del file (contenente i dati necessari) <b>segue</b> l'invio di un'email a ciascun interessato attraverso la quale si acquisirà la sottoscrizione del "tesseramento" e del "consenso informato all'attività" e successivamente si emetterà automaticamente la tessera federale; a tal fine indicare "<b>No</b>" per "<b>Consenso al tesseramento e all’attività già acquisito</b>" nel file .csv per i due consensi non acquisiti.
            </li>
            <li>
              al cacicamento del file (contenente i dati necessari) <b>non segue</b> alcuna raccolta del consenso ma esclusivamente l'emissione della tessera in quanto la sottoscrizione del "tesseramento" e del "consenso informato all'attività" sono già stati acquisiti dall'Affiliato con proprie modalità; a tal fine indicare "<b>Si</b>" per "<b>Consenso al tesseramento e all’attività già acquisito</b>" nel file .csv per due consensi se già acquisiti.
            </li>
          </ol>
          </div>
        </div>

        <div className="pb-5">
          <b>IMPORTANTE</b><br/>
          File csv, delimitato da virgola, senza righe vuote.
        </div>
        
        <h5 className="subtitle is-6">
          <Link to="/admin/cardEmitValidData" target="_blank">
            {i18n.t("Lista stati di nascita, di residenza e comuni accettati")} <i className="fas fa-table"></i>
          </Link>
        </h5>

        <CSVReader
          onDrop={handleOnDrop}
          onError={handleOnError}
          isReset={isReset}
          style={{
            dropArea: {
              borderColor: "#03a9f4",
              borderRadius: 5,
              padding: "10px",
            },
            dropAreaActive: {
              borderColor: "red",
            },
            dropFile: {
              width: "100%",
              height: 100,
              background: "#03a9f4",
              borderRadius: 5,
            },
            fileSizeInfo: {
              color: "#fff",
              backgroundColor: "transparent",
              lineHeight: 1,
              marginBottom: "0.5em",
              padding: "0 0.4em",
              fontSize: 12,
            },
            fileNameInfo: {
              color: "#fff",
              backgroundColor: "transparent",
              borderRadius: 3,
              fontWeight: "bold",
              fontSize: 15,
              lineHeight: 1,
              padding: "0 0.4em",
            },
            progressBar: {
              backgroundColor: "#ffdd57",
            },
          }}
          config={{
            fastMode: true, //problemi with "" in fields
            skipEmptyLines:true,
            step: async (results, parser) => {
              await handleOnStep(results, parser);
            },
            beforeFirstChunk: () => {
              handleBeforeFirstChunk();
            },
            complete: (results, file) => {
              //handleOnComplete();
              //console.log("Parsing complete:", results, file)
            },
            preview: 52, //limit parsed rows
          }}
          addRemoveButton
          onRemoveFile={handleOnRemoveFile}
          removeButtonColor="#333"
        >
          <span>{i18n.t("Clicca o Trascina il csv con i dati degli utenti per compilare in automatico tutti i campi...")}</span>
        </CSVReader>
        <div className="content pt-5">
          {theResults.length > 0 && <h5 className="title is-5">Risultati</h5>}

          <Suspense fallback={<div>Loading...</div>}>
          {theResults.length > 0 && (
            <div className="table-container">
              <table className="table is-fullwidth is-size-7">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                {theResults.map((item, i) => {
                  return (
                    <tr key={i} className={item.status === "OK" ? "has-background-success-light" : (item?.error?.generic?.includes("già emessa") || item?.error?.generic?.includes("già presente")) ? "has-background-warning-light" : "has-background-danger-light"}>
                      <td><span className="icon is-small fa-lg"><i className={item?.cardsData?.consenso_e_privacy_espressi !== 1 ? "fas fa-envelope" : "fas fa-address-card"} aria-hidden="true"></i></span> {theResults.length - i}</td>
                      <td>{item?.cardsData?.email}</td>
                      <td>{item?.cardsData?.nome}</td>
                      <td>{item?.cardsData?.cognome}</td>
                      <td>{item?.status === "OK" ? <SyntaxHighlighter language="json" style={atelierSeasideLight}>{JSON.stringify(item?.data, null, 2)}</SyntaxHighlighter> : <SyntaxHighlighter language="json" style={vs}>{JSON.stringify(item.error, null, 2)}</SyntaxHighlighter>}</td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          )}
          </Suspense>
        </div>
      </div>
      {/* <pre>{JSON.stringify(theResults, null, 2)}</pre> */}
    </div>
  );
};

export default CardEmit;
