import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchChangePassword } from "./../../model/auth";
import { Redirect } from "react-router-dom";

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleForgot = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetchChangePassword(password, props?.match?.params?.passwordToken);
      if (response.status === "OK") {
        setSuccess(response.message);
      } else {
        setError(response?.error);
      }
      console.log(response);
    } catch (e) {
      setError({ error: { generic: "Si è verificato un errore" } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error !== false) {
      setError(false);
    }
  }, [password]);

  if (success){
    return <Redirect to="/admin/login/reset" />
  }

  return (
    <div className="hero is-fullheight is-link">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              {/* <h3 className="title has-text-white">Login</h3>
              <hr className="login-hr" />
              <p className="subtitle has-text-white">Please login to see our cool stuff!</p> */}
              <div className="box">
                <img src="/img/firaft-logo.png" className="authLogo" alt="logo" />
                { props?.match?.params?.passwordToken ?
                <>
                <p className="title has-text-grey is-5">Inserisci la nuova password</p>
                <form>
                  <div className="field">
                    <div className="control is-medium">
                      {error?.password && <p className="is-size-6 has-text-danger">{error?.password}</p>}
                      <p className="control has-icons-left">
                        <input className="input is-medium" value={password} onChange={changePassword} type="password" placeholder="Password" />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                  <button className={`button is-block is-danger is-medium is-fullwidth ${isLoading && "is-loading"}`} onClick={handleForgot}>
                    Recupera
                  </button>
                </form>
                </>
                :
                <p className="is-size-6 has-text-danger">
                  Codice di autorizzazione errato, accedi dal link inviato per email. Se non hai ancora ricevuto l'email <Link to="/admin/lostpassword">richiedi nuovamente il recupero password.</Link>
                </p>
}
              </div>
              <p className="has-text-white">
                <Link to="/admin/login">Login</Link> &nbsp;·&nbsp;
                <a href="mailto:segreteria@federrafting.it">Hai bisogno di aiuto?</a>
              </p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ChangePassword;
