const legal_booking_confirmation_fr = 
`
Je soussigné(e), par l’evoi de ce formulaire, déclare:
- 1. D’avoir été préalablement informé(e) par les organisateurs des spécificités et des risques liés aux activités fluviales et de manifester l’intention d’y participer volontairement et à mes propres risques, en reconnaissant d’avoir été informé(e) de toutes les instructions techniques et comportamentales ( [cours théorique en vidéo](https://www.youtube.com/watch?v=llxAPuH_JwU) ) à suivre dans des eventuelles situations d’émergence, y comprise la possibilité de nager dans le fleuve même pour des fins de formation, et de s’engager à alerter le guide ou le maître si je ne comprend pas quelques instructions;
- 2. De ne pas être au courant de aucune pathologie qui pourrait représenter une contre-indication à l’activité pour laquelle je demande l’inscription;
- 3. De s’engager à prêter attention au briefing qui va se dérouler avant que l’activité fluviale commence et que, selon l’indiscutable avis du Technicien Fédéral, je pourrait être considéré(e) inapte à l’embarquement si la compréhension des instructions techniques et comportamentales fournies n’est pas suffisante et/ou si je ne serais pas capable de les appliquer;
- 4. Donner acte d’avoir été informé(e) qu’ on me fournira un équipement spécifique de sécurité prévu par la W.R.F (World Rafting Federation) et par les normes d’autoréglementation de la F.I.Raft (Federazione Italiana Rafting) exposées auprès du centre nautique;
- 5. D’avoir été totalement informé(e) du contenu de cette déclaration, que j’ai lue attentivement, en souscrivant volontairement cette dernière;
- 6. D’exonérer, alors, la Federazione Italiana Rafting, l’Inscrit F.I.Raft et ses techniciens (Guides et/ou Maîtres) de toute responsabilité et d’indemnisation, sauf en cas de dol ou de faute lourde grave démontrée et évidente, envers moi pour des lésions fisiques ou des dégâts matériels vérifiés pécédemment, même si dus au fait de tiers extérieurs ou d’autres participants.

Compte tenu et réaffirmé ce qui précède, par la signature de ce formulaire on souscrit ce qui est indiqué dans les points précédents, de 1 à 6, aux sens de l’art. 1341 et s.s. du c.c.
`;


const legal_booking_confirmation_underage_fr = 
`
**Je soussigné/é, en tant qu’exploitant la responsabilité parentale/tuteur, déclare** par l’envoi de la présente:
- 1. **de signer**, au nom et pour compte du mineur **la déclaration dans la demande d’adhésion et/ou dans le
consentement éclairé à l’activité mentionnée ci-dessus**;
- 2. de donner son consentement au traitement de ses données personnelles, aux termes de l’article 13 du GDPR, lue la
note d’[**information**](https://firaftbooking.it/terms/tutori) dans les modalités et pour les finalités indiquées au point 1.
Compte tenu et réaffirmé ce qui précède, par la signature de ce formulaire on souscrit ce qui est indiqué dans les points
précédents, de 1 à 2, aux sens de l’art. 1341 et s.s. du c.c.
`;

module.exports = {legal_booking_confirmation_fr, legal_booking_confirmation_underage_fr};
