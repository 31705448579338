const legal_booking_confirmation_en = `
The undersigned, by submitting this form, declares:
- 1. to have been previously informed, by the organisers, of the peculiarities and the risks associated with the river activities
and to express his/her intention to attend the activity by his/her own decision and at his/her own risk, acknowledging
he/she has been informed that he/she will receive the technical and behavioural instructions( [“theory lesson”](https://www.youtube.com/watch?v=Nz4bvV230Xw) ) to follow
in any potential emergency situation, including the possibility to swim in the river, also for learning purposes only,
before the practise of the activity, and to commit to report any misunderstanding to his/her technical instructor (Instructor
and/or River Guide);
- 2. to have not experienced any pathology that could represent a contraindication for the activity for which he/she is
demanding the registration;
- 3. his/her commitment to pay attention to the briefing that will take place before the beginning of the river activity and that,
for the unquestionable judgment of the guide, could be deemed unsuitable at the river boarding whenever the guide
believes that he/she did not understand the instructions previously provided and/or he/she is not able to put them into
practice;
- 4. to be informed that he/she will be equipped with specific safety equipment required by W.R.F. (World Rafting
  Federation) and by the self-regulatory standards of F.I.Raft (Federazione Italiana Rafting) exhibited at the water sports
  center;
- 5. that he/she has been fully informed about the content of this statement, heaving read the document and submitting it on
his/her own free initiative;
- 6. to exonerate, therefore, the F.I.Raft (Federazione Italiana Rafting), the organisations affiliated to F.I.Raft and its
technicians (River Guides and/or Instructors) from any responsibility and compensation, except in cases of intentional
fault or serious misconduct, toward him/herself for physical and material damages before, during and after the activity,
even if they are due to third parties or other participants.

Considering the above and sending this form I agree with what stated in the previous points, from 1 to 6, in accordance with the
art.1341 et seq. of the Italian Civil Code.
`;

const legal_booking_confirmation_underage_en = `
**The undersigned, as person with parental responsibility or legal guardian**, by submitting this form, **declares**:
- 1. **To accept** for and on behalf of the minor **what stated in the membership application and/or in the activity
consent form above**;
- 2. to give consent to the processing of personal data in accordance with the art.13 of GDPR and having read the
[**information**](https://firaftbooking.it/terms/tutori) regarding the methods and purposes described at point 1.
Considering the above and sending this form I agree with what stated in the previous points, from 1 to 2, in accordance with
the art.1341 et seq. of the c.c.
`;

module.exports = { legal_booking_confirmation_en, legal_booking_confirmation_underage_en };
