const legal_booking_confirmation_it = 
`
  Il/la sottoscritto/a, con l’invio del presente modulo, dichiara
- 1. di essere stato/a preventivamente informato/a dagli organizzatori delle specificità e dei rischi connessi alle attività fluviali e di
manifestare l’intenzione di parteciparvi per propria decisione ed a proprio rischio, dando atto di essere stato informato/a di tutte le
istruzioni tecniche e comportamentali ( [video "lezione teorica"](https://www.youtube.com/watch?v=llxAPuH_JwU) ) a cui attenersi nelle eventuali situazioni di emergenza, compresa la
possibilità di nuotare in fiume anche al solo scopo formativo, e di impegnarsi ad avvisare il proprio Tecnico (Guida e/o Maestro)
qualora non si comprendessero alcune istruzioni;
- 2. di non essere a conoscenza di alcuna patologia o stato che possa costituire controindicazione per l’attività per cui si prenota;
- 3. di impegnarsi a prestare la massima attenzione al briefing che si svolgerà prima dell’inizio dell’attività fluviale e che, ad
insindacabile giudizio del Tecnico federale, potrà essere ritenuto non idoneo all’imbarco qualora sia ritenuto che non abbia
compreso le istruzioni tecnico-comportamentali fornite e/o che non sarebbe in grado di metterle in pratica;
- 4. dare atto di essere stato informato che verrà equipaggiato della specifica attrezzatura di sicurezza prevista dalla W.R.F. (World
Rafting Federation) e dalle norme di autoregolamentazione della F.I.Raft (Federazione Italiana Rafting) esposte presso la base
nautica;
- 5. di essere stato/a completamente informato/a del contenuto della presente dichiarazione, della quale ha effettuato attenta lettura,
sottoscrivendo la stessa di propria libera iniziativa;
- 6. di esonerare, quindi, la Federazione Italiana Rafting, l’Affiliato F.I.Raft ed i suoi Tecnici (Guide e/o Maestri) da ogni
responsabilità e risarcimento, salvo in caso di dolo o colpa grave dimostrata e palese, nei propri confronti per danni fisici e
materiali verificatisi prima, durante e dopo la discesa, anche se dovuti a fatto di terzi estranei o altri partecipanti.

Premesso e ribadito quanto sopra, con l’invio del presente modulo si sottoscrive quanto dichiarato ai precedenti punti, da 1 a 6, ai sensi
dell’art. 1341 e s.s. del c.c..
`;


const legal_booking_confirmation_underage_it = 
`
**Il/la sottoscritto/a, in qualità di esercente la responsabilità genitoriale/tutoria**, con l’invio della presente, **dichiara**:
- 1. **di sottoscrivere in nome e per conto del minore quanto indicato nella richiesta di tesseramento e/o nel consenso informato all’attività** di cui sopra;
- 2. di dare il consenso al trattamento dei propri dati personali ai sensi all’articolo 13 del GDPR, letta l’[**informativa**](https://firaftbooking.it/terms/tutori) nelle modalità e per le finalità indicate al punto 1.

Premesso e ribadito quanto sopra, con la sottoscrizione del presente modulo si sottoscrive quanto dichiarato ai precedenti punti
1 e 2 ai sensi dell’art. 1341 e s.s. del c.c..
`;

module.exports = {legal_booking_confirmation_it, legal_booking_confirmation_underage_it};
