import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  it: {
    translation: {
      "Scegli risorsa": "Scegli attività",
      "Scegli servizio": "Scegli servizio",
      "Nessun servizio per questa risorsa" : "Nessun servizio per questa risorsa",
      "Scegli un'altra data" : "Scegli un'altra data",
      "Settimana" : "Settimana",
      "Giorno" : "Giorno",
      "Griglia" : "Griglia",
      "Compila il form per confermare la prenotazione": "Compila il form per confermare la prenotazione",
      "Riepilogo" : "Riepilogo",
      "Associazione" : "Associazione",
      "Servizio": "Servizio",
      "Durata": "Durata",
      "Prezzo per persona": "Prezzo per persona",
      "Data e ora": "Data e ora",
      "escluso eventuale costo del tesseramento": "escluso eventuale costo del tesseramento",
      "Per quante persone vuoi prenotare": "Per quante persone vuoi prenotare",
      "Dati partecipante": "Dati partecipante",
      "Gestore della prenotazione": "Gestore della prenotazione",
      "Devi essere maggiorenne per gestire la prenotazione": "Devi essere maggiorenne per gestire la prenotazione",
      "Email": "Email",
      "Telefono": "Telefono",
      "Nome": "Nome",
      "Cognome": "Cognome",
      "Data di nascita": "Data di nascita",
      "Nazionalità": "Paese di residenza",
      "Codice Fiscale": "Codice Fiscale",
      "Italia": "Italia",
      "Italiano": "Italiano",
      "Straniero": "Estero",
      "Stato di Nascita": "Stato di Nascita",
      "Sesso": "Sesso",
      "M": "M",
      "F": "F",
      "Acconsento": "Acconsento",
      "Nego il consenso": "Nego il consenso",
      "Conferma prenotazione": "Conferma prenotazione",
      "Verifica i campi contrassegnati in rosso e invia nuovamente.": "Verifica i campi contrassegnati in rosso e invia nuovamente.",
      "Si è verificato un errore nella verifica della tessera, controlla i dati e riprova." : "Si è verificato un errore nella verifica della tessera, controlla i dati e riprova.",
      "Inserisci un indirizzo email": "Inserisci un indirizzo email",
      "Inserisci un indirizzo email valido": "Inserisci un indirizzo email valido",
      "Inserisci il telefono": "Inserisci il telefono",
      "Inserisci il nome": "Inserisci il nome",
      "Inserisci il cognome": "Inserisci il cognome",
      "Inserisci una data di nascita valida": "Inserisci una data di nascita valida",
      "Seleziona nazionalità": "Seleziona paese di residenza",
      "Inserisci un codice fiscale valido" : "Inserisci un codice fiscale valido",
      "Inserisci uno stato di nascita" : "Inserisci uno stato di nascita",
      "Inserisci il genere" : "Inserisci il genere",
      "Effettua il pagamento per confermare la tua prenotazione": "Effettua il pagamento per confermare la tua prenotazione",
      "Conferma": "Conferma",
      "Costo tessere": "Costo tessere",
      "Costo servizio": "Costo servizio",
      "Costo totale": "Costo totale",
      "Il costo della prenotazione comprende il costo del tesseramento di": "Il costo della prenotazione comprende il costo del tesseramento di",
      "Il tesseramento è obbligatorio per accedere ai servizi, ogni tessera ha un costo di" : "Il tesseramento è obbligatorio per accedere ai servizi, ogni tessera ha un costo di",
      "Si è verificato un errore nel calcolo del costo, prova di nuovo o annulla ed effettua nuovamente la prenotazione!" : "Si è verificato un errore nel calcolo del costo, prova di nuovo o annulla ed effettua nuovamente la prenotazione!",
      "Totale da versare": "Totale da versare",
      "Il pagamento comprende la quota dei biglietti più l'eventuale quota per i tesseramenti.": "Il pagamento comprende la quota dei biglietti più l'eventuale quota per i tesseramenti.",
      "Il pagamento è relativo alle tessere, il pagamento dei servizi, ove previsto, verrà effettuato con le modalità definite dall'associazione." : "Il pagamento è relativo alle tessere, il pagamento dei servizi, ove previsto, verrà effettuato con le modalità definite dall'associazione.",
      "minuti": "minuti",
      "Riceverai la conferma della prenotazione via email e se necessario un link per completare il tesseramento." : "Riceverai la conferma della prenotazione via email e se necessario un link per completare il tesseramento.",
      "Effettua il pagamento" : "Effettua il pagamento",
      "Pagamento effettuato": "Pagamento effettuato",
      "Tesseramento" : "Tesseramento",
      "Stato di Residenza" : "Stato di Residenza",
      "Indirizzo" : "Indirizzo",
      "Comune" : "Comune",
      "Comune di residenza" : "Comune di residenza",
      "CAP" : "CAP",
      "Dati dell’esercente responsabilità genitoriale/tutoria" : "Dati dell’esercente responsabilità genitoriale/tutoria",
      "Invia" : "Invia",
      "Ci sono alcuni errori nei dati inseriti, gli errori sono evidenziati in rosso. Effettua le correzioni e procedi nuovamente con l'invio.": "Ci sono alcuni errori nei dati inseriti, gli errori sono evidenziati in rosso. Effettua le correzioni e procedi nuovamente con l'invio.",
      "Inserire il nome. Minimo 2, massimo 30 caratteri." : "Inserire il nome. Minimo 2, massimo 30 caratteri.",
      "Inserire il cognome. Minimo 2, massimo 30 caratteri." : "Inserire il cognome. Minimo 2, massimo 30 caratteri.",
      "Selezionare la nazionalità." : "Selezionare paese di residenza.",
      "Il consenso è obbligatorio per processare la tua richiesta." : "Il consenso è obbligatorio per processare la tua richiesta.",
      "Inserire una data di nascita valida." : "Inserire una data di nascita valida.",
      "Selezionare lo stato di residenza." : "Selezionare lo stato di residenza.",
      "Inserire un indirizzo." : "Inserire un indirizzo.",
      "Inserire un comune." : "Inserire un comune.",
      "Inserire un CAP." : "Inserire un CAP.",
      "Inserire il codice fiscale." : "Inserire il codice fiscale.",
      "Inserire il sesso." : "Inserire il sesso.",
      "Selezionare lo stato di nascita." : "Selezionare lo stato di nascita.",
      "Inserire il nome del responsabile. Minimo 2, massimo 30 caratteri." : "Inserire il nome del responsabile. Minimo 2, massimo 30 caratteri.",
      "Inserire il cognome del responsabile. Minimo 2, massimo 30 caratteri." : "Inserire il cognome del responsabile. Minimo 2, massimo 30 caratteri.",
      "Il consenso è obbligatorio per il tesseramento di minori." : "Il consenso è obbligatorio per il tesseramento di minori.",
      "Si è verificato un errore, nessuna tessera in sospeso con questi dati" : "Si è verificato un errore, nessuna tessera in sospeso con questi dati",
      "Si è verificato un errore nella verifica della tessera, si prega di provare più tardi, se il problema persiste contattare l'assistenza" : "Si è verificato un errore nella verifica della tessera, si prega di provare più tardi, se il problema persiste contattare l'assistenza",
      "Dati anagrafici mancanti o incorretti, verifica attentamente i dati inseriti" :  "Dati anagrafici mancanti o incorretti, verifica attentamente i dati inseriti",
      "Codice fiscale non corretto" : "Codice fiscale non corretto",
      "Codice fiscale obbligatorio per gli italiani" : "Codice fiscale obbligatorio per gli italiani",
      "Data emissione non valida" : "Data emissione non valida",
      "Data scadenza non valida" : "Data scadenza non valida",
      "La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento" : "La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento",
      "Livello pagamento non valido" :  "Livello pagamento non valido",
      "Affiliazione non presente" : "Affiliazione non presente",
      "Società non valida" : "Società non valida",
      "Stato nascita non valido" : "Stato nascita non valido",
      "Comune nascita non valido" : "Comune nascita non valido",
      "Consenso privacy per finalità istituzionali e di tesseramento obbligatorio" : "Consenso privacy per finalità istituzionali e di tesseramento obbligatorio",
      "Stato di residenza non riconosciuto" : "Stato di residenza non riconosciuto",
      "Comune di residenza non riconosciuto" : "Comune di residenza non riconosciuto",
      "Campi mancanti o anomali" : "Campi mancanti o anomali",
      "Per confermare la prenotazione devi accettare i termini" : "Per confermare la prenotazione devi accettare i termini",
      "Tessera emessa con successo, a breve la riceverai via email" : "Tessera emessa con successo, a breve la riceverai via email",
      "Pagina non trovata" : "Pagina non trovata",
      "Utilizza un csv per importare i dati degli utenti" : "Utilizza un csv per importare i dati degli utenti",
      "Scarica il template" : "Scarica il template",
      "Clicca o Trascina il csv con i dati degli utenti per compilare in automatico tutti i campi" : "Clicca o Trascina il csv con i dati degli utenti per compilare in automatico tutti i campi",
      "Importazione completata. Verifica eventuali dati mancanti ed invia" : "Importazione completata. Verifica eventuali dati mancanti ed invia",
      "Limiti di età" : "Limiti di età",
      "Prenotazione": "Prenotazione",
      "Costo biglietti": "Costo biglietti",
      "Attiva" : "Attiva",
      "Cancellata" : "Cancellata",
      "Cancella prenotazione" : "Cancella prenotazione",
      "Termine per l'annullamento del Biglietto con rimborso" : "Termine per l'annullamento del Biglietto con rimborso",
      "Sei sicuro? L'operazione non può essere annullata" : "Sei sicuro? L'operazione non può essere annullata",
      "Consenso informato all'attività" : "Consenso informato all'attività",
      "Consenso già fornito" : "Consenso già fornito",
      "Il modulo di consenso informato all’attività è stato regolarmente sottoscritto. La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento." : "Il modulo di consenso informato all’attività è stato regolarmente sottoscritto. La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento",
      "Consenso informato obbligatorio" : "Consenso informato obbligatorio",
      "Tesseramento e consenso informato all'attività" : "Tesseramento e consenso informato all'attività",
      "Richiesta di tesseramento" : "Richiesta di tesseramento",
      "se minore, indicare email dell'esercente la responsabilità genitoriale/tutoria" : "se minore, indicare email dell'esercente la responsabilità genitoriale/tutoria",
      "pari a" : "pari a",
      "link_scaduto_1" : "Il presente link è scaduto poiché la richiesta di tesseramento è stata già compilata ed inviata correttamente. Per il perfezionamento del tesseramento non sono necessarie ulteriori attività.",
      "link_scaduto_2" : "Se non hai ricevuto la tessera, verifica che l’email non sia finita in SPAM.",
      "link_scaduto_3" : "Per ogni ulteriore informazione o problematica, si prega di contattare l’Associazione presso la quale si svolgerà l’attività.",
    }
  },
  en: {
    translation: {
      "Scegli risorsa": "Choose activity",
      "Scegli servizio": "Choose service",
      "Nessun servizio per questa risorsa" : "No service for this resource",
      "Scegli un'altra data" : "Choose another date",
      "Settimana" : "Week",
      "Giorno" : "Day",
      "Griglia" : "Grid",
      "Compila il form per confermare la prenotazione": "Fill in the form to confirm your reservation",
      "Riepilogo" : "Summary",
      "Associazione" : "Association",
      "Servizio": "Service",
      "Durata": "Duration",
      "Prezzo per persona": "Price per person",
      "Data e ora": "Date and time",
      "escluso eventuale costo del tesseramento": "excluded the possible cost of the membership card",
      "Per quante persone vuoi prenotare": "Number of people you are booking for",
      "Dati partecipante": "Participant data",
      "Gestore della prenotazione": "booking manager",
      "Devi essere maggiorenne per gestire la prenotazione": "You must be of legal age to manage the booking",
      "Email": "Email",
      "Telefono": "Phone",
      "Nome": "First Name",
      "Cognome": "Last Name",
      "Data di nascita": "Date of birth",
      "Nazionalità": "Country of residence",
      "Codice Fiscale": "Codice Fiscale",
      "Italia": "Italy",
      "Italiano": "Italian",
      "Straniero": "Foreign",
      "Stato di Nascita": "Country of birth",
      "Sesso": "Gender",
      "M": "M",
      "F": "F",
      "Acconsento": "I agree",
      "Nego il consenso": "I deny consent",    
      "Conferma prenotazione": "Confirm reservation",
      "Verifica i campi contrassegnati in rosso e invia nuovamente.": "Please check the fields marked in red and submit again.",
      "Si è verificato un errore nella verifica della tessera, controlla i dati e riprova." : "There was an error verifying the card, please check your data and try again.",
      "Inserisci un indirizzo email": "Please enter an email address",
      "Inserisci un indirizzo email valido": "Please enter a valid email address",
      "Inserisci il telefono": "Enter your phone",
      "Inserisci il nome": "Enter your name",
      "Inserisci il cognome": "Enter your surname",
      "Inserisci una data di nascita valida": "Enter a valid date of birth",
      "Seleziona nazionalità": "Select country of residence",
      "Inserisci un codice fiscale valido" : "Enter a valid tax code",
      "Inserisci uno stato di nascita" : "Enter a birth state",
      "Inserisci il genere" : "Enter the gender",
      "Effettua il pagamento per confermare la tua prenotazione": "Proceed to the payment to confirm your reservation",
      "Conferma": "Confirmation",
      "Costo tessere": "Cost of cards",
      "Costo servizio": "Service cost",
      "Costo totale": "Total cost",
      "Il costo della prenotazione comprende il costo del tesseramento di": "The reservation cost includes the one of the membership of",
      "Il tesseramento è obbligatorio per accedere ai servizi, ogni tessera ha un costo di" : "Membership is required to access the services, each card has a cost of",
      "Si è verificato un errore nel calcolo del costo, prova di nuovo o annulla ed effettua nuovamente la prenotazione!" : "There was an error in the cost calculation, please try again or cancel and book again!",
      "Totale da versare": "Total to be paid",
      "Il pagamento comprende la quota dei biglietti più l'eventuale quota per i tesseramenti.": "Payment includes the fee for the tickets and the eventual fees for membership.",
      "Il pagamento è relativo alle tessere, il pagamento dei servizi, ove previsto, verrà effettuato con le modalità definite dall'associazione." : "The payment is related to the cards, the payment of the services, whenever required, will be made in the manner appointed by the association.",
      "minuti": "minutes",
      "Riceverai la conferma della prenotazione via email e se necessario un link per completare il tesseramento." : "You will receive the booking confirmation by email and a link to complete the membership if it is necessary.",
      "Effettua il pagamento" : "Make the payment",
      "Pagamento effettuato": "Payment executed",
      "Tesseramento" : "Membership",
      "Stato di Residenza" : "Country of Residence",
      "Indirizzo" : "Address",
      "Comune" : "City",
      "Comune di residenza" : "City of residence",
      "Postal Code" : "Postal Code",
      "Dati dell’esercente responsabilità genitoriale/tutoria" : "Data of the parental / guardian responsibility operator",
      "Invia" : "Send",
      "Ci sono alcuni errori nei dati inseriti, gli errori sono evidenziati in rosso. Effettua le correzioni e procedi nuovamente con l'invio.": "There are errors in the data, the errors are highlighted in red. Please enter valid data and submit again.",
      "Inserire il nome. Minimo 2, massimo 30 caratteri." : "Enter the name. Minimum 2, maximum 30 characters.",
      "Inserire il cognome. Minimo 2, massimo 30 caratteri." : "Enter the surname. Minimum 2, maximum 30 characters.",
      "Selezionare la nazionalità." : "Select country of residence.",
      "Il consenso è obbligatorio per processare la tua richiesta." : "Consent is required to process your request.",
      "Inserire una data di nascita valida." : "Please enter a valid date of birth.",
      "Selezionare lo stato di residenza." : "Select the country of residence.",
      "Inserire un indirizzo." : "Please enter an address.",
      "Inserire un comune." : "Enter a municipality.",
      "Inserire un CAP." : "Enter a Post Code.",
      "Inserire il codice fiscale." : "Please enter a Codice Fiscale.",
      "Inserire il sesso." : "Enter your gender.",
      "Selezionare lo stato di nascita." : "Select your birth Country.",
      "Inserire il nome del responsabile. Minimo 2, massimo 30 caratteri." : "Enter the name of the responsible. Minimum 2, maximum 30 characters.",
      "Inserire il cognome del responsabile. Minimo 2, massimo 30 caratteri." : "Enter the surname of the responsible. Minimum 2, maximum 30 characters.",
      "Il consenso è obbligatorio per il tesseramento di minori." : "Consent is mandatory for the registration of minors.",
      "Si è verificato un errore, nessuna tessera in sospeso con questi dati" : "An error has occurred, no cards pending with this data",
      "Si è verificato un errore nella verifica della tessera, si prega di provare più tardi, se il problema persiste contattare l'assistenza" : "There was an error verifying the card, please try again later, if the problem persists contact the assistance",
      "Dati anagrafici mancanti o incorretti, verifica attentamente i dati inseriti" :  "Missing or incorrect personal data, carefully check the data entered",
      "Codice fiscale non corretto" : "Incorrect tax code",
      "Codice fiscale obbligatorio per gli italiani" : "Mandatory tax code for Italians",
      "Data emissione non valida" : "Invalid issue date",
      "Data scadenza non valida" : "Invalid expiration date",
      "La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento" : "Your card is already issued, you do not need to make a new card",
      "Livello pagamento non valido" :  "Invalid payment level",
      "Affiliazione non presente" : "Affiliation not present",
      "Società non valida" : "Invalid company",
      "Stato nascita non valido" : "Invalid country of birth",
      "Comune nascita non valido" : "Municipality of birth invalid",
      "Consenso privacy per finalità istituzionali e di tesseramento obbligatorio" : "Privacy consent for institutional and membership purposes is mandatory",
      "Stato di residenza non riconosciuto" : "Country of residence not recognized",
      "Comune di residenza non riconosciuto" : "Municipality of residence not recognized",
      "Campi mancanti o anomali" : "Missing or incorrect fields",
      "Per confermare la prenotazione devi accettare i termini" : "To confirm the reservation you must accept the terms",
      "Tessera emessa con successo, a breve la riceverai via email" : "Card successfully issued, you will soon receive it by email",
      "Pagina non trovata" : "Page not found",
      "Utilizza un csv per importare i dati degli utenti" : "Use a csv to import user data",
      "Scarica il template" : "Download the template",
      "Clicca o Trascina il csv con i dati degli utenti per compilare in automatico tutti i campi" : "Click or drag the csv with the user data to automatically fill in all the fields",
      "Importazione completata. Verifica eventuali dati mancanti ed invia" : "Import completed. Check for any missing data and submit",
      "Limiti di età" : "Age requirements",
      "Prenotazione": "Booking",
      "Costo biglietti": "Tickets price",
      "Attiva" : "Active",
      "Cancellata" : "Canceled",
      "Cancella prenotazione" : "Cancel reservation",
      "Termine per l'annullamento del Biglietto con rimborso" : "Time Limit for cancellation of the Ticket with refund",
      "Sei sicuro? L'operazione non può essere annullata" : "Are you sure? The operation cannot be undone",
      "Consenso informato all'attività" : "Informed consent to the activity",
      "Consenso già fornito" : "Consent already given",
      "Il modulo di consenso informato all’attività è stato regolarmente sottoscritto. La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento." : "The informed consent form for the activity was duly signed. Your card is already issued, you do not need to make a new card",
      "Consenso informato obbligatorio" : "Informed consent required",
      "Tesseramento e consenso informato all'attività" : "Membership and informed consent to the activity",
      "Richiesta di tesseramento" : "Membership request",
      "se minore, indicare email dell'esercente la responsabilità genitoriale/tutoria" : "if minor, indicate the email address of the parental responsibility / guardian",
      "pari a" : "equal to",
      "link_scaduto_1" : "This link is expired. The membership application has been already submitted and we have correctly processed your request. No other action is required to complete the membership application.",
      "link_scaduto_2" : "If you didn’t receive the membership card by email, check your SPAM folder.",
      "link_scaduto_3" : "For any further information or clarification, please contact the Rafting Club where you are going to do the activity.",
    }
  },
  de: {
    translation: {
      "Scegli risorsa": "Aktivität auswählen",
      "Scegli servizio": "Service wählen",
      "Nessun servizio per questa risorsa" : "Kein Service für diese Ressource",
      "Scegli un'altra data" : "Wählen Sie ein anderes Datum",
      "Settimana" : "Woche",
      "Giorno" : "Tag",
      "Griglia" : "Gitter",
      "Compila il form per confermare la prenotazione": "Füllen Sie das Formular aus, um Ihre Reservierung zu bestätigen",
      "Riepilogo" : "Zusammenfassung",
      "Associazione" : "Verband",
      "Servizio": "Service",
      "Durata": "Dauer",
      "Prezzo per persona": "Preis pro Person",
      "Data e ora": "Datum und Uhrzeit",
      "escluso eventuale costo del tesseramento": "eventuelle Kosten der Mitgliedskarte sind ausgeschlossen",
      "Per quante persone vuoi prenotare": "Für wie viele Personen möchten Sie buchen",
      "Dati partecipante": "Daten des Teilnehmers",
      "Gestore della prenotazione": "Buchungsmanager",
      "Devi essere maggiorenne per gestire la prenotazione": "Sie müssen volljährig sein, um die Buchung zu verwalten",
      "Email": "Email",
      "Telefono": "Telefon",
      "Nome": "Vorname",
      "Cognome": "Nachname",
      "Data di nascita": "Geburtsdatum",
      "Nazionalità": "Land des Wohnsitzes",
      "Codice Fiscale": "Steuer-ID",
      "Italia": "Italien",
      "Italiano": "Italienisch",
      "Straniero": "Ausländisch",
      "Stato di Nascita": "Geburtsland",
      "Sesso": "Geschlecht",
      "M": "M",
      "F": "W",
      "Acconsento": "Ich willige ein",
      "Nego il consenso": "Ich willige nicht ein",
      "Conferma prenotazione": "Reservierung bestätigen",
      "Verifica i campi contrassegnati in rosso e invia nuovamente.": "Bitte überprüfen Sie die rot markierten Feld und versenden Sie diese erneut.",
      "Si è verificato un errore nella verifica della tessera, controlla i dati e riprova." : "Bei der Überprüfung der Mitgliedskarte ist ein Fehler aufgetreten. Überprüfen Sie Ihre Daten und versuchen Sie es erneut.",
      "Inserisci un indirizzo email": "Geben Sie eine E-Mail-Adresse an",
      "Inserisci un indirizzo email valido": "Geben Sie eine gültige E-Mail-Adresse an",
      "Inserisci il telefono": "Geben Sie den Telefon an",
      "Inserisci il nome": "Geben Sie den Namen an",
      "Inserisci il cognome": "Geben Sie Ihren Nachnamen an",
      "Inserisci una data di nascita valida": "Geben Sie ein gültiges Geburtsdatum an",
      "Seleziona nazionalità": "Land des Wohnsitzes",
      "Inserisci un codice fiscale valido" : "Geben Sie eine gültige Steuer-ID an",
      "Inserisci uno stato di nascita" : "Geben Sie ein Geburtsland an",
      "Inserisci il genere" : "Geben Sie das Geschlecht an",     
      "Effettua il pagamento per confermare la tua prenotazione": "Führen Sie die Zahlung durch, um Ihre Reservierung zu bestätigen", 
      "Conferma": "Bestätigung",
      "Costo tessere": "Kosten für Mitgliedskarten",
      "Costo servizio": "Servicekosten",
      "Costo totale": "Gesamtkosten",
      "Il costo della prenotazione comprende il costo del tesseramento di": "Die Kosten für die Buchung beinhalten die Registrierungskosten von",
      "Il tesseramento è obbligatorio per accedere ai servizi, ogni tessera ha un costo di" : "Die Mitgliedschaft ist verpflichten, um auf die Dienste zugreifen zu können. Jede Mitgliedskarte kostet",
      "Si è verificato un errore nel calcolo del costo, prova di nuovo o annulla ed effettua nuovamente la prenotazione!" : "Bei der Kostenberechnung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder stornieren und führen erneut die Buchung durch!",
      "Totale da versare": "Zu bezahlende Summe",
      "Il pagamento comprende la quota dei biglietti più l'eventuale quota per i tesseramenti.": "Die Zahlung beinhaltet die Gebühr für die Tickets sowie eventuelle Gebühren für die Mitgliedschaft.",
      "Il pagamento è relativo alle tessere, il pagamento dei servizi, ove previsto, verrà effettuato con le modalità definite dall'associazione." : "Die Zahlung bezieht sich auf die Mitgliedskarten, die Zahlung der Dienstleistungen erfolgt, sofern vorgesehen, in der vom Verein festgelegten Art.",
      "minuti": "protokoll",
      "Riceverai la conferma della prenotazione via email e se necessario un link per completare il tesseramento." : "Sie erhalten die Buchungsbestätigung per E-Mail und ggf. einen Link, um die Mitgliedschaft abzuschließen.",
      "Effettua il pagamento" : "Zahlung vornehmen",
      "Pagamento effettuato": "Zahlung erfolgt",
      "Tesseramento" : "Mitgliedschaft",
      "Stato di Residenza" : "Wohnsitzstaat",
      "Indirizzo" : "Adresse",
      "Comune" : "Gemeinde",
      "Comune di residenza" : "Wohnsitz Gemeinde",
      "CAP" : "Postleitzahl",
      "Dati dell’esercente responsabilità genitoriale/tutoria" : "Daten des Erziehungsberechtgten/Inhaber der elterlichen Sorge",
      "Invia" : "Senden",
      "Ci sono alcuni errori nei dati inseriti, gli errori sono evidenziati in rosso. Effettua le correzioni e procedi nuovamente con l'invio.": "Es sind Fehler bei den angegebenen Daten aufgetreten, die Fehler sind rot hervorgehoben. Korrigieren Sie die Daten und nehmen Sie erneut den Versand vor.",
      "Inserire il nome. Minimo 2, massimo 30 caratteri." : "Name eingeben. Mindestens 2, maximal 30 Zeichen.",
      "Inserire il cognome. Minimo 2, massimo 30 caratteri." : "Nachname angeben. Mindestens 2, maximal 30 Zeichen.",
      "Selezionare la nazionalità." : "Land des Wohnsitzes.",
      "Il consenso è obbligatorio per processare la tua richiesta." : "Zur Bearbeitung Ihrer Anfrage ist Ihre Einwilligung erforderlich.",
      "Inserire una data di nascita valida." : "Bitte geben Sie ein gültiges Geburtsdatum an.",
      "Selezionare lo stato di residenza." : "Wählen Sie den Wohnsitzstaat.",
      "Inserire un indirizzo." : "Geben Sie eine Adresse an.",
      "Inserire un comune." : "Geben Sie eine Gemeinde an.",
      "Inserire un CAP." : "Geben Sie eine Postleitzahl an.",
      "Inserire il codice fiscale." : "Geben Sie das die Steuer-ID an.",
      "Inserire il sesso." : "Geben Sie Ihr Geschlecht an.",
      "Selezionare lo stato di nascita." : "Wählen Sie Ihren Geburtsland.",
      "Inserire il nome del responsabile. Minimo 2, massimo 30 caratteri." : "Geben Sie den Namen des Verantwortlichen an. Mindestens 2, maximal 30 Zeichen.",
      "Inserire il cognome del responsabile. Minimo 2, massimo 30 caratteri." : "Geben Sie den Nachnamen des Verantwortlichen an. Mindestens 2, maximal 30 Zeichen.",
      "Il consenso è obbligatorio per il tesseramento di minori." : "Für die Registrierung von Minderjährigen ist die Einwilligung verpflichtend.",
      "Si è verificato un errore, nessuna tessera in sospeso con questi dati" : "Es ist ein Fehler aufgetreten, es ist keine Mitgliedskarte mit diesen Daten ausstehend",
      "Si è verificato un errore nella verifica della tessera, si prega di provare più tardi, se il problema persiste contattare l'assistenza" : "Beim Überprüfen der Mitgliedskarte ist ein Fehler aufgetreten. Versuchen Sie es später erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support",
      "Dati anagrafici mancanti o incorretti, verifica attentamente i dati inseriti" :  "Fehlende oder falsche Personenangaben, überprüfen Sie sorgfältig die angegebenen Daten",
      "Codice fiscale non corretto" : "Falsche Steuer-ID",
      "Codice fiscale obbligatorio per gli italiani" : "Steuer-ID ist verpflichtend für Italiener",
      "Data emissione non valida" : "Ungültiges Ausgabedatum",
      "Data scadenza non valida" : "Ungültiges Ablaufdatum",
      "La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento" : "Ihre Mitgliedskarte wurde bereits ausgestellt, Sie müssen keine neue Mitgliedsschaft beantragen",
      "Livello pagamento non valido" :  "Ungültige Zahlung",
      "Affiliazione non presente" : "Affiliation nicht vorhanden",
      "Società non valida" : "Ungültiger Verein",
      "Stato nascita non valido" : "Ungültiges Geburtsland",
      "Comune nascita non valido" : "Ungültige Geburtsgemeinde",
      "Consenso privacy per finalità istituzionali e di tesseramento obbligatorio" : "Einwilligung zur Verarbeitung der personenbezogener Daten für institutionelle und Mitgliedschaftszwecke ist verpflichtend",
      "Stato di residenza non riconosciuto" : "Wohnsitzland nicht erkannt",
      "Comune di residenza non riconosciuto" : "Wohngemeinde nicht anerkannt",
      "Campi mancanti o anomali" : "Fehlende oder falsche Felder",
      "Per confermare la prenotazione devi accettare i termini" : "Um die Reservierung zu bestätigen, müssen Sie die Bedingungen akzeptieren",
      "Tessera emessa con successo, a breve la riceverai via email" : "Karte erfolgreich ausgestellt, erhalten Sie bald per E-Mail",
      "Pagina non trovata" : "Seite nicht gefunden",
      "Utilizza un csv per importare i dati degli utenti" : "Verwenden Sie eine CSV-Datei, um Benutzerdaten zu importieren",
      "Scarica il template" : "Laden Sie die Vorlage herunter",
      "Clicca o Trascina il csv con i dati degli utenti per compilare in automatico tutti i campi" : "Klicken oder ziehen Sie die CSV-Datei mit den Benutzerdaten, um alle Felder automatisch auszufüllen",
      "Importazione completata. Verifica eventuali dati mancanti ed invia" : "Import abgeschlossen. Auf fehlende Daten prüfen und absenden",
      "Limiti di età" : "Altersanforderungen",
      "Prenotazione": "Buchung",
      "Costo biglietti": "Ticketpreis",
      "Attiva" : "Aktiv",
      "Cancellata" : "Abgebrochen", 
      "Cancella prenotazione" : "Reservierung stornieren",
      "Termine per l'annullamento del Biglietto con rimborso" : "Frist für die Stornierung des Tickets mit Rückerstattung",
      "Sei sicuro? L'operazione non può essere annullata" : "Bist du sicher? Der Vorgang kann nicht rückgängig gemacht werden",
      "Consenso informato all'attività" : "Einverständniserklärung zur Aktivität",
      "Consenso già fornito" : "Einwilligung bereits erteilt",
      "Il modulo di consenso informato all’attività è stato regolarmente sottoscritto. La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento." : "Die Einverständniserklärung für die Aktivität wurde ordnungsgemäß unterzeichnet. Ihre Mitgliedskarte wurde bereits ausgestellt, Sie müssen keine neue Mitgliedsschaft beantragen",
      "Consenso informato obbligatorio" : "Einverständniserklärung erforderlich",
      "Tesseramento e consenso informato all'attività" : "Mitgliedschaft und informierte Zustimmung zur Aktivität",
      "Richiesta di tesseramento" : "Mitgliedsantrag",
      "se minore, indicare email dell'esercente la responsabilità genitoriale/tutoria" : "bei Minderjährigen die E-Mail-Adresse des Erziehungsberechtigten angeben",
      "pari a" : "gleich",
      "link_scaduto_1" : "Dieser Link ist abgelaufen, da der Mitgliedsantrag bereits korrekt ausgefüllt und eingereicht wurde. Zur Vervollständigung der Mitgliedschaft sind keine weiteren Aktivitäten erforderlich.",
      "link_scaduto_2" : "Wenn Sie Ihre Mitgliedskarte nicht erhalten haben, überprüfen Sie bitte, ob die E-Mail nicht in SPAM gelandet ist.",
      "link_scaduto_3" : "Für weitere Informationen oder bei Problemen wenden Sie sich bitte an den Verein, in dem die Aktivität stattfinden wird.",
    }
  },
  fr: {
    translation: {
      "Scegli risorsa": "Choisir l'activité",
      "Scegli servizio": "Choisir le service",
      "Nessun servizio per questa risorsa" : "Aucun service pour cette ressource",
      "Scegli un'altra data" : "Choisir une autre date",
      "Settimana" : "Semaine",
      "Giorno" : "Jour",
      "Griglia" : "Grille",
      "Compila il form per confermare la prenotazione": "Compiler le formulaire pour confirmer la réservation",
      "Riepilogo" : "Résumé",
      "Associazione" : "Association",
      "Servizio": "Service",
      "Durata": "Durée",
      "Prezzo per persona": "Prix par personne",
      "Data e ora": "Date et heure",
      "escluso eventuale costo del tesseramento": "le coût eventuel de l'inscription est exclu",
      "Per quante persone vuoi prenotare": "Pour combien de personnes souhaietez-vous réserver?",
      "Dati partecipante": "Données du participant",
      "Gestore della prenotazione": "Gestionnaire de réservation",
      "Devi essere maggiorenne per gestire la prenotazione": "Vous devez avoir l'âge légal pour gérer la réservation",
      "Email": "Email",
      "Telefono": "Téléphone",
      "Nome": "Nom",
      "Cognome": "Prénom",
      "Data di nascita": "Date de naissance",
      "Nazionalità": "Pays de résidence",
      "Codice Fiscale": "Numéro de sécurité sociale",
      "Italia": "Italie",
      "Italiano": "Italien",
      "Straniero": "Étranger",
      "Stato di Nascita": "État de naissance",
      "Sesso": "Sexe",
      "M": "H",
      "F": "F",
      "Acconsento": "J'accepte",
      "Nego il consenso": "Je refuse le consentement",
      "Conferma prenotazione": "Je confirme la réservation",
      "Verifica i campi contrassegnati in rosso e invia nuovamente.": "Vérifiez les domaines en rouge et envoyez à nouveau.",
      "Si è verificato un errore nella verifica della tessera, controlla i dati e riprova." : "Une erreur pendant la validation de la carte est survenue, veuillez  contrôler les données et essayez encore.",
      "Inserisci un indirizzo email": "Insérer l'adresse email",
      "Inserisci un indirizzo email valido": "Insérer un adresse email valide",
      "Inserisci il telefono": "Insérer le téléphone",
      "Inserisci il nome": "Insérer le nom",
      "Inserisci il cognome": "Insérer le prénom",
      "Inserisci una data di nascita valida": "Insérer une date de naissance valide",
      "Seleziona nazionalità": "Selectionner pays de résidence",
      "Inserisci un codice fiscale valido" : "Insérer un numéro de sécurité sociale valide",
      "Inserisci uno stato di nascita" : "Insérer un État de naissance",
      "Inserisci il genere" : "Insérer le sexe",
      "Effettua il pagamento per confermare la tua prenotazione": "Effectuer le payement pour confimer la réservation",
      "Conferma": "Confirmer",
      "Costo tessere": "Coût des cartes",
      "Costo servizio": "Coût du service",
      "Costo totale": "Coût total",
      "Il costo della prenotazione comprende il costo del tesseramento di": "Le coût de l’iscription est inclu dans le coût de la réservation",
      "Il tesseramento è obbligatorio per accedere ai servizi, ogni tessera ha un costo di" : "L’inscription est obligatoire pour accéder aux services, le prix de chaque carte est de",
      "Si è verificato un errore nel calcolo del costo, prova di nuovo o annulla ed effettua nuovamente la prenotazione!" : "Une erreur dans le calcul du coût s’est vérifiée, essayez encore ou annulez et effectuez de nouveau la réservation",
      "Totale da versare": "Somme totale à verser",
      "Il pagamento comprende la quota dei biglietti più l'eventuale quota per i tesseramenti.": "Le payement inclut le prix des billets ainsi que le coût eventuel des inscriptions.",
      "Il pagamento è relativo alle tessere, il pagamento dei servizi, ove previsto, verrà effettuato con le modalità definite dall'associazione." : "Le payement est rélatif aux cartes, les payements des services, si prévus, il faut les effectuer selon les modalités établies par l’association.",
      "minuti": "minutes",
      "Riceverai la conferma della prenotazione via email e se necessario un link per completare il tesseramento." : "Vous recevrez la confirmation de la réservation par email et, si nécessaire, un lien pour compléter l’inscription.",
      "Effettua il pagamento" : "Effectuer le payement",
      "Pagamento effettuato": "Payement effectué",
      "Tesseramento" : "Inscription",
      "Stato di Residenza" : "État de résidence",
      "Indirizzo" : "Adresse",
      "Comune" : "Commune",
      "Comune di residenza" : "Commune de résidence",
      "CAP" : "Code postal",
      "Dati dell’esercente responsabilità genitoriale/tutoria" : "Données du titulaire de la responsabilité parentale/tuteur",
      "Invia" : "Envoyer",
      "Ci sono alcuni errori nei dati inseriti, gli errori sono evidenziati in rosso. Effettua le correzioni e procedi nuovamente con l'invio.": "Quelque erreur dans les données insérées est survenue, les erreurs sont montrées en rouge. Effectuez les corrections et envoyez de nouveau.",
      "Inserire il nome. Minimo 2, massimo 30 caratteri." : "Insérer le nom. Minimum 2, maximum 30 caractères.",
      "Inserire il cognome. Minimo 2, massimo 30 caratteri." : "Insérer le prénom. Minimum 2, maximum 30 caractères.",
      "Selezionare la nazionalità." : "Selectionner pays de résidence.",
      "Il consenso è obbligatorio per processare la tua richiesta." : "Le consentement est requis pour traiter votre demande.",
      "Inserire una data di nascita valida." : "Insérer une date de naissance valide.",
      "Selezionare lo stato di residenza." : "Selectionner l’état de résidence.",
      "Inserire un indirizzo." : "Insérer une adresse.",
      "Inserire un comune." : "Insérer une commune.",
      "Inserire un CAP." : "Insérer un code postal.",
      "Inserire il codice fiscale." : "Insérer le numéro de sécurité sociale.",
      "Inserire il sesso." : "Insérer le sexe.",
      "Selezionare lo stato di nascita." : "Sélectionner l’État de naissance.",
      "Inserire il nome del responsabile. Minimo 2, massimo 30 caratteri." : "Insérer le nom du responsable. Minimum 2, maximum 30 caractères.",
      "Inserire il cognome del responsabile. Minimo 2, massimo 30 caratteri." : "Insérer le prénom du responsable. Minimum 2, maximum 30 caractères.",
      "Il consenso è obbligatorio per il tesseramento di minori." : "Le consentement pour l’inscription des mineurs est obligatoire.",
      "Si è verificato un errore, nessuna tessera in sospeso con questi dati" : "Une erreur s’est vérifiée, aucune carte avec ces données est en attente",
      "Si è verificato un errore nella verifica della tessera, si prega di provare più tardi, se il problema persiste contattare l'assistenza" : "Une erreur dans la validation de la carte est survenue, veuillez essayer plus tard, si le problème persiste contactez l’assistance",
      "Dati anagrafici mancanti o incorretti, verifica attentamente i dati inseriti" :  "Les données personnelles sont absentes ou incorrectes, vérifiez soigneusement les données insérées",
      "Codice fiscale non corretto" : "Le numéro de sécurité sociale est incorrecte",
      "Codice fiscale obbligatorio per gli italiani" : "“Le numéro de sécurité sociale est obligatoire pour les Italiens",
      "Data emissione non valida" : "La date d’émission n’est pas valide",
      "Data scadenza non valida" : "La date d’expiration n’est pas valide",
      "La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento" : "Votre carte résulte déjà rendue, il n’est pas nécessaire d’effectuer une nouvelle inscription",
      "Livello pagamento non valido" :  "Le niveau de payment n’est pas valide",
      "Affiliazione non presente" : "L’affiliation n’est pas présente",
      "Società non valida" : "La société n’est pas valide",
      "Stato nascita non valido" : "L’État de naissance n’est pas valide",
      "Comune nascita non valido" : "La commune de naissance n’est pas valide",
      "Consenso privacy per finalità istituzionali e di tesseramento obbligatorio" : "Le consentement au traitement des données personnelles à des fins institutionnelles et pour l’inscription est obligatoire",
      "Stato di residenza non riconosciuto" : "L’État de résidence n’est pas reconnu",
      "Comune di residenza non riconosciuto" : "La commune de résidence n’est pas reconnue",
      "Campi mancanti o anomali" : "Domaines manquantes ou anormales",
      "Per confermare la prenotazione devi accettare i termini" : "Pour confirmer la réservation il faut accepter les conditions",
      "Tessera emessa con successo, a breve la riceverai via email" : "Carte émise avec succès, vous la recevrez bientôt par email",
      "Pagina non trovata" : "Page non trouvée",
      "Utilizza un csv per importare i dati degli utenti" : "Utiliser un csv pour importer des données utilisateur",
      "Scarica il template" : "Télécharger le modèle",
      "Clicca o Trascina il csv con i dati degli utenti per compilare in automatico tutti i campi" : "Cliquez ou faites glisser le csv avec les données de l'utilisateur pour remplir automatiquement tous les champs",
      "Importazione completata. Verifica eventuali dati mancanti ed invia" : "Importation terminée. Vérifiez les données manquantes et soumettez",
      "Limiti di età" : "Restrictions d'âge",
      "Prenotazione": "Réservation",
      "Costo biglietti": "Coût des billets",
      "Attiva" : "Activé",
      "Cancellata" : "Annulé",
      "Cancella prenotazione" : "Annuler votre réservation",
      "Termine per l'annullamento del Biglietto con rimborso" : "Date limite d'annulation du Billet avec remboursement",
      "Sei sicuro? L'operazione non può essere annullata" : "Es-tu sûr? L'opération ne peut pas être annulée",
      "Consenso informato all'attività" : "Consentement éclairé à l'activité",
      "Consenso già fornito" : "Consentement déjà donné",
      "Il modulo di consenso informato all’attività è stato regolarmente sottoscritto. La tua tessera risulta già emessa, non devi effettuare un nuovo tessaramento." : "Le formulaire de consentement éclairé pour l'activité a été dûment signé. Votre carte est déjà émise, vous n'avez pas besoin d'en faire une nouvelle",
      "Consenso informato obbligatorio" : "Consentement éclairé requis",
      "Tesseramento e consenso informato all'attività" : "Adhésion et consentement éclairé à l'activité",
      "Richiesta di tesseramento" : "Demande d'adhésion",
      "se minore, indicare email dell'esercente la responsabilità genitoriale/tutoria" : "si mineur, indiquer l'adresse e-mail de l'autorité parentale/tuteur",
      "pari a" : "égal à",
      "link_scaduto_1" : "Ce lien a expiré car la demande d’adhésion a déjà été remplie et envoyée correctement. Aucune activité supplémentaire n’est requise pour l’obtention de l’autorisation.",
      "link_scaduto_2" : "Si vous n’avez pas reçu votre carte, vérifiez que l’e-mail n’a pas été envoyé en SPAM.",
      "link_scaduto_3" : "Pour toute information complémentaire ou problématique, veuillez contacter l'Association auprès de laquelle l’activité aura lieu.",
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'it',
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;