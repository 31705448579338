const legal_booking_it = 
`
Il/la sottoscritto/a, con l’invio della presente, dichiara di acconsentire al trattamento dei dati personali, propri e di coloro per conto dei quali si
intende prenotare, letta l’[**informativa**](https://firaftbooking.it/terms/utenti-prenotazione), nelle modalità e per le finalità indicate al punto 1 della
stessa, strettamente connessi e strumentali alla gestione della richiesta di prenotazione.
Con l’invio dichiara altresì di aver acquisito, per quanto di competenza, il preventivo consenso al trattamento dei rispettivi dati da parte di coloro
per conto dei quali si prenota e di richiedere, contestualmente, l’invio agli stessi del modulo necessario ai fini del tesseramento alla Federazione
Italiana Rafting ([**informativa “Tesserati Turisti Amatori**](https://firaftbooking.it/terms/tesserati-turisti-amatori)).
`;

module.exports = legal_booking_it;
