const legal_card_en = `
**The undersigned demands to be registered for Federazione Italiana Rafting as “amateur-tourist”, by means of {{affiliato}}.**

The undersigned, by submitting this form, declares:
- 1. to have not experienced any pathology that could represent a contraindication for the activity for which he/she is demanding the membership;
- 2. to expressly accept all the rules of the Statute and the F.I.Raft and C.O.N.I. Regulations and to observe and fulfil them without exception;
- 3. to authorize the organisation affiliated to F.I.Raft to use the images and/or to make videos that include him/her. He/she also declares that the associate owns him/her nothing for the using this material, either now or in the future, that could be used for brochures, website, posters, stickers, calendars, holding from responsibilities whoever will use the material.;
- 4. to give consent to the processing of personal data in accordance with the art.13 of GDPR and having read the [**information**](https://firaftbooking.it/terms/utenti-prenotazione) regarding the methods and purposes described at point 1a, closely related and instrumental to the management of the membership relation, that allow for their treatment, to the extent necessary to the performance of the statutory purposes;

Considering the above and sending this form I agree with what stated in the previous points, from 1 to 4, in accordance with the
art.1341 et seq. of the Italian Civil Code.
`;

const legal_card_underage_en = `
**The undersigned, as person with parental responsibility or legal guardian**, by submitting this form, **declares**:
- 1. **To accept** for and on behalf of the minor **what stated in the membership application and/or in the activity
consent form above**;
- 2. to give consent to the processing of personal data in accordance with the art.13 of GDPR and having read the
[**information**](https://firaftbooking.it/terms/tutori) regarding the methods and purposes described at point 1.
Considering the above and sending this form I agree with what stated in the previous points, from 1 to 2, in accordance with
the art.1341 et seq. of the c.c.
`;

const legal_card_marketing_en = `
He/she also express the consent to the data communication for marketing and advertising purposes of third-parties, referred to in
point 1b, with which F.I.Raft or CONI has relationships of contractual nature and that process data to the extent necessary to
fulfil the obligations under the law and its contracts.
`;

module.exports = { legal_card_en, legal_card_underage_en, legal_card_marketing_en };
