import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { fetchLogin } from "./../../model/auth";
import { AppContext } from "./../../store/AppContext";
import { useTranslation } from "react-i18next";

const TesseratiTuristiAmatori = (props) => {
  const { t, i18n } = useTranslation();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="hero is-fullheight is-light">
      <div className="hero-body">
        <div className="container ">
          <div className="column is-10 is-offset-1">
            {i18n.language === "en" ? (
              <>
                <p>
                  <strong>INFORMATION ON THE PROCESSING OF PERSONAL DATA</strong>
                </p>
                <p>ART. 13 OF EU REGULATION 679/2016</p>
                <p>(GENERAL DATA PROTECTION REGULATION - GDPR)</p>
                <p>
                  <strong>MEMBER-AMATEUR TOURIST</strong>
                </p>
                <br />
                <p>
                  Dear <strong>member</strong>,
                </p>
                <p>
                Conforming to art.13 of EU Regulation 679/2016 (hence “GDPR”) we inform you that your personal data, that you provided, will
be processed by Federazione Italiana Rafting (abbreviated as “F.I.Raft” or “Federation”) acting as data controller (hereinafter
“Data Controller”), and could be subject to processing in the manner described by this Privacy Policy and in accordance with the
confidentiality principle that guides the activity of the Federation.
                </p>
                <p>
                Federazione Italiana Rafting, as data controller, inform you that the data of the person with parental responsibility or legal
guardian will be processed for the recognition and verification of the consent of the minor (the related information is available on
the website{" "}
                  <u>
                    <a href="http://www.federrafting.it/">www.federrafting.it</a>
                  </u>
                  ).
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>1 LEGAL BASIS AND PURPOSE OF THE PROCESSING</strong>
                    </p>
                  </li>
                </ul>
                <p>
                The legal basis of the processing (by way of example) is based on the permission of personal data processing, Legislative Decree
n. 242/1999 and subsequent amendments and integrations, on the existing rules on suitability for the sports practice (ministerial
decree on 18/02/1982 and ministerial decree on 24/04/2013) and on the CONI statute.
                </p>
                <p>
                Your personal data, acquired by the Federation with the membership as a result of the act of election, appointment or
classification, will be processed for the institutional purposes (1a), for example:
                </p>
                
                <p>
                Implementation of the enrolment activities<br/>
                Implementation of activities related to the relationship established with the membership, including the activities related to the
management of relationship with CONI, World Rafting Federation, as well as insurance companies;<br/>
Organisation and management of the competitions and sports events and/or regional, national and international sports events;<br/>
Registration for the competitions, sports events and/or regional, national and international sports events;<br/>
Refusal of requests submitted in any form to the Federation, the ones relatively concerned to its activities;<br/>
Giving a feedback about the concerns of the stakeholders regarding the registration on lists, events, competitions, meetings and/or
initiatives related to the Federation and to the subsequent management of them;<br/>
Requirements connected to obligations under the law, regulations, statute and federal regulations and, in general, national and
international regulations, from time to time in force
Sending advertising and informative material, commercial communications by the Federation, closely related to its own
institutional purposes;<br/>
Sending advertising and informative material, commercial communications (closely related to its own institutional purposes) by
the Sport Organisation, affiliated to F.I.Raft, where you requested your F.I.Raft membership and/or where you did the sport
activity, upon receipt of a specific privacy policy.<br/>

The processing, with the consent of the data subject, could be aimed to the provision of commercial and promotional informations
of the sports sector’s companies and societies with which F.I.Raft or CONI has relationships of contractual nature (1b), according
with what is established by GDPR.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>2 METHOD OF TREATMENT</strong>
                    </p>
                  </li>
                </ul>
                <p>
                The data will be processed in the following way: on manual spreadsheets, made with the help of electronic means, stored in
enclosed places with reserved and selected access, with a computerised system providing support against cyber risks (firewall,
antivirus, periodic backup of data); the data could be processed by internal or external entities, that are specifically authorised and
had undertaken to confidentiality.
See art. 4, paragraph 2, GDPR for the definition of “treatment”.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>3 PROVISION OF DATA AND REFUSAL</strong>
                    </p>
                  </li>
                </ul>
                <p>
                The provision of “ordinary” personal data (for example name, address or other personal identification elements, including your
image and eventually the details of your bank account) is strictly necessary to perform the activities referred to in point 1a. It may
happen that the provision of peculiar personal data (for example health data and personal data regarding the state of health, also in
relation to necessary treatment about national health emergencies) is necessary for the purpose of performing the activities
referred to in point 1a. On the other hand, the consent for the use of data for the purposes referred to in point 1b is optional.
Any refusal of the party concerned to provide personal data makes it impossible to perform the activity referred to in point 1a.
Any refusal to the data processing for the purposes referent to at point 1b is will have, on the other hand, no consequence on the
registration request.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>4 DATA COMMUNICATION</strong>
                    </p>
                  </li>
                </ul>
                <p>
                The personal data may also be processed on behalf of the owner, for the purposes referred to in point 1a, by third-party companies
that work on behalf of co-owners, in their capacity as external managers of the processing (this includes but is not limited to:
lenders, professional offices, suppliers/consultants who manage and/or participate in the management and/or maintenance of the
electronic and/or telematic tools that we use, insurance companies for the time strictly necessary to the successful execution of
that service, contractors). Your personal data will be made accessible only to those who need it due to their job of hierarchical
position. These parties will be properly instructed to avoid data loss, destruction, unauthorised accesses or unauthorised
processing of the data. The affiliate will operate as autonomous owner of the processing. Without your express consent, the holder
may communicate your data to supervisory bodies, legal authorities as well as to all the parties to which a mandatory release is
expressly provided by the law.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>5 DISCLOSURE OF YOUR DATA</strong>
                    </p>
                  </li>
                </ul>
                <p>Personal data are not subject to disclosure, without the prior express consent of the data subject.</p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>6 TRANSFER OF DATA ABROAD</strong>
                    </p>
                  </li>
                </ul>
                <p>
                The personal data can be transferred towards Countries belonging to the European Union and towards third party countries outside
the European Union, exclusively under the purposes stated in point 1. In such a case the data controller assures hereby that the
transfer of data outside the EU will be done in accordance with the art. 44 et seq. of the Regulation and with applicable legal
provisions, stipulating, if necessary, agreements that guarantee an adequate level of protection.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>7 DATA RETENTION</strong>
                    </p>
                  </li>
                </ul>
                <p>
                The data are kept for the time strictly necessary to the performance of the activity referred to in point 1a, in accordance with the
civil code, the fiscal legislation and the CONI and WRF rules and regulations, for a period not exceeding 10 years. The
verification of the obsolescence of the data stored in relation to the purposes for which they were collected is regularly carried out.
There is absolutely no automatic decision-making process or profiling activity, referred to in the art.22, GDPR paragraphs 1 and 4.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>8 RIGHTS OF THE DATA SUBJECT</strong>
                    </p>
                  </li>
                </ul>
                <p>
                As an interested party to the data treatment, we inform you that you have the possibility to exercise the rights required by the
Regulation, precisely:
                </p>
                <ul>
                  <ul>
                    <li>
                      <p>
                      The right, in accordance with art.15, to obtain confirmation that there is or there is not an ongoing treatment of personal
data concerning you and, if there is, to obtain the access to personal data and the following informations: i) purpose of
the processing ii) categories of personal data processed iii) recipients or categories of recipients to whom the data have
been or will be communicated, especially if third-countries or international organisations iv) personal data retention
period, whenever possible, or, if it is not possible, the criteria used to determine that period v) the existence of your right
to request from the data controller the rectification or deletion of personal data, or the restriction of the processing of
your personal data, or to object to their processing vi) the right to complain at the authorities, in accordance with art.77 et
seq. of the Regulation vii) in the event that personal data are not collected from the data subject, all available information
regarding their origin; viii) the existence of an automated decision-making process, including the profiling mentioned at
art.22 paragraphs 1 and 4 of the Regulation and, in such cases at list, meaningful informations about the logic used, as
well as the importance and the consequences of this processing concerned for the interested party ix) the right to be
informed about the existence of appropriate guarantees in accordance with the art.46 of the Regulation regarding the data
transfer, if the personal data are transferred to a third country or an international organisation;
The person concerned will also have (whenever possible) the possibility to exercise the rights foreseen by the art.16-21
of the Regulation (right of rectification, right to oblivion, right of treatment limitation, right to data portability, right of
opposition)
                      </p>
                    </li>
                  </ul>
                </ul>
                <p>
                We inform you that the owner commits to answer your requests no later than one month from the date of receipt. The term could
be extended depending on the complexity and number of the requests and the data controller will arrange to explain the reasons
for prolonging within one month of your request. The outcome of your request will be provided in written or in electronic format.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>9 DATA CONTROLLER.</strong>
                    </p>
                  </li>
                </ul>
                <p>
                  The data controller is the “Federazione Italiana Rafting” with registered office in Piazza San Paolo, 2, 13900, Biella (BI), C.F./P.I.
                  00689760148, tel. 0165 51 64 14 - E-mail:{" "}
                  <u>
                    <a href="mailto:segreteria@federrafting.it">segreteria@federrafting.it</a>
                  </u>
                </p>
                <p>
                  The updated list of data processors is available, by sending an Email to{" "}
                  <u>
                    <a href="mailto:segreteria@federrafting.it">segreteria@federrafting.it</a>
                  </u>
                  .
                </p>
              </>
            ) : i18n.language === "de" ? (
              <>
                <p>
                  <strong>INFORMATIONEN ÜBER DIE VERARBEITUNG PERSONENBEZOGENER DATEN</strong>
                </p>
                <p>ARTIKEL 13 DS-GVO</p>
                <p>(DATENSCHUTZ-GRUNDVERORDNUNG)</p>
                <p>
                  <strong>TESSERIERTE/MITGLIEDER – AMATEURE-TOURISTEN – VERWALTUNG VERBAND</strong>
                </p>
                <br />
                <p>
                  Liebe/r <strong>Tesserierte</strong>,
                </p>
                <p>
                gemäß Art. 13 DS-GVO informieren wir Sie, dass die personenbezogenen Daten, welche Sie freiwillig zur Verfügung gestellt haben, vom Italienischen Raftingverband (Federazione Italiana Rafting; kurz: „F.I.Raft" oder "Verband") in der Rolle als Verantwortlicher (im Folgenden „Verantwortlicher“), Gegenstand von Verarbeitung unter den unten angegebenen Arten und Zwecken sein können. Die Verarbeitung erfolgt in jedem Fall unter Beachtung der Vertraulichkeit, welche zu den Grundsätzen des Verbandes gehört.
                </p>
                <p>
                Der Italienische Raftingverband in Funktion als Verantwortlicher informiert, dass die Daten des Elternteils/gesetzlichen Vormundes für die Erkennung und Überprüfung der Einwilligung des Minderjährigen verarbeitet werden (die zugehörigen Datenschutzhinweise finden Sie auf der Website{" "}
                  <u>
                    <a href="http://www.federrafting.it/">www.federrafting.it</a>
                  </u>
                  ).
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>1 RECHTSGRUNDLAGE UND ENDGÜLTIGKEIT DER BEHANDLUNG</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Die Rechtsgrundlage der Verarbeitung liegt in der Einwilligung der Verarbeitung personenbezogener Daten, im gesetzesvertretendem Dekret Nr. 242/1999 ff., in den geltenden gesetzlichen Bestimmungen bezüglich der sportlichen Tauglichkeit (D.M. 18/02/1982 und D.M. 24/04/2013) und im Statut des Italienischen Nationalen Olympischen Komitees.
                </p>
                <p>
                Ihre personenbezogenen Daten, die der Verband anlässlich der „Tesserierung“ oder nach Wahl, Beschluss oder Ernennung erhalten/erworben hat, werden für folgende institutionelle Zwecke verarbeitet:
                </p>
                <ul>
                  <li>
                    <p>- Durchführung von Tesserierungen;</p>
                  </li>
                  <li>
                    <p>
                      - Durchführung der Tätigkeiten im Zusammenhang mit der Tesserierung, einschließlich Aktivitäten im Zusammenhang mit der Verwaltung der Beziehungen mit dem Italienischen Olympischen Komitee, dem Rafting-Weltverband „W.R.F.“ (World Rafting Federation) sowie Versicherungen;
                    </p>
                  </li>
                  <li>
                    <p>
                      - Organisation und Durchführung von Wettkämpfen und Sportveranstaltungen und/oder regionalen, nationalen und internationalen Sportveranstaltungen;
                    </p>
                  </li>
                  <li>
                    <p>- Anmeldung für Wettkämpfe, Sportveranstaltungen und/oder regionale, nationale und internationale Sportveranstaltungen;</p>
                  </li>
                  <li>
                    <p>- Bearbeitung von Anfragen seitens der Interessierten, welche dem Zuständigkeitsbereich des Verbandes unterliegen;</p>
                  </li>
                  <li>
                    <p>
                      - Bearbeitung von Anfragen seitens der Interessierten hinsichtlich Anmeldungen zu Veranstaltungen, Wettbewerben, und/oder Initiativen im Zusammenhang mit dem Verband und dessen Verwaltung;
                    </p>
                  </li>
                  <li>
                    <p>
                      - Zur Erfüllung der Verpflichtungen, die durch Gesetze, Verordnungen, Statuten und Verbandsverordnungen und im Allgemeinen durch die von Zeit zu Zeit geltenden nationalen und internationalen Rechtsvorschriften vorgesehen sind;
                    </p>
                  </li>
                  <li>
                    <p>
                      - Zusendung von Werbe-, Informations- und Kommunikationsmaterialien durch den Verband, welche im engen Zusammenhang mit den institutionellen Zwecken stehen;
                    </p>
                  </li>
                </ul>
                <p>
                Die Verarbeitung kann mit vorheriger Einwilligung des Betroffenen und immer unter Einhaltung der Bestimmungen der DS-GVO auch auf den Versand von Werbe- und Kommunikationsmaterial abzielen seitens Unternehmen des Sportsektors und Unternehmen mit denen der Italienische Raftingverband oder das Italienische Olympische Komitee in einer vertraglichen Beziehung (Ziffer 1b) ist.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>2 ART DER VERARBEITUNG</strong>
                    </p>
                  </li>
                </ul>
                <p>
                  Die Verarbeitung erfolgt auf folgende Art: auf physischen Formularen, die auch mit Hilfe elektronischer Mittel hergestellt werden, welche in
                  abschließbaren Schränken und/oder Räumen, zu welchen nur ausgewählte Personen Zugang haben, aufbewahrt werden, oder auch in elektronischen
                  Systemen, welche über verschiedene technische und organisatorische Maßnahmen verfügen wie beispielsweise (Firewall, Antivirus, regelmäßige
                  Sicherung von Daten); die Daten können lediglich von autorisierten Personen verarbeitet werden, intern oder extern, welche auf Vertraulichkeit
                  verpflichtet wurden. Für die Definition von "Verarbeitung", siehe Artikel 4 Absatz 2 DS-GVO.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>3 UMFANG DER VERARBEITUNG UND VERWEIGERUNG</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Die Angabe „allgemeiner“ personenbezogener Daten wie beispielsweise Vorname, Nachname, Telefonnummer und E-Mail-Adresse) ist für die Durchführung der unter Ziffer 1 genannten Tätigkeiten unbedingt erforderlich. Es ist nicht ausgeschlossen, dass durch Angabe auch „sensible“ personenbezogene Daten wie beispielsweise Gesundheitsdaten und Daten, welche angemessen sind den Gesundheitszustand einer Person zu ermitteln, die in Bezug auf nationale Gesundheitsnotfälle notwendig sind, für die Durchführung der unter Ziffer 1 genannten Tätigkeiten erforderlich sind. Die eventuelle Verweigerung der Angabe von personenbezogenen Daten des Interessenten, würde die Durchführung der unter Ziffer 1 genannten Tätigkeiten unmöglich machen.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>4 EMPFÄNGER DER PERSONENBEZOGENEN DATEN</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Die personenbezogenen Daten können im Auftrag des Verantwortlichen und der gemeinsamen Verantwortlichen (verbundenen Unternehmen), für die unter Ziffer 1 genannten Zwecke von Dritten verarbeitet werden. Zu diesen gehören z. B. und nicht beschränkt auf: Rafting-Weltverband „W.R.F.“ (World Rafting Federation), Vereine, welche im Namen des Italienischen Raftingverbandes tätig sind, Kreditinstitute, professionelle Firmen, Lieferanten/Berater, welche in der Verwaltung und/oder Wartung von elektronischen und/oder telematischen von uns verwendeten Geräte mitwirken, Versicherungsgesellschaften, externe Mitarbeiter. Ihre personenbezogenen Daten werden ausschließlich denjenigen zugänglich gemacht, die sie im Zusammenhang mit der Ausübung ihrer vorgeschriebenen Tätigkeit oder hierarchischen Position tatsächlich benötigen. Diese Personen werden entsprechend geschult, um Verlust, Zerstörung, unbefugten Zugriff oder unbefugte Verarbeitung der Daten selbst zu vermeiden.
Die Vereine, welchen Daten, für die unter Ziffer 1b genannten Zwecke weitergegeben werden können, agieren als eigenständige Verantwortliche.
Ohne Ihre ausdrückliche Einwilligung, ist es dem Verantwortlichen erlaubt, Ihre Daten an Aufsichtsbehörden, Justizbehörden sowie an alle anderen Personen übermitteln, gegenüber welchen eine Kommunikation aufgrund von ausdrücklichen gesetzlichen Bestimmungen zwingend erforderlich ist.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>5 VERBREITUNG VON DATEN</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Personenbezogene Daten unterliegen keiner Verbreitung oder Weiterleitung, es sei denn, dies geschieht mit ausdrücklicher Einwilligung der Betroffenen.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>6 DATENÜBERMITTLUNG INNERHALB UND AUSSERHALB DER EUROPÄISCHEN UNION</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Personenbezogene Daten können im Rahmen, der unter Ziffer 1 genannten Zwecke in Länder der Europäischen Union und in sog. Drittländern übermittelt werden.
In diesem Fall versichert der Verantwortliche, dass die Übermittlung von Daten in Ländern außerhalb der Europäischen Union und des Europäischen Wirtschaftsraums in Übereinstimmung mit Art. 44 ff. der DS-GVO und den anwendbaren Rechtsvorschriften, durch die Einhaltung und Anwendung von erforderlichen Vereinbarungen, welche ein angemessenes Schutzniveau für natürliche Personen gewährleisten.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>7 DAUER DER SPEICHERUNG</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Die Daten werden für den Zeitraum aufbewahrt, der für die Durchführung der in Ziffer 1 genannten Tätigkeit und im Einklang mit den Bestimmungen des Zivilgesetzbuches, der Steuergesetzgebung und den Regeln und Verordnungen des Italienischen Olympischen Komitees und dem Rafting-Weltverband „W.R.F.“ (World Rafting Federation), aber nie für einen längeren Zeitraum von zehn Jahren, erforderlich ist.  Eine Prüfung der Erforderlichkeit zur Datenaufbewahrung wird regelmäßig durchgeführt. Es gibt keine automatisierte Entscheidungsfindung oder Profilerstellung im Sinne von Artikel 22 Absatz 1 und 4 der DS-GVO.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>8 RECHTE DER BETROFFENEN</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Wir informieren Sie, dass sich unser Verband verpflichtet, Ihre Anfragen innerhalb eines Monats spätestens ab dem Datum des Eingangs zu beantworten. Diese Frist kann je nach Komplexität oder Anzahl der Bewerbungen verlängert werden, und der Verband wird Ihnen innerhalb eines Monats nach Ihrer Anfrage den Grund für die Verlängerung erläutern. Das Ergebnis Ihres Antrags kann Ihnen schriftlich, entweder in Papierform oder in elektronischer Form, zur Verfügung gestellt werden. Wir informieren Sie darüber, dass Sie die Möglichkeit haben, folgende in der Verordnung vorgesehenen Rechte auszuüben:
                </p>
                <ul>
                  <ul>
                    <li>
                      <p>
                        <b>a)</b> Das Recht gemäß Artikel 15 eine Bestätigung darüber zu verlangen, ob betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf folgende Informationen: (i) die Zwecke der Verarbeitung (ii) die Kategorien personenbezogener Daten, die verarbeitet werden; (iii) die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen; iv) falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer; (v) das Recht auf Berichtigung oder Löschung der betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung; vi) das Recht, bei einer Aufsichtsbehörde gemäß den Artikeln 77 ff. DS-GVO eine Beschwerde einzulegen; vii) wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten; viii) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Absätze 1 und 4 und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.; ix) das Recht, über das Bestehen angemessener Garantien im Sinne von Artikel 46 DS-GVO informiert zu werden, wenn personenbezogene Daten an ein Drittland oder eine internationale Organisation übermittelt werden;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>b)</b> Der Betroffene hat falls anwendbar auch die Möglichkeit, die in den Artikeln 16 bis 21 DS-GVO beschriebenen Rechte wahrzunehmen (Recht auf Berichtigung, Recht auf Einschränkung der Verarbeitung, Recht auf Datenübertragbarkeit, Widerspruchsrecht).
                      </p>
                    </li>
                  </ul>
                </ul>
                <p>
                Wir informieren Sie, dass sich der Verantwortliche bemüht, Ihre Anfragen innerhalb eines Monats ab Eingangsdatum zu beantworten. Diese Frist kann je nach Komplexität oder Anzahl der Anfragen verlängert werden. In diesem Fall wird Ihnen der Verantwortliche innerhalb eines Monats nach Ihrer Anfrage die Gründe die Gründe für die Verlängerung mitteilen. Das Ergebnis Ihrer Anfrage kann Ihnen schriftlich, entweder in Papierform oder in elektronischer Form, zur Verfügung gestellt werden.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>9 VERANTWORTLICHER DER DATENVERARBEITUNG.</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Verantwortlicher der Datenverarbeitung der Italienische Raftingverband mit Sitz in Piazza San Paolo, 2, 13900, Biella (BI), Ust-ID 00689760148, Tel. 0165 51 64 14 - E-Mail:{" "}
                  <u>
                    <a href="mailto:segreteria@federrafting.it">segreteria@federrafting.it</a>
                  </u>
                </p>
                <p>
                Die aktualisierte Liste der Verantwortlichen erhalten Sie per Anfrage an{" "}
                  <u>
                    <a href="mailto:segreteria@federrafting.it">segreteria@federrafting.it</a>
                  </u>
                  .
                </p>
              </>
            ) : i18n.language === "fr" ? (
              <>
                <p>
                  <strong>Informative au traitement des données personnelles</strong>
                </p>
                <p>Art.13 Règlement (UE) 2016/679</p>
                <p>(General data protection regulation - GDPR)</p>
                <p>
                  <strong>Inscrits Touristes Amateurs - gestion fédérale</strong>
                </p>
                <br />
                <p>
                Cher <strong>Inscrit</strong>,
                </p>
                <p>
                Aux sens de l’art. 13 Règlement (UE) 2016/679 (“GDPR”), on vous informe que les données personnelles, que vous avez volontairement mises à disposition et que seront en possessions de la Federazione Italiana Rafting (F.I.Raft), en qualité de responsable du traitement (“Titulaire”), pourront être objet de traitement selon les modalités et pour les fins indiquées ci-après et, toutefois, dans le respect du principe de discrétion duquel l’Activité de la Fédération est inspirée.
La Federazione Italiana Rafting (F.I.Raft), en qualité de responsable du traitement informe que les données du responsable parental/tutelle seront traitées pour la détection et la vérification du consentement du sujet mineur (la rélative informative est disponible sur le site{" "}
                  <u>
                    <a href="http://www.federrafting.it/">www.federrafting.it</a>
                  </u>
                  ).
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>1 BASE JURIDIQUE ET FIN DU TRAITEMENT </strong>
                    </p>
                  </li>
                </ul>
                <p>
                La base juridique du traitement s’identifie (à titre d’exemple) dans l’attribution du consentement au traitement des données personnelles, dans le décret législatif n. 242/1999 et ss.mm., dans les normes  relatives à l’aptitude à la pratique sportive (D.M. 18/02/1982 et D.M. 24/04/2013) et dans le Statut du CONI.
Votres données personnelles, acquises par la Federazione Italiana Rafting au moment de l’iscription, c’est-à-dire après l’acte d’éléction, nomination ou encadrement, seront traitées pour des fins institutionnelles (1a), à titre illustratif:
                </p>
                <ul>
                  <li>
                    <p>- accomplissement de l’inscription;</p>
                  </li>
                  <li>
                    <p>
                      - exécution des activités liées au rapport établi avec l’inscription, inclu les activités liées à la gestion des rapport avec le CONI, la World Rafting Federation (WRF) ainsi que les assurances;
                    </p>
                  </li>
                  <li>
                    <p>
                      - Organisation et gestion des compétitions et des manifestations sportives et/ou des événéments régionaux, nationaux et internationaux;
                    </p>
                  </li>
                  <li>
                    <p>- Inscription aux compétitions et des manifestations sportives et/ou des événéments régionaux, nationaux et internationaux;</p>
                  </li>
                  <li>
                    <p>
                      - Évasion des demandes, dans toutes les formes portées à l’attention de la Federazione, des intéressés concernant les activités de compétences de cette dernière;
                    </p>
                  </li>
                  <li>
                    <p>
                      - Érogation d’un résultat concernant les instances des intéressés en ce qui concerne l’inscription aux listes, événéments, compétitions, manifestations et /ou initiatives rélatives à la Federazione et à la gestions des derniers;
                    </p>
                  </li>
                  <li>
                    <p>
                      - Obligations imposées par la loi, les règlements, le statut et les règlements fédéraux et, en général, par la législation applicable cas par cas soit d’ordre national soit international;
                    </p>
                  </li>
                  <li>
                    <p>
                      - invio di materiale pubblicitario, informativo e di comunicazioni commerciali da parte della Federazione strettamente
connesso ai propri fini istituzionali.
                    </p>
                  </li>
                  <li>
                    <p>
                      - Envoi de matériel publicitaire, informatif et de communications commerciales strictement lié aux propres fins institutionnelles de la part de l’Inscrit auprès duquel vous êtes inscrits ou vous avez pratiqué l’activité sportive instituitionnelle, si vous avez reçu l’informative spécifique.
                    </p>
                  </li>
                </ul>
                <p>
                Le traitement, après consentement de l’interessé, pourrait être finalisé à l’envoi d’informations de nature commerciale et publicitaire des entreprises du secteur sportif et des sociétés avec lesquelles la F.I.Raft ou le CONI ont des rapports de nature contractuelle (1b), conformément à ce qui est établi par le GDPR.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>2 MODALITE’ DU TRAITEMENT</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Le traitement sera effectué selon les modalités suivantes: sur des fiches menuelles, réalisées aussi parmi l’aide des moyens électroniques, conservées dans des lieux fermés et d’accès sélectionné, c’est-à-dire de façon informatisée parmi des supports contre les risques informatiques (firewall, antivirus, backup périodique des données); les données pourraient être traitées par sujets, internes ou externes, spécifiquement autorisés et engagés à la confidentialité. Pour la définition de “traitement”, voir art. 4, paragraphe 2, GDPR.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>3 APPORT DES DONNEES ET REFUS</strong>
                    </p>
                  </li>
                </ul>
                <p>
                L’apport  des données personnelles “communes” (à titre illustratif, nominatif, adresse ou d’autres éléments d’identification personnelle, y comprise votre image et eventuellement les cordonnées du compte bancaire) est strictement nécessaire au déroulement des activités du point 1a. Il peut également se vérifier que l’apport des détails des données personnelles “sensibles” (à titre illustratif, données sanitaires et les données personnelles adapte à révéler l’état de santé, aussi en relation aux traitements nécessaires liés à des émergences sanitaires nationales) est nécessaire pour le déroulement des activités du point 1a. Le consentement pour l’utilisation des données personnelles pour des fins du point 1b est, en revanche, facultatif.
                </p>                
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>4 COMMUNICATION DES DONNEES</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Les données personnelles peuvent être traitées au nom du Titulaire pour des fins du point 1a par le CONI, par la WRF, ainsi que par des sociétés externes qui mènent des activités au nom de la Federazione, en qualité de responsables externes du traitement (à titre strictement illustratif et non exhaustif: instituts de crédit, études professionnelles, fournisseurs / conseillers qui gèrent et /ou participent à la gestion et /ou à l’entretien des appareils électroniques et/ou télématiques que nous utilisons, compagnies d’assurance pour des temps strictement nécessaires à l’exécution optimale du service, collaborateurs externes).
Votres données personnelles seront rendues accessibles seulement à ceux qui en auront l’effective nécessité en relation avec l’exercice de leur tâche ou position hiérarchique. Ces derniers, seront adéquatement instruits pour éviter des pertes, distructions, accès ou traitements non autorisé des données-mêmes.
Les sociétés auxquelles les données peuvent être communiquées pour les fins du point 1b agiront en qualité de Titulaires indépendants du traitement.
Sans votre consentement déclaré, le Titulaire pourrait communiquer votres données à des organismes de vigilance, autorités judiciaires ainsi qu’à tous les autres sujets envers lesquels la communication est obligatoire selon une clair disposition légale.
                </p>                
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>5 DIFFUSION DES DONNES</strong>
                    </p>
                  </li>
                </ul>
                <p>Les données personnelles ne peuvent pas être diffusées, si vous n’avez pas donné votre consentement.</p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>6 TRASMISSION DES DONNEES A L’ETRANGER</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Les données personnelles peuvent être diffusées dans les Pays de l’Union Européenne et dans les Pays extérieurs à la Communauté pour les fins du point 1a.
Dans ce cas, le Titulaire assure dès maintenant que la diffusion des données Extra-Ue aura lieu conformément aux art. 44 ss. du Règlement et aux dispositions des lois appliquables en concluant, si nécessaire, des accords qui assurent un niveau de protection suffisant.
                </p>                
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>7 CONSERVATION DES DONNEES</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Les données sont conservées pour la période strictement nécessaire à l’exécution de l’activité du point 1a conformément au code civil, à la normative fiscale et aux normes et règlements du CONI et de la WRF et pour une période inférieure à dix années. La vérification sur l’obsolescence des données traitées pour les fins pour lesquelles sont collectées et traitées est effectuée régulièrement. Il n’existe aucun procès décisionnel automatisé ni aucune activité de profilage de l’article 22, paragraphes 1 et 4 du GDPR.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>8 DROITS DE L’INTERESSE</strong>
                    </p>
                  </li>
                </ul>
                <p>
                En qualité d’intéressé au traitement des données, on vous informe que vous avez la possibilité d’exercer les droits prévus par le Règlement, et précisement:
                </p>
                <ul>
                  <ul>
                    <li>
                      <p>
                        <b>a)</b> le droit, aux sens de l’art. 15, d’obtenir la confirmation qu’un traitement de données personnelles qui vous concerne est effectivement en cours et, dans ce cas, d’obtenir l’accès aux données personnelles et aux informations suivantes: i) les fins du traitement; ii) les catégories des données personnelles en cause; iii) les destinataires ou les catégories des destinataires auxquels les données personnelles ont été ou seront communiqués, en particulier si pays tiers ou organisations internationales; iv) si possible, la période de conservation des données personnelles prévue ou, si ce n’est pas possible, les critères utilisés pour déterminer la période; v) l’existence du droit de l’intéressé de demander au Titulaire du traitement la rectification ou l’élimination des données personnelles ou la limitation du traitement des données personnelles qui le regardent ou de s’opposer à leur traitement; vi) le droit d'introduire une réclamation auprès d'une autorité de contrôle, aux sens des art. 77 ss. du Règlement; vii) si les données n’ont pas été collectées auprès de l’intéressé, toutes les informations disponibles sur leur origine; viii) l’existence d’un processus décisionnel automatisé, y compris le profilage de l’article 22, paragraphes 1 et 4 du Règlement et, au moins dans ces cas, les informations significatives sur la logique utilisée, et l’importance et les conséquences pour l’intéressé prévues par ce traitement; ix) le droit d’ être informé de l’existence de garanties appropriées aux sens de l’article 46 du Règlement rélative à la trasmission, si les données personnelles sont trasmises à un Pays tiers ou à une organisation internationale;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>b)</b> L’intéressé aura également (si applicable) la possibilité d’exercer ses droits des art. 16-21 du Règlement (droit de rectification, droit à l’oubli, droit à la limitation du traitement, droit à la portabilité des données, droit d’opposition).
                      </p>
                    </li>
                  </ul>
                </ul>
                <p>
                On vous informe que notre Fédération s’engage à répondre à votres demandes dans un délai maximum d'un mois à compter de la réception. Tel délai pourrait être prolongé en fonction de la complexité ou du nombre des demandes et la Fédération vous expliquera la raison de la prolongation dans un délai maximum d'un mois à compter de votre demande. Le résultat de votre demande pourra être communiqué par écrit, ou en version imprimée ou electronique.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>9 TITULAIRE DU TRAITEMENT.</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Le Titulaire du traitement est la “Federazione Italiana Rafting” dont le siège social se situe sur Piazza San Paolo, 2, 13900, Biella (BI), C.F./P.I. 00689760148, tel. 0165 51 64 14 - E-mail:{" "}
                  <u>
                    <a href="mailto:segreteria@federrafting.it">segreteria@federrafting.it</a>
                  </u>
                </p>
                <p>
                La liste actualisée des responsables du traitement est disponible en envoyant la demande à{" "}
                  <u>
                    <a href="mailto:segreteria@federrafting.it">segreteria@federrafting.it</a>
                  </u>
                  .
                </p>
              </>
            ) : (
              <>
                <p>
                  <strong>INFORMATIVA AL TRATTAMENTO DEI DATI PERSONALI</strong>
                </p>
                <p>ART. 13 REGOLAMENTO (UE) 2016/679</p>
                <p>(GENERAL DATA PROTECTION REGULATION - GDPR)</p>
                <p>
                  <strong>TESSERATI TURISTI AMATORI – GESTIONALE FEDERALE</strong>
                </p>
                <br />
                <p>
                  Gentile <strong>Tesserato</strong>,
                </p>
                <p>
                  ai sensi dell’art. 13 Regolamento (UE) 2016/679 (di seguito “GDPR&quot;), La informiamo che i dati personali, da Lei volontariamente messi a
                  disposizione e di cui entrerà in possesso la Federazione Italiana Rafting (F.I.Raft), in qualità di titolare del trattamento (di seguito
                  “Titolare”), potranno formare oggetto di trattamento con le modalità e per le finalità indicate di seguito e, comunque, nel rispetto del
                  principio di riservatezza cui è ispirata l’attività della Federazione..
                </p>
                <p>
                  La Federazione Italiana Rafting in qualità di Titolare del trattamento informa che i dati dell’esercente la responsabilità genitoriale/
                  tutoria verranno trattati per la rilevazione e l’accertamento del consenso del soggetto minore (la relativa informativa è disponibile sul sito{" "}
                  <u>
                    <a href="http://www.federrafting.it/">www.federrafting.it</a>
                  </u>
                  ).
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>1 BASE GIURIDICA E FINALITA’ DEL TRATTAMENTO </strong>
                    </p>
                  </li>
                </ul>
                <p>
                La base giuridica del trattamento si identifica (a titolo esemplificativo) nel conferimento del consenso al trattamento dei dati
personali, nel d.lgs. n. 242/1999 e ss. mm., nelle vigenti norme in materia di idoneità alla pratica sportiva (D.M. 18/02/1982 e
D.M. 24/04/2013) e nello Statuto CONI.
                </p>
                <p>
                I Suoi dati personali, acquisiti dalla Federazione in occasione dell’atto di tesseramento ovvero a seguito dell’atto di elezione,
nomina o inquadramento, verranno trattati per le finalità istituzionali (1a), a titolo esemplificativo:
                </p>
                <ul>
                  <li>
                    <p>- esecuzione delle attività di tesseramento;</p>
                  </li>
                  <li>
                    <p>
                      - esecuzione delle attività connesse al rapporto istaurato con il tesseramento, ivi incluse le attività correlate alla gestione
dei rapporti con il CONI, la World Rafting Federation (WRF) nonché le assicurazioni;
                    </p>
                  </li>
                  <li>
                    <p>
                      - organizzazione e gestione delle competizioni e manifestazioni sportive e/o degli eventi sportivi regionali, nazionali ed
internazionali;
                    </p>
                  </li>
                  <li>
                    <p>- iscrizione alle competizioni, manifestazioni sportive e/o eventi sportivi regionali, nazionali ed internazionali;</p>
                  </li>
                  <li>
                    <p>
                      - evasione delle richieste, in qualsiasi forma sottoposte all’attenzione della Federazione, degli interessati relativamente ad
attività di competenza della stessa;
                    </p>
                  </li>
                  <li>
                    <p>
                      - erogazione di un riscontro in merito alle istanze degli interessati in ordine all’iscrizione ad elenchi, eventi, gare,
manifestazioni e/o iniziative relative alla Federazione ed alla successiva gestione degli stessi;
                    </p>
                  </li>
                  <li>
                    <p>
                      - adempimenti connessi ad obblighi previsti dalla legge, dai regolamenti, dallo statuto e dai regolamenti federali e, in
generale, dalla normativa di volta in volta applicabile sia a carattere nazionale che internazionale;
                    </p>
                  </li>
                  <li>
                    <p>
                      - invio di materiale pubblicitario, informativo e di comunicazioni commerciali da parte della Federazione strettamente
connesso ai propri fini istituzionali.
                    </p>
                  </li>
                  <li>
                    <p>
                      - invio di materiale pubblicitario, informativo e di comunicazioni commerciali strettamente connesso ai propri fini
istituzionali da parte dell’Affiliato presso il quale si risulta essere tesserati o presso il quale si è svolta attività sportiva
istituzionale, previo ricevimento di apposita informativa.
                    </p>
                  </li>
                </ul>
                <p>
                Il trattamento, previo consenso dell’interessato, potrà essere finalizzato altresì all’invio di informazioni di natura commerciale e
promozionale di aziende del settore sportivo e società con le quali la F.I.Raft o il CONI abbiano rapporti di natura contrattuale
(1b), in conformità a quanto stabilito dal GDPR.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>2 MODALITA’ DEL TRATTAMENTO</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Il trattamento sarà effettuato con le seguenti modalità: su schede manuali, realizzate anche con l’ausilio di mezzi elettronici,
conservate in luoghi chiusi ad accesso selezionato, ovvero in maniera informatizzata tramite supporti contro i rischi informatici
(firewall, antivirus, backup periodico dei dati); i dati potranno essere trattati da soggetti, interni o esterni, appositamente
autorizzati ed impegnati alla riservatezza. Per la definizione di “trattamento”, vedasi art. 4, paragrafo 2, GDPR.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>3 CONFERIMENTO DEI DATI E RIFIUTO</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Il conferimento di dati personali “comuni” (a titolo esemplificativo, nominativo, indirizzo o altri elementi di identificazione
personale, ivi compresa la Sua immagine ed eventualmente gli estremi del conto corrente bancario di Sua pertinenza) è
strettamente necessario ai fini dello svolgimento delle attività di cui al punto 1a. Può altresì accadere che il conferimento di
particolari di dati personali “sensibili” (a titolo esemplificativo, dati sanitari e i dati personali idonei a rivelare lo stato di salute, anche in relazione a trattamenti necessari in relazione ad emergenze sanitarie nazionali) sia necessario ai fini dello svolgimento
delle attività di cui al punto 1a. Il consenso per l’utilizzo dei dati per le finalità di cui al punto 1b è, invece, facoltativo.
L’eventuale rifiuto da parte dell’interessato di conferire dati personali comporterebbe l’impossibilità di adempiere alle attività di
cui al punto 1a. Un eventuale rifiuto al trattamento dei dati per le finalità di cui al punto 1b, invece, non avrà conseguenza alcuna
sulla richiesta di tesseramento.
                </p>                
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>4 COMUNICAZIONE DEI DATI</strong>
                    </p>
                  </li>
                </ul>
                <p>
                I dati personali possono essere trattati per conto del Titolare per le finalità di cui al punto 1a dal CONI, dalla World Rafting
Federation (WRF), nonché da società terze che svolgono attività per conto della Federazione, nella loro qualità di responsabili
esterni del trattamento (a titolo meramente esemplificativo e non esaustivo: istituti di credito, studi professionali, fornitori /
consulenti che gestiscono e/o partecipano alla gestione e/o alla manutenzione degli strumenti elettronici e/o telematici da noi
utilizzati, compagnie assicurative per i tempi strettamente necessari all’ottimale esecuzione di tale servizio, collaboratori esterni).
I Suoi dati personali saranno resi accessibili solo a coloro i quali ne abbiano effettiva necessità in relazione all’esercizio della
propria mansione o posizione gerarchica. Tali soggetti, saranno opportunamente istruiti al fine di evitare perdite, distruzione,
accessi non autorizzati o trattamenti non consentiti dei dati stessi.
Le società alle quali possono altresì essere comunicati i dati per le finalità di cui al punto 1b opereranno in qualità di autonomi
Titolari del trattamento.
Senza il Suo espresso consenso, il Titolare potrà comunicare i Suoi dati ad organismi di vigilanza, autorità giudiziarie nonché a
tutti gli altri soggetti verso i quali la comunicazione sia obbligatoria in base ad un’espressa disposizione di legge.
                </p>                
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>5 DIFFUSIONE DEI DATI</strong>
                    </p>
                  </li>
                </ul>
                <p>I dati personali non sono soggetti a diffusione, se non previo esplicito consenso dell’interessato.</p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>6 TRASFERIMENTO DEI DATI ALL'ESTERO</strong>
                    </p>
                  </li>
                </ul>
                <p>
                I dati personali possono essere trasferiti verso Paesi dell’Unione Europea e verso Paesi terzi rispetto all’Unione Europea
nell’ambito delle finalità di cui al punto 1a.
In tal caso, il Titolare assicura sin d’ora che il trasferimento dei dati Extra-UE avverrà in conformità agli artt. 44 ss. del
Regolamento ed alle disposizioni di legge applicabili stipulando, se necessario, accordi che garantiscano un livello di protezione
adeguato.
                </p>                
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>7 CONSERVAZIONE DEI DATI</strong>
                    </p>
                  </li>
                </ul>
                <p>
                I dati sono conservati per il periodo strettamente necessario all’espletamento dell’attività di cui al punto 1a secondo quanto
previsto dal codice civile, dalla normativa fiscale e dalle norme e regolamenti del CONI e della WRF e per un periodo comunque
non superiore a dieci anni. La verifica sulla obsolescenza dei dati oggetto di trattamento rispetto alle finalità per le quali sono stati
raccolti e trattati viene effettuata periodicamente. Non esiste alcun processo decisionale automatizzato né alcuna attività di
profilazione di cui all’articolo 22, paragrafi 1 e 4 del GDPR.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>8 DIRITTI DELL'INTERESSATO</strong>
                    </p>
                  </li>
                </ul>
                <p>
                In qualità di interessato al trattamento dei Dati, La informiamo che ha la possibilità di esercitare i diritti previsti dal Regolamento,
e precisamente:
                </p>
                <ul>
                  <ul>
                    <li>
                      <p>
                        <b>a)</b> il diritto, ai sensi dell’art. 15, di ottenere la conferma che sia o meno in corso un trattamento di dati personali che la
riguardano e, in tal caso, di ottenere l’accesso ai dati personali e alle seguenti informazioni: i) le finalità del trattamento ii)
le categorie di dati personali in questione; iii) i destinatari o le categorie di destinatari a cui i dati personali sono stati o
saranno comunicati, in particolare se paesi terzi o organizzazioni internazionali; iv) quando possibile, il periodo di
conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo; v)
l’esistenza del diritto dell’interessato di chiedere al Titolare del trattamento la rettifica o la cancellazione dei dati personali
o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento; vi) il diritto di proporre
reclamo a un’autorità di controllo, ai sensi degli artt. 77 ss. del Regolamento; vii) qualora i dati non siano raccolti presso
l’interessato, tutte le informazioni disponibili sulla loro origine; viii) l’esistenza di un processo decisionale automatizzato,
compresa la profilazione di cui all’articolo 22, paragrafi 1 e 4 del Regolamento, e, almeno in tali casi, informazioni
significative sulla logica utilizzata, nonché l’importanza e le conseguenze previste di tale trattamento per l’interessato; ix)
il diritto di essere informato dell’esistenza di garanzie adeguate ai sensi dell’articolo 46 del Regolamento relative al
trasferimento, qualora i dati personali siano trasferiti a un paese terzo o a un’organizzazione internazionale;
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>b)</b> l’interessato avrà altresì (ove applicabili) la possibilità di esercitare i diritti di cui agli artt. 16-21 del Regolamento (diritto
di rettifica, diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità dei dati, diritto di opposizione).
                      </p>
                    </li>
                  </ul>
                </ul>
                <p>
                La informiamo che la nostra Federazione si impegna a rispondere alle Sue richieste al più tardi entro un mese dalla data del
ricevimento. Tale termine potrebbe essere prorogato in funzione della complessità o numerosità delle richieste e la Federazione
provvederà a spiegarLe il motivo della proroga entro un mese dalla Sua richiesta. L’esito della Sua richiesta potrà esserLe fornito
per iscritto, o in formato cartaceo o in formato elettronico.
                </p>
                <br />
                <ul>
                  <li>
                    <p>
                      <strong>9 TITOLARE DEL TRATTAMENTO.</strong>
                    </p>
                  </li>
                </ul>
                <p>
                Titolare del trattamento è la “Federazione Italiana Rafting” con sede legale in Piazza San Paolo, 2, 13900, Biella (BI), C.F./P.I.
00689760148, tel. 0165 51 64 14 - E-mail:{" "}
                  <u>
                    <a href="mailto:segreteria@federrafting.it">segreteria@federrafting.it</a>
                  </u>
                </p>
                <p>
                  L’elenco aggiornato dei responsabili del trattamento è disponibile inoltrando richiesta a{" "}
                  <u>
                    <a href="mailto:segreteria@federrafting.it">segreteria@federrafting.it</a>
                  </u>
                  .
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TesseratiTuristiAmatori;
