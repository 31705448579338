import React, { useState, useEffect } from "react";
import Error404 from "./../../template/Error404";
import Loader from "./../../template/Loader";
import { fetchActiveBooking, cancelActiveBooking } from "./../../model/booking";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import dayjs from "dayjs";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ManageBooking = (props) => {
  const { t, i18n } = useTranslation();

  const [exist, setExist] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [bookingData, setBookingData] = useState(false);
  const [cancellationCutoffHours, setCancellationCutoffHours] = useState(48);

  useEffect(() => {
    if (props.match?.params?.id) {
      handleFetchActiveBooking(props.match?.params?.id);
    }
  }, [props.match?.params?.id]);

  const handleFetchActiveBooking = async () => {
    setIsLoading(true);
    let response = await fetchActiveBooking(props.match.params.id);
    if (response.status === "OK") {
      if (response.data !== false) {
        setExist(true);
        setBookingData(response.data);
        if( response.data[0].cancellation_cutoff_hours !== null){
          setCancellationCutoffHours(response.data[0].cancellation_cutoff_hours)
        }
      } else {
        setExist(false);
      }
    } else {
      setExist(false);
    }
    setIsLoading(false);
  };

  const handleRequestCancelation = async () => {
    setIsSending(true);
    setTimeout(() => {
      let confirmed = window.confirm(t("Sei sicuro? L'operazione non può essere annullata"));
      if (confirmed) {
        handleCancelAtiveBooking();
      } else {
        setIsSending(false);
      }
    }, 100);
  };

  const handleCancelAtiveBooking = async () => {
    setIsSending(true);
    let response = await cancelActiveBooking(props.match.params.id);
    if (response.status === "OK") {
      alert(t("Cencelled"));
      handleFetchActiveBooking();
    } else {
      alert("Error")
      handleFetchActiveBooking();
    }
    setIsSending(false);
  };


  if (!props.match.params.id) return <Error404 />;
  if (isLoading) return <Loader isLoading={isLoading} />;
  if (!exist) return <Error404 />;

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="box has-background-warning">
            <h3 className="subtitle is-4">
              {t("Prenotazione")}:{" "}
              <b className={bookingData[0].status === "active" ? "has-text-success" : "has-text-danger"}>
                {bookingData[0].status === "active" ? t("Attiva") : t("Cancellata")}
                {bookingData[0].status !== "active" && bookingData[0].cancellation_date !== null && (
                  <span className="is-size-6"> {dayjs(bookingData[0].cancellation_date).format("YYYY/MM/DD HH:mm")}</span>
                )}
              </b>
            </h3>
            <p className="pt-2">
              {t("Id")}: <b>{bookingData[0].payment_id}</b>
            </p>
            <p className="pt-2">
              {t("Associazione")}:{" "}
              <b>
                {bookingData[0].name} | {bookingData[0].display_name}
              </b>
            </p>
            <p className="pt-2">
              {t("Servizio")}: <b>{bookingData[0].resource_service_name}</b>
            </p>
            <p className="pt-2">
              {t("Costo biglietti")}: <b>{bookingData[0].ticket_amount}€</b>
            </p>
            <p className="pt-2">
              {t("Data e ora")}: <b>{dayjs(bookingData[0].book_date).format("DD/MM/YYYY HH:mm")}</b>
            </p>
            <table className="table is-fullwidth table table is-striped mt-4 has-background-warning-light">
              <thead>
                <tr>
                  <th colSpan="2">{t("Dati partecipante")}</th>
                </tr>
              </thead>
              <tbody>
                {(bookingData || []).map((member, i) => {
                  return (
                    <tr key={"rm-" + i}>
                      <td>{member.nome}</td>
                      <td>{member.cognome}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {bookingData[0].status === "active" && (
              <div>
                <button onClick={handleRequestCancelation} className={isSending ? `button is-danger is-loading` : `button is-danger`}>
                  <span>{t("Cancella prenotazione")}</span>
                  <span className="icon">
                  <i className="far fa-calendar-times"></i>
                  </span>
                </button>
                <div className="mt-2">
                  <span className={"is-size-7 has-text-grey"}>{t("Termine per l'annullamento del Biglietto con rimborso")}: {dayjs(bookingData[0].book_date).subtract(cancellationCutoffHours, "hours").format("DD/MM/YYYY HH:mm")}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageBooking;
