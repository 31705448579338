import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "./../store/AppContext";
import { getBaseConfig } from "./../model/admin";

const AdmHead = () => {
  const { context } = useContext(AppContext);

  const [baseConfig, setBaseConfig] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const toggleIsActive = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  useEffect(() => {
    handleGetBaseConfig();
  }, []);


  const handleGetBaseConfig = async () => {
    try {
      const data = await getBaseConfig();
      setBaseConfig(data);
    } catch (e) {
    } finally {
    }
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/admin/dashboard" className="navbar-item">
          <img className="headLogo" src="/img/firaft-logo.png" alt="FIRAFT" />
        </Link>

        <a href="/" onClick={toggleIsActive} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${isActive && "is-active"}`}>
        <div className="navbar-start">
          <Link to="/admin/dashboard" className="navbar-item">
            Dashboard
          </Link>

          <Link to="/admin/resources" className="navbar-item">
            Attività
          </Link>

          <Link to="/admin/bookings" className="navbar-item">
            Prenotazioni
          </Link>

          <Link to="/admin/cardEmit" className="navbar-item">
            Caricamento massivo
          </Link>

          <Link to="/admin/cardsList" className="navbar-item">
            Tessere Emesse
          </Link>     

          <Link to="/admin/paymentsList" className="navbar-item">
            Pagamenti ricevuti
          </Link>   

          <a href="https://manuale.firaftbooking.it/" target="_blank" rel="noreferrer" className="navbar-item">
            FAQ
          </a>                    

          {/* <div className="navbar-item has-dropdown is-hoverable">
        <a className="navbar-link">
          More
        </a>

        <div className="navbar-dropdown">
          <a className="navbar-item">
            About
          </a>
          <a className="navbar-item">
            Contact
          </a>
          <hr className="navbar-divider" />
          <a className="navbar-item">
            Report an issue
          </a>
        </div>
      </div> */}
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link to="/admin/dashboard" className="button is-link is-inverted">
                <span className="icon">
                  <i className="fas fa-user" aria-hidden="true"></i>
                </span>
                <span>{context?.email}</span>
              </Link>
              <Link to="/admin/logout" className="button is-light">
                <span className="icon">
                  <i className="fas fa-sign-out-alt" aria-hidden="true"></i>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdmHead;
