const legal_card_it = 
`
**Il/la sottoscritto/a chiede di essere tesserato alla Federazione Italiana Rafting quale “Turista-Amatore” per il tramite dell’Affiliato
{{affiliato}}.**

Il/la sottoscritto/a, con l’invio del presente modulo, dichiara:
- 1. di non essere a conoscenza di alcuna patologia che possa costituire controindicazione per l’attività per cui richiede il tesseramento;
- 2. di accettare espressamente tutte le norme dello Statuto e dei Regolamenti della F.I.Raft e del CONI, nonché di obbligarsi ad
osservarli ed adempiervi senza alcuna eccezione;
- 3. di autorizzare la Federazione e l’Affiliato, presso il quale si richiede il tesseramento, all’utilizzo delle immagini e/o
all’effettuazione di riprese video che lo/a vedono ritratto/a per finalità istituzionali. Dichiara inoltre che nulla gli è dovuto, né ora,
né in futuro, per l’utilizzo del suddetto materiale fotografico e/o video da parte dell’Affiliato, che potrebbe essere utilizzato per la
preparazione di brochure, per il sito internet, locandine, album figurine, calendari, sollevando sin da ora l’utilizzatore da ogni
responsabilità;
- 4. di dare il consenso al trattamento dei propri dati personali ai sensi all’articolo 13 del GDPR, letta l’[**informativa**](https://firaftbooking.it/terms/utenti-prenotazione) nelle modalità e per le finalità indicate al punto 1a, strettamente
connesse e strumentali alla gestione del rapporto di tesseramento, che consentono il loro trattamento nella misura necessaria per il
perseguimento degli scopi statutari.;

Premesso e ribadito quanto sopra, con la sottoscrizione del presente modulo si sottoscrive quanto dichiarato ai precedenti punti, da 1 a 4, ai
sensi dell’art. 1341 e s.s. del c.c..
`;

const legal_card_underage_it = 
`
**Il/la sottoscritto/a, in qualità di esercente la responsabilità genitoriale/tutoria**, con l’invio della presente, **dichiara**:
- 1. **di sottoscrivere in nome e per conto del minore quanto indicato nella richiesta di tesseramento e/o nel consenso informato all’attività** di cui sopra;
- 2. di dare il consenso al trattamento dei propri dati personali ai sensi all’articolo 13 del GDPR, letta l’[**informativa**](https://firaftbooking.it/terms/tutori) nelle modalità e per le finalità indicate al punto 1.

Premesso e ribadito quanto sopra, con la sottoscrizione del presente modulo si sottoscrive quanto dichiarato ai precedenti punti
1 e 2 ai sensi dell’art. 1341 e s.s. del c.c..
`;

const legal_card_marketing_it = 
`
Esprime, inoltre, il consenso alla comunicazione dei dati per finalità, indicate al punto 1b dell’informativa, di marketing e promozionali a terzi
con i quali la F.I.Raft o il CONI abbiano rapporti di natura contrattuale, e da questi trattati nella misura necessaria all’adempimento di obblighi
previsti dalla legge e dai contratti.
`;

module.exports = {legal_card_it, legal_card_underage_it, legal_card_marketing_it};
