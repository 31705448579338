import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import Loader from "./../../../template/Loader";
import _ from "lodash";
import { fetchPaymentIntent, fakePayment } from "./../../../model/booking";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from 'react-i18next';

const BookingPayment = (props) => {
  const { t, i18n } = useTranslation();

  let stipeLocale = i18n.language === "en" || i18n.language === "de" ? i18n.language : "it";
  const stripePromise = loadStripe(props.appConfig.stripePublicKey, {locale: stipeLocale});
  
  const [intent, setIntent] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const getPaymentIntent = async () => {
    let response = await fetchPaymentIntent({ bookingData: props.bookingData, membersData: props.membersData });
    if (response.status === "OK") {
      setIntent(response.data);
    } else {
      var r = window.confirm(t("Si è verificato un errore nel calcolo del costo, prova di nuovo o annulla ed effettua nuovamente la prenotazione!"));
      if (r === true) {
        fetchPaymentIntent();
      }
    }
  };

  useEffect(() => {
    getPaymentIntent();
  }, [props]);

  const setCompleted = () => {
    setIsCompleted(true);
  };

  if (intent === false) {
    return <Loader isLoading={true} />;
  }


  const handleFakePayment = async ()=>{
    let body = {
      id: intent.paymentIntent.id,
      type: "payment_intent.succeeded",
      data: {
        object: {
          id: intent.paymentIntent.id,
          amount: 700,
          application_fee_amount: 241 
        }
      }
    }
    let response = await fakePayment(body);
  }

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <h3 className="subtitle is-4" onClick={() => getPaymentIntent()}>
            { !isCompleted && t("Effettua il pagamento per confermare la tua prenotazione")}
          </h3>

          <div className={`box ${ !isCompleted ? "has-background-warning" : "has-background-success-light"}`}>
            {!isCompleted ? (
              <>
                <h3 className="subtitle is-3">
                  <button
                    className={`button block ${ !isCompleted ? "is-warning" : "is-success"}`}
                    onClick={() => {
                      //props.clearBooking();
                      props.clearMembersOnly();
                    }}
                  >
                    <span className="icon">
                      <i className="fas fa-arrow-left"></i>
                    </span>
                  </button>{" "}
                  {t("Riepilogo")}
                </h3>
              </>
            ) : (
              <h3 className="subtitle is-3">
                <span className="icon">
                  <i className="fas fa-ticket-alt"></i>
                </span>{" "}
                {t("Conferma")}
              </h3>
            )}

            <p className="pt-2">
              {t("Associazione")}:{" "}
              <b>
                {props.bookingData.resource.username}
                {props.bookingData.resource?.display_name && " | " + props.bookingData.resource?.display_name}
              </b>
            </p>
            <p className="pt-2">
            {t("Servizio")}:{" "}
              <b>
                {props.bookingData.resource.name} | {props.bookingData.service.name}
              </b>
            </p>
            <p className="pt-2">
              {t("Limiti di età")}: <b>{props.bookingData.service.min_age}-{props.bookingData.service.max_age}</b>
            </p>            
            <p className="pt-2">
              {t("Durata")}: <b>{props.bookingData.service.duration} {t("minuti")}</b>
            </p>
            <p className="pt-2">
              {t("Prezzo per persona")}:{" "}
              <b>
                {props.bookingData.service?.pax_price !== 0 ? props.bookingData.service.pax_price + " €" : "0 €"} ({t("escluso eventuale costo del tesseramento")}) {t("pari a")} 2€
              </b>
            </p>
            <p className="pt-2">
              {t("Data e ora")}: <b>{dayjs(props.bookingData.slot?.date_start).format("DD/MM/YYYY HH:mm")}</b>
            </p>

            <hr />

            {intent?.needCard?.length > 0 && 
              <p className="pt-2">
                {intent.needCard
                  .replace("Il costo della prenotazione comprende il costo del tesseramento di", t("Il costo della prenotazione comprende il costo del tesseramento di"))
                  .replace("Il tesseramento è obbligatorio per accedere ai servizi, ogni tessera ha un costo di", t("Il tesseramento è obbligatorio per accedere ai servizi, ogni tessera ha un costo di"))}
              </p>
            }

            {intent.cardsPrice > 0 && <p className="pt-2">{t("Costo tessere")}: {intent.cardsPrice / 100}€</p>}
            <p className="pt-2">{t("Costo servizio")}: {intent.ticketPrice / 100}€</p>
            <p className="pt-2">
              {t("Costo totale")}: <b>{intent.totalPrice / 100}€</b>
            </p>
          </div>

          <div className="box has-background-info-light">
            <Elements stripe={stripePromise}>
              <CheckoutForm intent={intent} setCompleted={setCompleted} />
            </Elements>
          </div>

          { process?.env?.NODE_ENV === "development" &&
            <button className={`dev-button`} onClick={handleFakePayment}>
              Pagamento test
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default BookingPayment;

const CheckoutForm = ({ intent, setCompleted }) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //const [intent, setIntent] = useState(false);

  useEffect(() => {
    //getPaymentDetails()
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setStripeError(false);
    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
      setStripeError(error.message);
      setIsLoading(false);
    } else {
      let confirmPayment = await stripe.confirmCardPayment(intent.paymentIntent.client_secret, { payment_method: paymentMethod.id }, { handleActions: true });
      setPaymentStatus(confirmPayment?.paymentIntent?.status);
      setCompleted();
      setIsLoading(false);
      
    }
  };

  if (paymentStatus === "succeeded") {
    return (
      <div>
        <h4 className="title is-3 has-text-success">{t("Pagamento effettuato")}</h4>
        <h5 className="subtitle is-5">{t("Riceverai la conferma della prenotazione via email e se necessario un link per completare il tesseramento.")}</h5>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <h4 className="title is-4">{t("Totale da versare")}: {intent.paymentAmount / 100}€</h4>
      <h5 className="subtitle is-5">
        {
        typeof intent?.paymentInfo !== "undefined" &&
          intent.paymentInfo
          .replace("Il pagamento comprende la quota dei biglietti più l'eventuale quota per i tesseramenti.", t("Il pagamento comprende la quota dei biglietti più l'eventuale quota per i tesseramenti."))
          .replace("Il pagamento è relativo alle tessere, il pagamento dei servizi, ove previsto, verrà effettuato con le modalità definite dall'associazione.", t("Il pagamento è relativo alle tessere, il pagamento dei servizi, ove previsto, verrà effettuato con le modalità definite dall'associazione."))
        }
      </h5>
      {stripeError ? (
        <p className="has-text-danger pt-2 pb-4" s>
          {stripeError}
        </p>
      ) : (
        <p className="has-text-danger pt-2 pb-4">&nbsp;</p>
      )}
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "18px",
              color: "#424770",
              "::placeholder": {
                color: "#000000",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
      <button className={`button is-primary mt-6 ${isLoading && "is-loading"}`} type="submit" disabled={isLoading}>
        {t("Effettua il pagamento")}
      </button>

      {/* <code className="has-text-primary">{JSON.stringify(intent, null, 2)}</code>  */}
    </form>
  );
};
