import React, { useEffect, useState, Suspense, useRef, useCallback } from "react";
import Breadcumb from "./components/Breadcumb";
import Loader from "./../../template/Loader";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { getCardsList, getBaseConfig, editCard, deleteCard } from "./../../model/admin";
import { Link } from "react-router-dom";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";

import "rsuite-table/dist/css/rsuite-table.css";

const CardsList = (props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [theResults, setTheResults] = useState([]);
  const [theData, setTheData] = useState([]);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortType, setSortType] = useState("asc");

  const [chooseDate, setChooseDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 11, 31));

  const [isAdmin, setIsAdmin] = useState(false);
  const [modal, setModal] = useState(false);
  const [editResponse, setEditResponse] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newValue, setNewValue] = useState(false);
  const [codeFilter, setCodeFilter] = useState(false);

  const codeInput = useRef();
  
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(()=>{
    handleGetBaseConfig();
  },[])

  const handleGetBaseConfig = async () => {
    try {
      const data = await getBaseConfig();
      if (data.affiliation_code === 10){
        setIsAdmin(true);
      }else{
        setIsAdmin(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  // async function handleGetCardsList() {
  //   //const response = await getCardsList(dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD"), dayjs().endOf("year").format("YYYY-MM-DD"));
  //   const response = await getCardsList(dayjs().startOf("year").format("YYYY-MM-DD"), dayjs().endOf("year").format("YYYY-MM-DD"), codeFilter);
  //   setTheData(response.data.cards);
  //   setTheResults(response.data.cards);
  //   console.log(response.data.cards);
  // }

  async function handleGetCardsListTimeFrame() {
    setChooseDate(false);
    const response = await getCardsList(dayjs(startDate).format("YYYY-MM-DD"), dayjs(endDate).format("YYYY-MM-DD"), codeFilter);
    setTheData(response.data.cards);
    setTheResults(response.data.cards);
    console.log(response.data.cards);
  }

  useEffect(() => {
    handleGetCardsListTimeFrame();
  }, []);

  useEffect(() => {
    handleGetCardsListTimeFrame();
  }, [codeFilter]);

  async function handleSortColumn(_sortColumn, _sortType) {
    setSortColumn(_sortColumn);
    setSortType(_sortType);

    let _theData = theData;

    console.log(theData);

    _theData.sort((a, b) => {

      if(a[_sortColumn] < b[_sortColumn]) { return _sortType === "asc" ? -1 : 1 }
      if(a[_sortColumn] > b[_sortColumn]) { return _sortType === "asc" ? 1 : -1 }
      return 0;

      // let x = a[_sortColumn];
      // let y = b[_sortColumn];
      // if (typeof x === "string") {
      //   x = x.charCodeAt();
      // }
      // if (typeof y === "string") {
      //   y = y.charCodeAt();
      // }
      // if (_sortType === "asc") {
      //   return x - y;
      // } else {
      //   return y - x;
      // }
    });

    setTheData(_theData);
  }

  async function onlyBulk() {
    let _theData = theResults;
    let filtered = _.filter(_theData, { origine: "bulk" });
    setTheData(filtered);
  }

  async function onlyRegistration() {
    let _theData = theResults;
    let filtered = _.filter(_theData, { origine: "registration" });
    setTheData(filtered);
  }

  async function onlyApi() {
    let _theData = theResults;
    let filtered = _.filter(_theData, { origine: "ext-api-v1" });
    setTheData(filtered);
  }

  async function showAll() {
    setTheData(theResults);
  }

  function LinkCell({ rowData, dataKey, ...props }){
    return <Cell {...props}><a href={rowData[dataKey]} target="_blank" rel="noreferrer">{rowData[dataKey]}</a></Cell>
  }

  function ActionCell({ rowData, dataKey, ...props }){
    function handleAction() {
      setModal(rowData);
    }
    return (
      <Cell {...props} className="link-group">
        <span onClick={handleAction} class="icon"><i class="fas fa-edit" aria-hidden="true"></i></span>
      </Cell>
    );
  };


  function changeValue(name, value){
    setModal(prevState => ({
      ...prevState,
      [name]: value
  }));
  };


  const handleEditRow= async () => {
    setIsSaving(true);
    setEditResponse(false);
    try {
      const data = await editCard(modal);
      if (data.status === "OK") {
        setModal( false );
        handleGetCardsListTimeFrame();
      } else {
        setEditResponse(data.error);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const confirmDeleteRow= async () => {
    let confirmed = window.confirm("Sei sicuro di eliminare la riga id "+modal.id+"? I dati non saranno recuperabili, pensaci bene.");
    if(confirmed){
      handleDeleteRow();
    }
  };

  const handleDeleteRow= async () => {
    setIsSaving(true);
    setEditResponse(false);
    try {
      const data = await deleteCard(modal.id);
      if (data.status === "OK") {
        setModal( false );
        handleGetCardsListTimeFrame();
      } else {
        setEditResponse(data.error);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };



  return (
    <div className="has-background-warning p-3 resources">
      <Loader isLoading={isLoading} />

      <div className="container">
        <Breadcumb />

        <h2 className="title is-2">Lista tessere emesse</h2>

        {isAdmin && (
          <>
            <h5>
              Filtra per affiliato (prima imposta l'id, oppure scrivi <b>all</b> per tutti, e poi usa i filtri classici):
            </h5>
            <div className=" is-flex is-align-content-center">
              <input className="input is-small" type="text" placeholder="id affiliato" defaultValue={""} ref={codeInput} />
              <button className="button is-primary is-small" onClick={() => setCodeFilter(codeInput.current.value)}>
                Attiva filtro per id affiliato: <b>{codeFilter}</b>
              </button>
            </div>
          </>
        )}

        <div className="content pt-5">
          <h5>Filtri:</h5>
          <div className="buttons">
            <button className="button is-link is-small" onClick={showAll}>
              <span>Tutto</span>
            </button>

            <button className="button is-primary is-small" onClick={onlyBulk}>
              <span>Bulk</span>
            </button>

            <button className="button is-primary is-small" onClick={onlyRegistration}>
              <span>Registration</span>
            </button>

            <button className="button is-primary is-small" onClick={onlyApi}>
              <span>Api</span>
            </button>

            <button className="button is-primary is-small" onClick={() => setChooseDate(!chooseDate)}>
              <span>Filtra per data</span>
            </button>

            <CSVLink data={theData} filename={"export.csv"} className="button is-primary is-small">
              Scarica csv
            </CSVLink>
          </div>

          {chooseDate && (
            <div className="content pb-5">
              <span>Selezionare un range di date, al 1° click la data di inizio ed al 2° la data di fine</span>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                minDate={new Date(new Date().getFullYear()-1, 0, 1)}
                dateFormat={"dd/MM/yyyy"}
                maxDate={new Date(new Date().getFullYear(), 11, 31)}
                selectsRange
                inline
              />
              <button className="button is-primary is-small" onClick={handleGetCardsListTimeFrame}>
                <span>
                  Filtra per data {dayjs(startDate).format("YYYY-MM-DD")} - {dayjs(endDate).format("YYYY-MM-DD")}{" "}
                </span>
              </button>
            </div>
          )}

          <Table
            virtualized
            height={600}
            //autoHeight
            className="dataTable"
            hover
            bordered
            sortColumn={sortColumn}
            sortType={sortType}
            data={theData}
            affixHeader={true}
            onSortColumn={handleSortColumn}
            rowClassName={(data) => "consenso_" + data?.last_consenso_informato + " tessera_"+data?.status}
            onRowClick={(data) => {
              console.log(data);
            }}>
            <Column align="center" width={60} resizable fixed sortable>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            {isAdmin && (
              <Column width={50}>
                <HeaderCell>Edit</HeaderCell>
                <ActionCell dataKey="id" />
              </Column>
            )}
            <Column align="center" width={70} resizable>
              <HeaderCell>Affiliato</HeaderCell>
              <Cell dataKey="id_affiliato" />
            </Column>

            <Column align="center" width={90} resizable sortable>
              <HeaderCell>Origine</HeaderCell>
              <Cell dataKey="origine" />
            </Column>

            <Column width={60} resizable>
              <HeaderCell>Nome</HeaderCell>
              <Cell dataKey="nome" />
            </Column>

            <Column width={60} resizable>
              <HeaderCell>Cognome</HeaderCell>
              <Cell dataKey="cognome" />
            </Column>

            <Column width={150} resizable>
              <HeaderCell>Email</HeaderCell>
              <Cell dataKey="email" />
            </Column>

            <Column width={120} resizable sortable>
              <HeaderCell>Emessa Y-M-D</HeaderCell>
              <Cell dataKey="data_emissione_order" />
            </Column>

            <Column width={80} resizable>
              <HeaderCell>Cod Tessera</HeaderCell>
              <Cell dataKey="cod_tessera" />
            </Column>

            <Column width={60} resizable>
              <HeaderCell>Stagione</HeaderCell>
              <Cell dataKey="stagione" />
            </Column>

            <Column width={60} resizable>
              <HeaderCell>Stato Tessera</HeaderCell>
              <Cell dataKey="status" />
            </Column>

            <Column width={60} resizable>
              <HeaderCell>Link consenso Tesseramento (solo se tessera non emessa)</HeaderCell>
              <LinkCell dataKey="link" />
            </Column>

            <Column width={60} resizable>
              <HeaderCell>Link tessera</HeaderCell>
              <LinkCell dataKey="card_file" />
            </Column>

            <Column width={80} resizable>
              <HeaderCell>Consenso Informato</HeaderCell>
              <Cell dataKey="last_consenso_informato" />
            </Column>

            <Column width={140} resizable>
              <HeaderCell>Data Consenso</HeaderCell>
              <Cell dataKey="last_consenso_informato_data" />
            </Column>

            <Column width={90} resizable>
              <HeaderCell>Privacy Istituzionale</HeaderCell>
              <Cell dataKey="privacy_istituzionale" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>Data Nascita</HeaderCell>
              <Cell dataKey="member_data_nascita" />
            </Column>

            <Column width={60} resizable>
              <HeaderCell>Minore</HeaderCell>
              <Cell dataKey="last_minore" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>Res.Min. Nome</HeaderCell>
              <Cell dataKey="last_member_responsabile_minore_nome" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>Res.Min. Cognome</HeaderCell>
              <Cell dataKey="last_member_responsabile_minore_cognome" />
            </Column>

            <Column width={150} resizable>
              <HeaderCell>Res. (0 Italia / 1 Estero)</HeaderCell>
              <Cell dataKey="straniero" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>Stato di nascita</HeaderCell>
              <Cell dataKey="stato_nascita" />
            </Column>

            <Column width={50} resizable>
              <HeaderCell>Sesso</HeaderCell>
              <Cell dataKey="sesso" />
            </Column>

            <Column width={50} resizable>
              <HeaderCell>Telefono</HeaderCell>
              <Cell dataKey="telefono" />
            </Column>
          </Table>

          {/* <Suspense fallback={<div>Loading...</div>}>
            {theResults.length > 0 && (
              <div className="table-container">
                <table className="table is-fullwidth is-size-7">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Nome</th>
                      <th>Cognome</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {theResults.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className={
                            item.status === "OK"
                              ? "has-background-success-light"
                              : item?.error?.generic?.includes("già emessa") || item?.error?.generic?.includes("già presente")
                              ? "has-background-warning-light"
                              : "has-background-danger-light"
                          }>
                          <td>
                            <span className="icon is-small fa-lg">
                              <i className={item.cardsData.consenso_e_privacy_espressi !== 1 ? "fas fa-envelope" : "fas fa-address-card"} aria-hidden="true"></i>
                            </span>{" "}
                            {theResults.length - i}
                          </td>
                          <td>{item.cardsData.email}</td>
                          <td>{item.cardsData.nome}</td>
                          <td>{item.cardsData.cognome}</td>
                          <td>
                            {item.status === "OK" ? (
                              <SyntaxHighlighter language="json" style={atelierSeasideLight}>
                                {JSON.stringify(item.data, null, 2)}
                              </SyntaxHighlighter>
                            ) : (
                              <SyntaxHighlighter language="json" style={vs}>
                                {JSON.stringify(item.error, null, 2)}
                              </SyntaxHighlighter>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </Suspense> */}

          {modal && (
            <div className="modal is-active">
              <div className="modal-background"></div>
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">
                    Modifica id: <b>{modal.id}</b>
                  </p>
                  <button className="delete" aria-label="close" onClick={() => {setModal(false); setEditResponse(false);}}></button>
                </header>
                <section className="modal-card-body">
                  <>
                    <p>
                      Nome:
                      <input className={`input is-large`} value={modal.nome} onChange={(e) => changeValue("nome", e.target.value)} type="text" placeholder="Nome" autoFocus="" />
                    </p>
                    <p>
                      Cognome:
                      <input
                        className={`input is-large`}
                        value={modal.cognome}
                        onChange={(e) => changeValue("cognome", e.target.value)}
                        type="text"
                        placeholder="Cognome"
                        autoFocus=""
                      />
                    </p>
                    <p>
                      Email:
                      <input className={`input is-large`} value={modal.email} onChange={(e) => changeValue("email", e.target.value)} type="text" placeholder="Email" autoFocus="" />
                    </p>
                    <p>
                      Codice Tessera:
                      <input
                        className={`input is-large`}
                        value={modal.cod_tessera}
                        onChange={(e) => changeValue("cod_tessera", e.target.value)}
                        type="text"
                        placeholder="Codice Tessera"
                        autoFocus=""
                      />
                    </p>
                    <p>
                      Stato:
                      <div class="buttons has-addons">
                        <button onClick={(e) => changeValue("status", 'emitted')} class={`button ${modal.status === "emitted" && "is-success is-selected"}`}>emitted</button>
                        <button onClick={(e) => changeValue("status", 'payed')  } class={`button ${modal.status !== "emitted" && "is-warning is-selected"}`}>payed</button>
                      </div>
                    </p>
                    <p>
                      Data di nascita (aaaa-mm-gg es: 1988-06-27):
                      <input
                        className={`input is-large`}
                        value={modal.member_data_nascita}
                        onChange={(e) => changeValue("member_data_nascita", e.target.value)}
                        type="text"
                        placeholder="Data di Nascita"
                        autoFocus=""
                      />
                    </p>
                    <p>
                      Telefono:
                      <input
                        className={`input is-large`}
                        value={modal.telefono}
                        onChange={(e) => changeValue("telefono", e.target.value)}
                        type="text"
                        placeholder="Telefono"
                        autoFocus=""
                      />
                    </p>
                  </>

  

                </section>
                <footer className="modal-card-foot is-flex-direction-column pt-2">
                {editResponse !== false && <div class="full">{editResponse.map((err)=>{
                    return <p className="has-text-danger is-size-7 mb-0">{err}</p>
                  })}</div>}
                  <div className="is-full">
                    <button className={`button is-success ${isSaving && "is-loading"}`} onClick={handleEditRow}>
                      Salva
                    </button>
                    <button className="button" onClick={() => {setModal(false); setEditResponse(false)}}>
                      Annulla
                    </button>
                    <button className="button" onClick={confirmDeleteRow}>
                      Elimina
                    </button>
                    </div>
                  </footer>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <pre>{JSON.stringify(theResults, null, 2)}</pre> */}
    </div>
  );
};

export default CardsList;
