import React, { createContext, useReducer, useEffect } from "react";
import config from "./../config/config.json";

export const AppContext = createContext();

const contextVersion = config.appContext;

const AppContextProvider = (props) => {
  const defaultState = {
    token: false,
    email: false,
    language: "it"
  };
  const restartState = { rehydrated: true };

  const [context, dispatch] = useReducer(appReducer, defaultState);

  useEffect(() => {
    const rehydrateState = (async () => {
      const localData = await window.localStorage.getItem(contextVersion);
      const localState = await JSON.parse(localData);
      const mergedState = { ...localState, ...restartState };
      dispatch({ type: "REHYDRATE", mergedState }); //dispatch function will pass the action up to BookContext
    })();
  }, []);

  useEffect(() => {
    const setLocalData = (async () => {
      //console.log("context",context);
      try {
        await window.localStorage.setItem(contextVersion, JSON.stringify(context));
      } catch (e) {
        // saving error
        console.log(e);
      }
    })();
  }, [context]);
  return (
    <AppContext.Provider value={{ context, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

export const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return { ...state, token: action.token, email: action.email };
    case "CLEAR_TOKEN":
      return { ...state, token: false, email: false};
    case "SET_LANGUAGE":
      return { ...state, language: action.language };
    case "REHYDRATE":
      return { ...state, ...action.mergedState };
    default:
      return state;
  }
};
