import React from "react";

const Loader = ({isLoading, isRelative}) => {
  return (
    <div className={`loader-wrapper ${isLoading && "is-active"} ${isRelative && "relative"}`}>
      <div className="loader is-loading"></div>
    </div>
  );
};

export default Loader;
