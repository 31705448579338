const legal_card_fr = 
`
**Je soussigné(e) demande d’être inscrit à la Federazione Italiana Rafting comme “Touriste-Amateur” par l’intermédiaire de l’Inscrit {{affiliato}}.**

Je soussigné(e), par l’envoi de ce formulaire, déclare:
- 1. De ne pas être au courant de aucune pathologie qui pourrait représenter une contre-indication à l’activité pour laquelle je demande l’inscription;
- 2. D’accepter expressément toutes les normes du Statut et des Règlementations de la F.I.Raft et du CONI, ainsi que de s’obliger à les suivre et à les respecter sans aucune ecception;
- 3. dD’autoriser la Fédération et l’inscrit, auprès du quel je demande l’inscription, à l’utilisation des images et/ou à la réalisation d’enregistrements vidéo qui me voient portait pour des fins institutionnelles. Je déclare en outre que rien ne m’est dû, ni maintenent, ni dans l’avenir, pour l’utilisation dudit matériel photographique et /ou vidéo de la part de l’Inscrit, qui pourrait être utilisé pour l’élaboration de brochures, pour le site Internet, pour les affiches, pour les albums d’images, pour les calandriers, en déchargeant dès maintenant l’utilisateur de toute responsabilité;
- 4. De donner le consentement au traitement des données personnelles conformément à l’article 13 du GDPR, lu l’[**informative**](https://firaftbooking.it/terms/utenti-prenotazione) dans les modalités et pour les fins indiquées au point 1a, strictement liées et visantes à la gestion du dossier de l’inscription, qui permettent leur traitement dans la mesure nécessaire pour la réalisation des fins statutaires.;

Compte tenu et réaffirmé ce qui précède, par la signature de ce formulaire on souscrit ce qui est indiqué dans les points précédents, de 1 à 4, aux sens de l’art. 1341 et s.s. du c.c.
`;

const legal_card_underage_fr = 
`
**Je soussigné/é, en tant qu’exploitant la responsabilité parentale/tuteur, déclare** par l’envoi de la présente:
- 1. **de signer**, au nom et pour compte du mineur **la déclaration dans la demande d’adhésion et/ou dans le
consentement éclairé à l’activité mentionnée ci-dessus**;
- 2. de donner son consentement au traitement de ses données personnelles, aux termes de l’article 13 du GDPR, lue la
note d’[**information**](https://firaftbooking.it/terms/tutori) dans les modalités et pour les finalités indiquées au point 1.
Compte tenu et réaffirmé ce qui précède, par la signature de ce formulaire on souscrit ce qui est indiqué dans les points
précédents, de 1 à 2, aux sens de l’art. 1341 et s.s. du c.c.
`;

const legal_card_marketing_fr = 
`
Je donne, en outre, le consentement à la communication des données pour des finalités, indiquées au point 1b de l’informative, de marketing et de publicité à tiers avec lesquels la F.I.Raft ou le CONI ont des rapports contractules, et aux traitement des données dans la mesure nécessaire pour la réalisation des obligations prévues par la loi et les contrats.
`;

module.exports = {legal_card_fr, legal_card_underage_fr, legal_card_marketing_fr};
