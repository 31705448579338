import React, { useEffect, useState } from "react";
import "./App.css";
import "./App.scss";
import AppContextProvider from "./store/AppContext";
import Main from "./template/Main";
import Cookies from "js-cookie";
window.Cookies = Cookies;

const isPublic = true;
const cookieAuth = "beInternet";

const App = () => {
  async function grantAccess() {
    const password = await prompt("Applicazione in manutenzione, inserisci la password per accedere");
    if (password === cookieAuth) {
      window.Cookies.set(cookieAuth, "granted");
      window.location.reload();
    } else {
      window.Cookies.remove(cookieAuth);
    }
  }

  const cookieCheck = window.Cookies.get(cookieAuth);
  if (!isPublic && cookieCheck !== "granted") {
    return (
      <div className="hero is-fullheight is-warning">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              <img className="headLogo" src="/img/firaft-logo.png" alt="FIRAFT" width="128px" />
              <h2 className="title is-3">Stiamo aggiornando l'applicazione</h2>
              <button className="button is-danger" onClick={grantAccess} style={{ padding: "20px", fontSize: "18px", color: "#333" }}>
                Clicca per accedere se conosci la password
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <AppContextProvider>
      <Main />
    </AppContextProvider>
  );
};

export default App;
