import React, { useState, useEffect, useRef } from "react";
import { fetchEvents } from "./../../../model/booking";
//CALENDAR
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import itLocale from "@fullcalendar/core/locales/it";
import enLocale from "@fullcalendar/core/locales/en-gb";
import deLocale from "@fullcalendar/core/locales/de";
//CALENDAR
import dayjs from "dayjs";

import { useTranslation } from 'react-i18next';

const BookingCalendar = (props) => {

  const { i18n, t } = useTranslation();
  //const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState({from:false, to:false});

  // const [view, setView] = useState(7);
  // const calendarView = [
  //   "dayGridMonth",
  //   "dayGridWeek",
  //   "dayGridDay",
  //   "dayGrid",
  //   "timeGridWeek",
  //   "timeGridDay",
  //   "timeGrid",
  //   "listYear",
  //   "listMonth",
  //   "listWeek",
  //   "listDay",
  //   "list",
  // ];

  const calendarRef = useRef(null);

  const handleFetchEvents = async () => {
    let response = await fetchEvents(props.slug, props.service_id, dateRange.from, dateRange.to);
    //console.log(response);
    if (response.status === "OK") {
      let _events = [];
      let cutoffHours = Number.isInteger(props.reservationCutoffHours) ? props.reservationCutoffHours : 2;
      for(let i=0; i < response.data.events.length; i++){
        if (response.data.events[i].start > dayjs().add(cutoffHours,"hours").format("YYYY-MM-DD HH:MM")){
          _events.push(response.data.events[i]);
        }
        
      }
      setEvents(_events);
    } else {
      setEvents([]);
    }
  };

  useEffect(() => {
    //handleFetchEvents();
  }, [props.service_id, props.slug]);

  const _datesSet = (e)=>{
    const _startDate = dayjs(e.startStr).format("YYYY-MM-DD");
    const _endDate = dayjs(e.endStr).format("YYYY-MM-DD");

    if(_startDate !== dateRange.from || _endDate !== dateRange.to){
      setDateRange({from:_startDate, to:_endDate});
      
    }

  }

  useEffect(()=>{
    if (dateRange.from !== false && dateRange.to !== false)
    handleFetchEvents();
  }, [dateRange, props.service_id, props.slug])

  // useEffect(()=>{
  //   calendarRef.current.getApi().changeView(calendarView[view]);
  // }, [view])

  const handleEventClick = (e)=>{
    if (e.event._def.extendedProps.min_pax > e.event._def.extendedProps.max_total_pax){
      alert(t("Scegli un'altra data"));
      return;
    }
    props.openBookingForm(e.event._def.extendedProps);
  }

  const locale = i18n.language === "en" ? enLocale : i18n.language === "de" ? deLocale : itLocale;

  return (
    <div className="bookingCalendarComponent scrollTo">{dayjs().add(10,"hours").format("YYYY-MM-DD HH:MM")}

      <FullCalendar
        locale={locale}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={{
          left: "prev,next", //today
          center: "title",
          right: "_listWeek,_listDay _grid",
        }}
        customButtons={{
          //_listMonth: { text: 'Mese', click: ()=> { calendarRef.current.getApi().changeView("listMonth"); }},
          _listWeek: { text: t('Settimana'), click: ()=> { calendarRef.current.getApi().changeView("listWeek"); }},
          _listDay: { text: t('Giorno'), click: ()=> { calendarRef.current.getApi().changeView("listDay"); }},
          _grid: { text: t('Griglia'), click: ()=> { calendarRef.current.getApi().changeView("dayGridWeek"); }}, 
          //_grid: { text: 'Griglia', click: ()=> { calendarRef.current.getApi().changeView("timeGridWeek"); }}, 
        }}
        initialView={"listWeek"}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        height={600}
        eventClick={
          handleEventClick
        }
        events={events}
        ref={calendarRef}
        validRange={{
          start: dayjs().format("YYYY-MM-DD"),
          end: dayjs().add(1,"years").format("YYYY-MM-DD"),
        }}
        datesSet={_datesSet}
      />
    </div>
  );
};

export default BookingCalendar;

// visibleRange
// viewDidMount={()=>console.log("viewDidMount")}
// viewWillUnmount={()=>console.log("viewWillUnmount")}
// setView={()=>console.log("setView")}
// changeView={()=>console.log("changeView")}
// eventAdd={function(){}}
// eventChange={function(){}}
// eventRemove={function(){}} 
// eventsSet={(value) => {
// console.log("eventSet", value);
// }}
// eventContent={ (e)=>{}}
// select={(value) => {
//   console.log("select", value);
// }}
