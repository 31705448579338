import axios from "axios";
import config from "../config/config.json";
import _ from "lodash";

const base = process?.env?.NODE_ENV === "development" ? config.devApiBaseUrl : config.apiBaseUrl;

const fetchCardsApi = axios.create();
fetchCardsApi.interceptors.request.use(async (axiosConfig) => {
  axiosConfig.baseURL = base;
  return axiosConfig;
});


const fetchMember = async(args)=>{
  try{
    const response = await fetchCardsApi.get(`cards/${args.nome}/${args.cognome}/${args.uuid}/`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}


const fetchEmitCardBase = async(slug, navigation_slug)=>{
  try{
    const response = await fetchCardsApi.get(`cards/emitCardBase/${slug}/${navigation_slug}`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}

const emitCard = async(slug, navigation_slug, body)=>{
  try{
    const response = await fetchCardsApi.post(`cards/emitCard/${slug}/${navigation_slug}`,body);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}


export {fetchMember, fetchEmitCardBase, emitCard};