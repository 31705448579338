import React, { useState, useEffect } from "react";
import Error404 from "./../../template/Error404";
import Loader from "./../../template/Loader";
import { emitCard, fetchEmitCardBase } from "./../../model/cards";
import Select, { createFilter } from "react-select";
import { Select as SelectVirtualized } from "react-select-virtualized";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import dayjs from "dayjs";
import _ from "lodash";
import { getBirthState, getState, getMunicipality } from "./../../model/booking";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { legal_card_it, legal_card_underage_it, legal_card_marketing_it } from "../../legal/card_it";
import { legal_card_en, legal_card_underage_en, legal_card_marketing_en } from "../../legal/card_en";
import { legal_card_de, legal_card_underage_de, legal_card_marketing_de } from "../../legal/card_de";
import { legal_card_fr, legal_card_underage_fr, legal_card_marketing_fr } from "../../legal/card_fr";
import ReactMarkdown from "react-markdown";
import { legal_booking_confirmation_en } from "../../legal/booking_confirmation_en";
import { legal_booking_confirmation_de } from "../../legal/booking_confirmation_de";
import { legal_booking_confirmation_it } from "../../legal/booking_confirmation_it";
import { legal_booking_confirmation_fr } from "../../legal/booking_confirmation_fr";

registerLocale("it", it);

const Register = (props) => {
  const { t, i18n } = useTranslation();

  const [club, setClub] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formError, setFormError] = useState(false);
  const [exist, setExist] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [birthState, setBirthState] = useState([]);
  const [allState, setAllState] = useState([]);
  const [municipality, setMunicipality] = useState([]);
  const [emitted, setEmitted] = useState(false);

  useEffect(() => {
    if (props.match?.params?.slug && props.match?.params?.registration_slug) {
      handleGetEmitCardBase(props.match?.params?.slug, props.match?.params?.registration_slug);
    }
  }, [props.match?.params?.slug && props.match?.params?.registration_slug]);

  const handleGetEmitCardBase = async (slug, registration_slug) => {
    let response = await fetchEmitCardBase(slug, registration_slug);
    if (response.status === "OK") {
      setClub(response.data);
    } else {
      setClub(-1);
      setExist(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (exist) {
      handleGetDropdown();
    }
  }, [exist]);

  const handleGetDropdown = async () => {
    const bsResponse = await getBirthState();
    let bs = [];
    if (bsResponse?.status === "OK") {
      let data = bsResponse.data;
      for (let i = 0; i < data.length; i++) {
        bs.push({ label: data[i].denominazione, value: data[i].denominazione });
      }
    }
    setBirthState(bs);

    const sResponse = await getState();
    let s = [];
    if (sResponse?.status === "OK") {
      let data = sResponse.data;
      for (let i = 0; i < data.length; i++) {
        s.push({ label: data[i].denominazione, value: data[i].denominazione });
      }
    }
    setAllState(s);

    const mResponse = await getMunicipality();
    let m = [];
    if (mResponse?.status === "OK") {
      let data = mResponse.data;
      for (let i = 0; i < data.length; i++) {
        m.push({ label: data[i].denominazione, value: data[i].denominazione });
      }
    }

    // order by label
    m.sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

    setMunicipality(m);
  };

  const onChange = (e) => {
    console.log({ [e.target.name]: e.target.value });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    setFormError(false);

    console.log(formData);

    const response = await emitCard(props.match?.params?.slug, props.match?.params?.registration_slug, formData);
    setIsSending(false);

    if (response.status === "KO") {
      let errors = response.error;
      _.forEach(errors, function (value, key) {
        errors[key] = t(value);
        console.log(errors[key], "-", value);
      });
      setFormError(errors);
      window.scrollTo(0, 0);
      setIsSending(false);
    } else {
      setEmitted(response.data.success);
      setIsSending(false);
    }
  };
  if (!props.match.params.slug || !props.match.params.registration_slug) return <Error404 />;
  if (isLoading) return <Loader isLoading={isLoading} />;
  if (!exist) return <Error404 />;

  if (emitted !== false)
    return (
      <div className="hero is-fullheight is-success">
        <div className="hero-body">
          <div className="container">
            <div className="column is-12">
              <h2 className="subtitle is-3">{t(emitted)}</h2>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="column is-12">
            <h2 className="subtitle is-3">{t("Tesseramento e consenso informato all'attività")}</h2>
            <hr />

            {formError !== false && (
              <p className="has-text-danger pb-4">
                {t("Ci sono alcuni errori nei dati inseriti, gli errori sono evidenziati in rosso. Effettua le correzioni e procedi nuovamente con l'invio.")}
              </p>
            )}

            {formError?.generic && <p className="has-text-danger pb-4">{formError?.generic}</p>}

            {/* Email */}
            <div className="columns">
              <div className="column field">
                <label className="label">{t(`Email`)}</label>
                <div className="control">
                  <input
                    className={`input is-normal`}
                    type="email"
                    placeholder={t(`Email`)}
                    name={`email`}
                    value={formData[`email`] ? formData[`email`] : ""}
                    onChange={onChange}
                  />
                  {formError?.email && <p className="has-text-danger p-1">{formError?.email}</p>}
                </div>
              </div>
            </div>


            {/* Telefono */}
            <div className="columns">
              <div className="column field">
                <label className="label">{t(`Telefono`)}</label>
                <div className="control">
                  <input
                    className={`input is-normal`}
                    type="text"
                    placeholder={t(`Telefono`)}
                    name={`telefono`}
                    value={formData[`telefono`] ? formData[`telefono`] : ""}
                    onChange={onChange}
                  />
                  {formError?.telefono && <p className="has-text-danger p-1">{formError[`telefono`]}</p>}
                </div>
              </div>
            </div>    

            {/* Nome e Cognome */}
            <div className="columns">
              <div className="column field">
                <label className="label">{t(`Nome`)}</label>
                <div className="control">
                  <input
                    className={`input is-normal`}
                    type="text"
                    placeholder={t(`Nome`)}
                    name={`nome`}
                    value={formData[`nome`] ? formData[`nome`] : ""}
                    onChange={onChange}
                  />
                  {formError?.nome && <p className="has-text-danger p-1">{formError?.nome}</p>}
                </div>
              </div>
              <div className="column field">
                <label className="label">{t(`Cognome`)}</label>
                <div className="control">
                  <input
                    className={`input is-normal`}
                    type="text"
                    placeholder={t(`Cognome`)}
                    name={`cognome`}
                    value={formData[`cognome`] ? formData[`cognome`] : ""}
                    onChange={onChange}
                  />
                  {formError?.cognome && <p className="has-text-danger p-1">{formError?.cognome}</p>}
                </div>
              </div>
            </div>

            {/* Data di nascita e Nazionalità */}
            <div className="columns">
              <div className="column field">
                <label className="label">{t(`Data di nascita`)}</label>
                <div className="control">
                  <DatePicker
                    showTimeSelect={false}
                    selected={formData[`data_nascita`] ? new Date(formData[`data_nascita`]) : ""}
                    onChange={(data) => onChange({ target: { name: `data_nascita`, value: data } })}
                    className="input is-normal"
                    // maxDate={ new Date( dayjs().subtract(16, "years").format("YYYY-MM-DD"))}
                    dateFormat={"dd/MM/yyyy"}
                    showYearDropdown
                    showMonthDropdown
                    peekNextMonth
                    locale={it}
                    dropdownMode="select"
                    placeholderText=" GG/MM/AAAA"
                    maxDate={new Date()}
                  />
                  {formError?.data_nascita && <p className="has-text-danger p-1">{formError?.data_nascita}</p>}
                </div>
              </div>
              <div className="column field">
                <div className="control">
                  <p className={`has-text-weight-bold`}>{t(`Nazionalità`)}</p>
                  <label className="radio pt-3">
                    <input
                      type="radio"
                      onChange={onChange}
                      name={`straniero`}
                      value={0}
                      checked={formData.hasOwnProperty("straniero") && formData[`straniero`].toString() === "0" ? true : false}
                    />{" "}
                    {t(`Italia`)}
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      onChange={onChange}
                      name={`straniero`}
                      value={1}
                      checked={formData.hasOwnProperty("straniero") && formData[`straniero`].toString() === "1" ? true : false}
                    />{" "}
                    {t(`Straniero`)}
                  </label>
                  {formError?.straniero && <p className="has-text-danger p-1">{formError?.straniero}</p>}
                </div>
              </div>
            </div>

            {/* Stato di nascita e sesso e CF */}
            <div className="columns">
              <div className="column field">
                <label className="label">{t(`Stato di Nascita`)}</label>
                {(formData[`straniero`] === "1" || formData[`straniero`] === 1) || true ? (
                  <Select
                    defaultValue={""}
                    name={`stato_nascita`}
                    options={birthState}
                    isClearable={true}
                    isSearchable={true}
                    className={`basic-multi-select`}
                    classNamePrefix="select"
                    onChange={(data) => onChange({ target: { name: `stato_nascita`, value: data !== null ? data.value : "" } })}
                    placeholder={t(`Stato di Nascita`)}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                ) : (
                  <input
                    className={`input is-normal is-static`}
                    type="text"
                    placeholder={t(`Stato di Nascita`)}
                    name={`stato_nascita_static`}
                    value={"ITALIA"}
                    onChange={onChange}
                  />
                )}
                {formError?.stato_nascita && <p className="has-text-danger p-1">{formError?.stato_nascita}</p>}
              </div>
              <div className="column field">
                <div className="control">
                  <p className={`has-text-weight-bold`}>{t(`Sesso`)}</p>
                  <label className="radio pt-3">
                    <input type="radio" onChange={onChange} name={`sesso`} value={"F"} checked={formData[`sesso`] === "F" ? true : false} /> {t("F")}
                  </label>
                  <label className="radio">
                    <input type="radio" onChange={onChange} name={`sesso`} value={"M"} checked={formData[`sesso`] === "M" ? true : false} /> {t("M")}
                  </label>
                  {formError?.sesso && <p className="has-text-danger p-1">{formError?.sesso}</p>}
                </div>
              </div>

              {(formData[`straniero`] === "0" || formData[`straniero`] === 0) && (

              <div className="column field">
                <label className="label">{t(`Codice Fiscale`)}</label>
                <div className="control">
                  <input
                    className={`input is-normal`}
                    type="text"
                    placeholder={t(`Codice Fiscale`)}
                    name={`codice_fiscale`}
                    value={formData[`codice_fiscale`] ? formData[`codice_fiscale`] : ""}
                    onChange={onChange}
                  />
                  {formError?.codice_fiscale && <p className="has-text-danger p-1">{formError?.codice_fiscale}</p>}
                </div>
              </div>

              )}

            </div>

            {/* Stato di Residenza */}
            
                  <div className="columns">
                    <div className="column field">
                      <label className="label">{t(`Stato di Residenza`)}</label>
                      {formData[`straniero`] === "1" || formData[`straniero`] === 1 ? (
                      <Select
                        defaultValue={""}
                        name={`stato_residenza`}
                        options={allState}
                        isClearable={true}
                        isSearchable={true}
                        className={`basic-multi-select`}
                        classNamePrefix="select"
                        onChange={(data) => onChange({ target: { name: `stato_residenza`, value: data !== null ? data.value : "" } })}
                        placeholder={t(`Stato di Residenza`)}
                      />) : (
                        <input
                          className={`input is-normal is-static`}
                          type="text"
                          placeholder={t(`Stato di Nascita`)}
                          name={`stato_residenza_static`}
                          value={"Italia"}
                          onChange={onChange}
                        />
                      )}
                      {formError?.stato_residenza && <p className="has-text-danger p-1">{formError?.stato_residenza}</p>}
                    </div>
                  </div>
                


            { //formData[`stato_residenza`] === "Italia" && (
              (formData[`straniero`] === "0" || formData[`straniero`] === 0) && (
              <>
                {/* Indirizzo e Comune */}
                <div className="columns">
                  <div className="column field">
                    <label className="label">{t(`Indirizzo`)}</label>
                    <div className="control">
                      <input
                        className={`input is-normal`}
                        type="text"
                        placeholder={t(`Indirizzo`)}
                        name={`indirizzo`}
                        value={formData[`indirizzo`] ? formData[`indirizzo`] : ""}
                        onChange={onChange}
                      />
                      {formError?.indirizzo && <p className="has-text-danger p-1">{formError?.indirizzo}</p>}
                    </div>
                  </div>
                  <div className="column field">
                    <label className="label">{t(`Comune`)}</label>
                    <div className="control">
                      <SelectVirtualized
                        defaultValue={""}
                        name={`stato_residenza`}
                        options={municipality}
                        isClearable={true}
                        isSearchable={true}
                        className={`basic-multi-select`}
                        classNamePrefix="select"
                        onChange={(data) => onChange({ target: { name: `comune`, value: data !== null ? data.value : "" } })}
                        placeholder={t("Comune di residenza")}
                      />
                      {formError?.comune && <p className="has-text-danger p-1">{formError?.comune}</p>}
                    </div>
                  </div>

                  <div className="column field">
                    <label className="label">{t(`CAP`)}</label>
                    <div className="control">
                      <input
                        className={`input is-normal`}
                        type="text"
                        placeholder={t(`CAP`)}
                        name={`cap`}
                        value={formData[`cap`] ? formData[`cap`] : ""}
                        onChange={onChange}
                      />
                      {formError?.cap && <p className="has-text-danger p-1">{formError?.cap}</p>}
                    </div>
                  </div>
                </div>
              </>
            )}

            <hr />

            <div className="columns terms">
              <div className="column is-size-7">
                <p className="has-text-centered	is-uppercase is-size-7 has-text-weight-bold	pb-2">{i18n.t("Richiesta di tesseramento")}</p>
                <ReactMarkdown className="has-markdown" linkTarget="_blank">
                  {i18n.language === "en"
                    ? legal_card_en.replace("{{affiliato}}", club?.display_name)
                    : i18n.language === "de"
                    ? legal_card_de.replace("{{affiliato}}", club?.display_name)
                    : i18n.language === "fr"
                    ? legal_card_fr.replace("{{affiliato}}", club?.display_name)
                    : legal_card_it.replace("{{affiliato}}", club?.display_name)}
                </ReactMarkdown>
                <div className="control">
                  <label className="radio pt-3">
                    <input
                      type="radio"
                      onChange={onChange}
                      name={`privacy_istituzionale`}
                      value={1}
                      checked={formData[`privacy_istituzionale`] === "1" || formData[`privacy_istituzionale`] === 1 ? true : false}
                    />{" "}
                    <b>{t("Acconsento")}</b>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      onChange={onChange}
                      name={`privacy_istituzionale`}
                      value={0}
                      checked={formData[`privacy_istituzionale`] === "0" || formData[`privacy_istituzionale`] === 0 ? true : false}
                    />{" "}
                    <b>{t("Nego il consenso")}</b>
                  </label>
                  {formError?.privacy_istituzionale && <p className="has-text-danger p-1">{formError?.privacy_istituzionale}</p>}
                </div>
                <br />
                <div className="is-size-7">
                  <ReactMarkdown className="has-markdown" linkTarget="_blank">
                    {i18n.language === "en" ? legal_card_marketing_en 
                    : i18n.language === "de" ? legal_card_marketing_de 
                    : i18n.language === "fr" ? legal_card_marketing_fr 
                    : legal_card_marketing_it}
                  </ReactMarkdown>
                </div>
                <div className="control">
                  <label className="radio pt-3">
                    <input
                      type="radio"
                      onChange={onChange}
                      name={`privacy_commerciale`}
                      value={1}
                      checked={formData?.privacy_commerciale === "1" || formData === 1 ? true : false}
                    />{" "}
                    <b>{t("Acconsento")}</b>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      onChange={onChange}
                      name={`privacy_commerciale`}
                      value={0}
                      checked={formData[`privacy_commerciale`] === "0" || formData[`privacy_commerciale`] === 0 ? true : false}
                    />{" "}
                    <b>{t("Nego il consenso")}</b>
                  </label>
                  {formError?.privacy_commerciale && <p className="has-text-danger p-1">{formError?.privacy_commerciale}</p>}
                </div>
                <br />
                <div className="is-size-7">
                <p className="has-text-centered	is-uppercase is-size-7 has-text-weight-bold	pb-2">{i18n.t("Consenso informato all'attività")}</p>
                  <ReactMarkdown className="has-markdown" linkTarget="_blank">
                    {i18n.language === "en" ? legal_booking_confirmation_en : i18n.language === "de" ? legal_booking_confirmation_de : i18n.language === "fr" ? legal_booking_confirmation_fr : legal_booking_confirmation_it}
                  </ReactMarkdown>
                </div>
                <div className="control">
                  <label className="radio pt-3">
                    <input
                      type="radio"
                      onChange={onChange}
                      name={`consenso_informato`}
                      value={1}
                      checked={formData[`consenso_informato`] === "1" || formData[`consenso_informato`] === 1 ? true : false}
                    />{" "}
                    <b>{t("Acconsento")}</b>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      onChange={onChange}
                      name={`consenso_informato`}
                      value={0}
                      checked={formData[`consenso_informato`] === "0" || formData[`consenso_informato`] === 0 ? true : false}
                    />{" "}
                    <b>{t("Nego il consenso")}</b>
                  </label>
                  {formError?.consenso_informato && <p className="has-text-danger p-1">{formError?.consenso_informato}</p>}
                </div>                
              </div>
            </div>

            {/* MINORE */}
            {dayjs().diff(dayjs(formData[`data_nascita`]), "year") < 18 && dayjs().diff(dayjs(formData[`data_nascita`]), "day") > 0 && (
              <>
                <hr />
                <p className={"title is-6 mb-2"}>{t("Dati dell’esercente responsabilità genitoriale/tutoria")}</p>
                <p className="is-size-7 pb-4">*{i18n.t("se minore, indicare email dell'esercente la responsabilità genitoriale/tutoria")}</p>
                <div className="columns">
                  <div className="column field">
                    <label className="label">{t(`Nome`)}</label>
                    <div className="control">
                      <input
                        className={`input is-normal`}
                        type="text"
                        placeholder={t(`Nome`)}
                        name={`responsabile_minore_nome`}
                        value={formData[`responsabile_minore_nome`] ? formData[`responsabile_minore_nome`] : ""}
                        onChange={onChange}
                      />
                      {formError?.responsabile_minore_nome && <p className="has-text-danger p-1">{formError?.responsabile_minore_nome}</p>}
                    </div>
                  </div>
                  <div className="column field">
                    <label className="label">{t(`Cognome`)}</label>
                    <div className="control">
                      <input
                        className={`input is-normal`}
                        type="text"
                        placeholder={t(`Cognome`)}
                        name={`responsabile_minore_cognome`}
                        value={formData[`responsabile_minore_cognome`] ? formData[`responsabile_minore_cognome`] : ""}
                        onChange={onChange}
                      />
                      {formError?.responsabile_minore_cognome && <p className="has-text-danger p-1">{formError?.responsabile_minore_cognome}</p>}
                    </div>
                  </div>
                </div>
                <div className="columns terms">
                  <div className="column is-size-7">
                    <ReactMarkdown className="has-markdown" linkTarget="_blank">
                      {i18n.language === "en"
                        ? legal_card_underage_en.replace("{{minore}}", `${formData?.nome} ${formData?.cognome}`).replace("{{affiliato}}", club?.display_name)
                        : i18n.language === "de"
                        ? legal_card_underage_de.replace("{{minore}}", `${formData?.nome} ${formData?.cognome}`).replace("{{affiliato}}", club?.display_name)
                        : i18n.language === "fr"
                        ? legal_card_underage_fr.replace("{{minore}}", `${formData?.nome} ${formData?.cognome}`).replace("{{affiliato}}", club?.display_name)
                        : legal_card_underage_it.replace("{{minore}}", `${formData?.nome} ${formData?.cognome}`).replace("{{affiliato}}", club?.display_name)}
                    </ReactMarkdown>

                    <br />
                    <div className="control">
                      <label className="radio pt-3">
                        <input
                          type="radio"
                          onChange={onChange}
                          name={`responsabile_minore_termini`}
                          value={1}
                          checked={formData[`responsabile_minore_termini`] === "1" || formData[`responsabile_minore_termini`] === 1 ? true : false}
                        />{" "}
                        <b>{t("Acconsento")}</b>
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          onChange={onChange}
                          name={`responsabile_minore_termini`}
                          value={0}
                          checked={formData[`responsabile_minore_termini`] === "0" || formData[`responsabile_minore_termini`] === 0 ? true : false}
                        />{" "}
                        <b>{t("Nego il consenso")}</b>
                      </label>
                      {formError?.responsabile_minore_termini && <p className="has-text-danger p-1">{formError?.responsabile_minore_termini}</p>}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="buttons is-centered">
              <button
                className={`button is-centered is-primary block is-medium is-link ${isSending && "is-loading"}`}
                disabled={isSending}
                type="submit"
                onClick={onSubmit}
              >
                {t("Invia")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(club)}</pre> */}
    </div>
  );
};

export default Register;
