import React, { useEffect, useState, useCallback } from "react";
import Loader from "./../../template/Loader";
import { getBaseConfig, editBaseConfig, fetchAdminApi, uploadLogo } from "./../../model/admin";
import { Link } from "react-router-dom";
import { TwitterPicker } from "react-color";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import QRCode from "qrcode.react";

const Dashboard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [baseConfig, setBaseConfig] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [modal, setModal] = useState(false);
  const [newValue, setNewValue] = useState(false);
  const [editResponse, setEditResponse] = useState(false);
  const [stripe, setStripe] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [logoUpload, setLogoUpload] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(false);
  const [reservationCutoffHours, setReservationCutoffHours] = useState(2);
  const [cancellationCutoffHours, setCancellationCutoffHours] = useState(48);

  const baseUrl = window.location.origin;

  useEffect(() => {
    handleGetBaseConfig();
    getStripe();
  }, []);

  const handleGetBaseConfig = async () => {
    setIsLoading(true);
    try {
      const data = await getBaseConfig();
      setBaseConfig(data);
      if (data.reservation_cutoff_hours !== null) {
        setReservationCutoffHours(parseInt(data.reservation_cutoff_hours));
      }
      if (data.cancellation_cutoff_hours !== null) {
        setCancellationCutoffHours(parseInt(data.cancellation_cutoff_hours));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadQR = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id+".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const toTab = (e, i) => {
    e.preventDefault();
    setActiveTab(i);
  };

  const openEditModal = ({ field, name, defaultValue }) => {
    setNewValue(defaultValue);
    setModal({ field, name, defaultValue });
  };

  const closeEditModal = () => {
    setNewValue("");
    setModal(false);
    setEditResponse(false);
  };

  const changeNewValue = (e) => {
    setNewValue(e.target.value);
  };

  const handleEditBaseConfig = async () => {
    setIsSaving(true);
    try {
      const data = await editBaseConfig(modal.field, newValue);
      if (data.status === "OK") {
        setBaseConfig(data.baseConfig);
        setEditResponse({ success: data.message });
      } else {
        setEditResponse({ error: data.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSliderEditBaseConfig = async (field, value) => {
    setIsSaving(true);
    try {
      const data = await editBaseConfig(field, value);
      console.log(data.status);
      if (data.status === "OK") {
        setBaseConfig(data.baseConfig);
        setEditResponse({ success: data.message });
      } else {
        setEditResponse({ error: data.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handleLogoChange = (e) => setLogoUpload({ file: e.target.files[0] });

  const handleUploadLogo = async () => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append("logo", logoUpload.file);
    try {
      const data = await uploadLogo(formData);
      if (data.status === "OK") {
        setBaseConfig(data.baseConfig);
      } else {
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (logoUpload !== false) {
      handleUploadLogo();
    }
  }, [logoUpload]);

  const getStripe = async () => {
    console.log("getStripe");
    const response = await fetchAdminApi.get("/payments");
    setStripe(response.data);
  };

  const stripeAction = async (e) => {
    e.preventDefault();
    if (stripe?.stripe_account_status === "completed") {
      connectStripe();
    } else if (stripe?.stripe_account_status === "on_hold") {
      connectStripe();
    } else if (!stripe) {
      connectStripe();
    }
  };

  const connectStripe = async () => {
    setStripeLoading(true);
    const response = await fetchAdminApi.post("/payments");
    if (response?.data?.type === "login_link") {
      window.open(response.data.redirectUrl);
    } else {
      window.location.href = response.data.redirectUrl;
    }
    setStripeLoading(false);
  };

  const deleteStripeConfirm = async () => {
    if (window.confirm("Sei sicuro?")) {
      deleteStripe();
    }
  };

  const deleteStripe = async () => {
    setStripeLoading(true);
    const response = await fetchAdminApi.delete("/payments");
    setStripeLoading(false);
    getStripe();
  };

  useEffect(() => {
    if (baseConfig?.primary_color !== null) {
      setPrimaryColor(baseConfig?.primary_color);
    }
  }, [baseConfig]);

  const handleColorChange = (color) => {
    setPrimaryColor(color.hex);
  };

  const confirmColor = async () => {
    setIsSaving(true);
    try {
      const data = await editBaseConfig("primary_color", primaryColor);
      if (data.status === "OK") {
        setBaseConfig(data.baseConfig);
        setEditResponse({ success: data.message });
      } else {
        setEditResponse({ error: data.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <div className="has-background-warning p-3 resources">
      <div className="container pt-3 pb-3">
        <section className="section box">
          <div className={"is-flex is-flex-direction-row is-align-items-center"}>
            {baseConfig?.logoUrl !== null && (
              <>
                <img src={baseConfig?.logoUrl} style={{ width: 32, marginRight: 10 }} alt="logo" />{" "}
              </>
            )}
            <h2 className="title is-2">Dashboard</h2>
          </div>
          {baseConfig?.primary_color !== null ? <hr style={{ backgroundColor: baseConfig.primary_color }} /> : <hr />}
          <div className="tabs is-cented">
            <ul>
              <li className={` ${activeTab === 1 && "is-active"}`}>
                <a href="/" onClick={(e) => toTab(e, 1)}>
                  <span className="icon is-small">
                    <i className="fas fa-info-circle" aria-hidden="true"></i>
                  </span>
                  <span>Affiliato</span>
                </a>
              </li>
              <li className={` ${activeTab === 2 && "is-active"}`}>
                <a href="/" onClick={(e) => toTab(e, 2)}>
                  <span className="icon is-small">
                    <i className="fas fa-cog" aria-hidden="true"></i>
                  </span>
                  <span>Impostazioni</span>
                </a>
              </li>
            </ul>
          </div>

          <div className={` ${activeTab !== 1 && "is-hidden"}`}>
            <div className="table-container">
              <table className="table is-bordered is-striped is-hoverable is-fullwidth">
                <tbody>
                  <tr>
                    <td>Nome</td>
                    <th>{baseConfig?.name}</th>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <th>{baseConfig?.email}</th>
                  </tr>
                  <tr>
                    <td>Codice Affiliato</td>
                    <th>{baseConfig?.affiliation_code}</th>
                  </tr>
                  <tr>
                    <td>Codice Fiscale</td>
                    <th>{baseConfig?.CF}</th>
                  </tr>
                  <tr>
                    <td>Link prenotazioni</td>
                    <th>
                      <a href={`${baseUrl}/book/${baseConfig?.slug}`} target="_blank" rel="noreferrer">
                        <span className="icon-text">
                          <span className="icon">
                            <i className="fas fa-home"></i>
                          </span>
                          {`${baseUrl}/book/${baseConfig?.slug}`}
                        </span>
                      </a>
                    </th>
                  </tr>
                  <tr>
                    <td>Api Token</td>
                    <th><code>{baseConfig?.api_token}</code></th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={` ${activeTab !== 2 && "is-hidden"}`}>
            <div className="table-container">
              <table className="table is-bordered is-striped is-hoverable is-fullwidth">
                <tbody>
                  <tr>
                    <td>Logo</td>
                    <th>{baseConfig?.logoUrl !== null && <img src={baseConfig?.logoUrl} style={{ width: 128 }} alt="logo" />}</th>
                    <td className="is-narrow">
                      <label htmlFor="logo">
                        <input type="file" name="logo" id="logo" accept=".jpg, .jpeg, .png" onChange={handleLogoChange} style={{ display: "none" }} />
                        <div className="button is-inverted is-primary">
                          <i className="fas fa-camera" aria-hidden="true"></i>
                        </div>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Colore</td>
                    <th>
                      <TwitterPicker
                        width="100%"
                        triangle="hide"
                        colors={["#FF6900", "#FCB900", "#7BDCB5", "#00D084", "#8ED1FC", "#0693E3", "#ABB8C3", "#EB144C", "#F78DA7", "#9900EF"]}
                        onChangeComplete={handleColorChange}
                        color={primaryColor}
                      />
                    </th>
                    <td className="is-narrow">
                      <button className="button is-inverted is-primary" onClick={confirmColor}>
                        <i className="fas fa-save" style={baseConfig?.primaryColor !== null && { color: primaryColor }} aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Nome visualizzato</td>
                    <th>{baseConfig?.display_name}</th>
                    <td className="is-narrow">
                      <button
                        className="button is-inverted is-primary"
                        onClick={() => openEditModal({ field: "display_name", name: "Nome visualizzato", defaultValue: baseConfig?.display_name })}
                      >
                        <i className="fas fa-pen" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Testo introduttivo</td>
                    <th>{baseConfig?.info_text}</th>
                    <td className="is-narrow">
                      <button
                        className="button is-inverted is-primary"
                        onClick={() => openEditModal({ field: "info_text", name: "Testo introduttivo", defaultValue: baseConfig?.info_text })}
                      >
                        <i className="fas fa-pen" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Link prenotazioni</td>
                    <th>
                      <a href={`${baseUrl}/book/${baseConfig?.slug}`} target="_blank" rel="noreferrer">
                        <span className="icon-text">
                          <span className="icon">
                            <i className="fas fa-home"></i>
                          </span>
                          {`${baseUrl}/book/${baseConfig?.slug}`}
                        </span>
                      </a>
                      <div className="sep"></div>
                        <br/>
                      <QRCode size={256} value={`${baseUrl}/book/${baseConfig?.slug}`} id="qr_book" onClick={()=>downloadQR("qr_book")} />
                    </th>
                    <td className="is-narrow">
                      <button
                        className="button is-inverted is-primary"
                        onClick={() => openEditModal({ field: "slug", name: "Link", defaultValue: baseConfig?.slug })}
                      >
                        <i className="fas fa-pen" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Link modulo tesseramento e consenso informato<br />
                      <small className="tableMoreInfo has-text-danger">Pagamento a carico dell'affiliato</small>
                    </td>
                    <th>
                      {baseConfig?.registration_slug !== null ? (
                        <>
                        <a href={`${baseUrl}/register/${baseConfig?.slug}/${baseConfig?.registration_slug}`} target="_blank" rel="noreferrer">
                          <span className="icon-text">
                            <span className="icon">
                              <i className="fas fa-home"></i>
                            </span>
                            {`${baseUrl}/register/${baseConfig?.slug}/${baseConfig?.registration_slug}`}
                          </span>
                        </a>                        
                        <div className="sep"></div>
                        <br/>
                        <QRCode size={256} value={`${baseUrl}/register/${baseConfig?.slug}/${baseConfig?.registration_slug}`} id="qr_register" onClick={()=>downloadQR("qr_register")} />

                        </>
                      ) : (
                        "Imposta un link"
                      )}
                    </th>
                    <td className="is-narrow">
                      <button
                        className="button is-inverted is-primary"
                        onClick={() => openEditModal({ field: "registration_slug", name: "Link", defaultValue: baseConfig?.registration_slug })}
                      >
                        <i className="fas fa-pen" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Chiusura prenotazioni
                      <br />
                      <small className="tableMoreInfo">prima della data di inizio evento</small>
                    </td>
                    <th>
                      <div style={{ padding: 15 }}>
                        <InputRange
                          minValue={1}
                          maxValue={96}
                          value={reservationCutoffHours}
                          onChange={(value) => setReservationCutoffHours(value)}
                          onChangeComplete={() => handleSliderEditBaseConfig("reservation_cutoff_hours", reservationCutoffHours)}
                          formatLabel={(value) => `${Math.floor(value / 24)}d ${value % 24}h`}
                          disabled={isLoading}
                        />
                      </div>
                    </th>
                    <td className="is-narrow"></td>
                  </tr>

                  <tr>
                    <td>
                      Cancellazione prenotazioni
                      <br />
                      <small className="tableMoreInfo">ore prima dell'evento, oltre non è garantito il rimborso</small>
                    </td>
                    <th>
                      <div style={{ padding: 15 }}>
                        <InputRange
                          minValue={1}
                          maxValue={720}
                          value={cancellationCutoffHours}
                          onChange={(value) => setCancellationCutoffHours(value)}
                          onChangeComplete={() => handleSliderEditBaseConfig("cancellation_cutoff_hours", cancellationCutoffHours)}
                          formatLabel={(value) => `${Math.floor(value / 24)}d ${value % 24}h`}
                          disabled={isLoading}
                        />
                      </div>
                    </th>
                    <td className="is-narrow"></td>
                  </tr>

                  <tr>
                    <td>
                      Pagamenti <i className="fab fa-stripe has-text-danger-dark"></i>
                    </td>
                    <th>
                      <button onClick={stripeAction} className={`button is-outlined is-link ${stripeLoading && "is-loading"}`} disabled={stripeLoading}>
                        {stripe?.stripe_account_status === "completed"
                          ? "Configurazione completata, gestisci account"
                          : stripe?.stripe_account_status === "on_hold"
                          ? "Clicca per configurare"
                          : "Clicca per collegare"}
                      </button>
                    </th>
                    <td className="is-narrow">
                      {stripe !== false && (
                        <button className="button is-inverted is-danger" onClick={deleteStripeConfirm}>
                          <i className="fas fa-trash" aria-hidden="true"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="section box">
          <h4 className="title is-4">Gestione attività e prenotazioni </h4>
          <div className="columns">
            <div className="column">
              <div className="block">
                <Link to="/admin/resources">
                  <span>Gestisci le tue attività </span>
                  <span className="icon is-small">
                    <i className="fas fa-arrow-right" aria-hidden="true"></i>
                  </span>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="block">
                <Link to="/admin/bookings">
                  <span>Le tue prenotazioni </span>
                  <span className="icon is-small">
                    <i className="fas fa-arrow-right" aria-hidden="true"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      {modal && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Modifica <b>{modal.name}</b>
              </p>
              <button className="delete" aria-label="close" onClick={() => setModal(false)}></button>
            </header>
            <section className="modal-card-body">
              {editResponse === false ? (
                <>
                  <p>
                    Inserisci il nuovo valore per <b>{modal.name}</b> clicca su Salva
                  </p>
                  {modal.field === "info_text" ? (
                    <textarea className={`textarea is-medium`} defaultValue={newValue} onChange={changeNewValue} placeholder="Valore" autoFocus="" rows={3} />
                  ) : (
                    <input className={`input is-large`} value={newValue} onChange={changeNewValue} type="text" placeholder="Valore" autoFocus="" />
                  )}
                </>
              ) : (
                <p className={`is-size-6 ${editResponse?.success ? "has-text-success" : "has-text-danger"}`}>
                  {editResponse?.success ? editResponse?.success : editResponse?.error}
                </p>
              )}
            </section>
            {editResponse === false ? (
              <footer className="modal-card-foot">
                <button className={`button is-success ${isSaving && "is-loading"}`} onClick={handleEditBaseConfig}>
                  Salva
                </button>
                <button className="button" onClick={() => setModal(false)}>
                  Annulla
                </button>
              </footer>
            ) : (
              <footer className="modal-card-foot">
                <button className={`button ${editResponse?.success ? "is-success" : "is-danger"}`} onClick={closeEditModal}>
                  OK
                </button>
              </footer>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
