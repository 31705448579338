import React from "react";
import {Link} from "react-router-dom"
import { useTranslation } from 'react-i18next';

const ErrorForm = (props) => {
  const { t } = useTranslation();

  return (
    <div className="hero is-fullheight is-warning">
      <div className="hero-body">
      <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              <img className="headLogo" src="/img/firaft-logo.png" alt="FIRAFT" />
              <h4 className="title is-4">
                <p>{ t("link_scaduto_1")}</p><br/>
                <p>{ t("link_scaduto_2")}</p><br/>
                <p>{ t("link_scaduto_3")}</p>
              </h4>
              { props.mode === "admin" &&
                <Link className="button is-danger is-light is-medium is-centered" to={"/admin/dashboard"}>Torna alla dashboard</Link>              
              }
            </div>
          </div>
      </div>
    </div>
  );
};

export default ErrorForm;
