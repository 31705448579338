import React, { useEffect, useState } from "react";
import Breadcumb from "./components/Breadcumb";
import Loader from "./../../template/Loader";
import Forbidden from "./../../template/Forbidden";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { getServices, addService, editService, deleteService } from "./../../model/admin";
import { Link } from "react-router-dom";

const Resources = (props) => {
  const [resource, setResource] = useState(false);
  const [services, setServices] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newDuration, setNewDuration] = useState(60);
  const [newRange, setNewRange] = useState({ min: 1, max: 2 });
  const [newAgeRange, setNewAgeRange] = useState({ min: 0, max: 100 });
  const [newMaxTotal, setNewMaxTotal] = useState(20);
  const [newPrice, setNewPrice] = useState(0);
  const [isForbidden, setIsForbidden] = useState(false);

  useEffect(() => {
    handleGetServices();
  }, []);

  const handleGetServices = async () => {
    setIsLoading(true);
    try {
      const data = await getServices(props.match.params.resource_id);
      console.log(data);
      if (data?.status === "FORBIDDEN") {
        setIsForbidden(true);
      }
      setResource(data.resource);
      setServices(data.services);
    } catch (e) {
      setResource([]);
      setServices([]);
    } finally {
      setIsLoading(false);
    }
  };

  const changeNewName = (e) => {
    setNewName(e.target.value);
  };

  const changeNewDescription = (e) => {
    setNewDescription(e.target.value);
  };

  const changeNewDuration = (value) => {
    setNewDuration(value);
  };

  const changeNewRange = (value) => {
    setNewRange(value);
  };

  const changeNewAgeRange = (value) => {
    setNewAgeRange(value);
  };

  const changeNewMaxTotal = (e) => {
    setNewMaxTotal(e.target.value);
  };

  const changeNewPrice = (e) => {
    setNewPrice(e.target.value);
  };

  const openAddModal = (e) => {
    e.preventDefault();
    setNewName("");
    setNewDescription("");
    setNewDuration(60);
    setNewRange({ min: 1, max: 2 });
    setModal({ type: "add" });
  };

  const reOpenAddModal = (e) => {
    e.preventDefault();
    setModal({ type: "add" });
  };

  const openEditModal = (e, args) => {
    console.log(args);
    e.preventDefault();
    if (args?.name && args?.description && args?.min_pax && args?.max_pax && args?.duration ) {
      setNewName(args.name);
      setNewDescription(args.description);
      setNewDuration(args.duration);
      setNewRange({ min: args.min_pax, max: args.max_pax });
      setNewAgeRange({ min: args.min_age, max: args.max_age });
      setNewMaxTotal(args?.max_total_pax);
      setNewPrice(args?.pax_price);

    }
    setModal({ type: "edit", ...args });
  };

  const handleAddService = async (e) => {
    e.preventDefault();
    try {
      const data = await addService(newName, newDescription, newDuration, newRange.min, newRange.max, newAgeRange.min, newAgeRange.max, newMaxTotal, newPrice, props.match.params.resource_id);
      if (data.status === "OK") {
        handleGetServices();
        setNewName("");
        setNewDescription("");
        setNewDuration(60);
        setNewRange({ min: 1, max: 2 });
        setModal({ type: "notification", subtype: "success", message: data.message, action: "closeModal" });
      } else {
        setModal({ type: "notification", subtype: "error", message: data.message, action: "reOpenAddModal" });
      }
    } catch (e) {
      console.log("resources.js handleAddService", e);
    }
  };

  const deleteConfirm = (e, args) => {
    e.preventDefault();
    if (modal === false) {
      setModal(args);
    } else {
      setModal(false);
    }
  };

  const handleDeleteService = async (id) => {
    try {
      const data = await deleteService(id);
      if (data.status === "OK") {
        setModal({ type: "notification", message: data.message });
        handleGetServices();
      } else {
        setModal({ type: "notification", message: data.message });
        //return data;
      }
    } catch (e) {
      console.log("Resources.js handleDeleteResource", e);
    }
  };

  const handleEditService = async () => {
    try {
      const data = await editService(newName, newDescription, newDuration, newRange.min, newRange.max, newAgeRange.min, newAgeRange.max, newMaxTotal, newPrice, props.match.params.resource_id, modal.serviceId);
      if (data.status === "OK") {
        setModal({ type: "notification", subtype: "success", message: data.message, action: "closeModal" });
        handleGetServices();
      } else {
        setModal({ type: "notification", subtype: "error", message: data.message, action: "openEditModal", serviceId: modal.serviceId });
      }
    } catch (e) {
      console.log("resources.js handleEditResource", e);
    }
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModal(false);
  };

  const SingleService = (props) => {
    return (
      <div className="card block">
        <header className="card-header">
          <p className="card-header-title">Servizio #{props.id}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <p className="title is-5 is-spaced">{props.name}</p>
            <p className="subtitle is-6">{props.description}</p>
            <hr />
            <div className="p-4">
              <p className="is-size-6">Durata</p>
              <InputRange
                minValue={15}
                maxValue={1440}
                value={props.duration}
                onChange={() => null}
                disabled={true}
                formatLabel={(value) => `${Math.floor((value / 60) * 100) / 100}h`}
              />
            </div>
            <div className="p-4 pt-6">
              <p className="is-size-6">Range: minimo e massimo di persone per singola prenotazione</p>
              <InputRange
                minValue={1}
                maxValue={40}
                value={{ min: props.min_pax, max: props.max_pax }}
                onChange={() => null}
                disabled={true}
                formatLabel={(value) => `${value}pax`}
                allowSameValues={true}
              />
            </div>
            <div className="p-4 pt-6">
              <p className="is-size-6">Età (min e max)</p>
              <InputRange
                minValue={0}
                maxValue={100}
                value={{ min: props.min_age, max: props.max_age }}
                onChange={() => null}
                disabled={true}
                formatLabel={(value) => `${value}`}
                allowSameValues={true}
              />
            </div>
            <div className="p-4 pt-6">
              <p className="is-size-6">Numero massimo di prenotazioni nello stesso orario</p>
              <p className="subtitle is-6">{props.max_total_pax}</p>
            </div>
            <div className="p-4 pt-6">
              <p className="is-size-6">Prezzo per persona</p>
              <p className="subtitle is-6">{props.pax_price} €</p>
            </div>
          </div>
        </div>
        <footer className="card-footer">
          <a
            href="/"
            className="card-footer-item"
            onClick={(e) => {
              openEditModal(e, {
                serviceId: props.id,
                name: props.name,
                description: props.description,
                min_pax: props.min_pax,
                max_pax: props.max_pax,
                min_age: props.min_age,
                max_age: props.max_age,                
                duration: props.duration,
                max_total_pax: props.max_total_pax,
                pax_price: props.pax_price
              });
            }}
          >
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-pencil-alt"></i>
              </span>
              <span className="is-hidden-mobile">Modifica</span>
            </span>
          </a>

          <a
            href="/"
            className="card-footer-item"
            onClick={(e) => deleteConfirm(e, { type: "delete", name: props.name, description: props.description, id: props.id })}
          >
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-trash-alt"></i>
              </span>
              <span className="is-hidden-mobile">Elimina</span>
            </span>
          </a>

          <Link to={`/admin/slots/${props.id}`} className="card-footer-item">
            <span className="is-hidden-mobile">Slots</span>
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-arrow-right"></i>
              </span>
            </span>
          </Link>
        </footer>
      </div>
    );
  };

  if (isForbidden) {
    return <Forbidden />;
  }

  return (
    <div className="has-background-warning p-3 services">
      <Loader isLoading={isLoading} />

      <div className="container">
        <Breadcumb service={services?.length > 0 && services[0]} />

        <h2 className="title is-2">Servizi</h2>
        <h4 className="subtitle is-4">
          Il servizio definisce tipologia di discesa (es. classic, exciting, etc.), orari, numero minimo di partecipanti per singola prenotazione, numero massimo di partecipanti e durata media dell'esperienza.
        </h4>

        <div className="columns is-multiline">
          {(services || []).map((item, index) => {
            return (
              <div className="column is-full" key={`res-${item.id}`}>
                <SingleService
                  name={item.name}
                  description={item.description}
                  duration={item.duration}
                  id={item.id}
                  min_pax={item.min_pax}
                  max_pax={item.max_pax}
                  min_age={item.min_age}
                  max_age={item.max_age}
                  max_total_pax={item.max_total_pax}
                  pax_price={item.pax_price}
                />
              </div>
            );
          })}
        </div>

        {!services && (
          <div className="block has-text-centered">
            <span>Ancora nessun servizio in questa attività.</span>
          </div>
        )}

        <div className="block has-text-centered p-6">
          <button className="button is-primary is-outlined is-medium is-centered" onClick={openAddModal}>
            <span> Aggiungi un Servizio</span>
            <span className="icon is-small">
              <i className="fas fa-plus"></i>
            </span>
          </button>
        </div>

        {modal?.type === "delete" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Servizio #{modal.id}</p>
                <button className="delete" aria-label="close" onClick={deleteConfirm}></button>
              </header>
              <section className="modal-card-body">
                Se vuoi davvero eliminare l'attività <b>{modal.name}</b> clicca su Elimina
              </section>
              <footer className="modal-card-foot">
                <button className="button is-danger" onClick={() => handleDeleteService(modal.id)}>
                  Elimina
                </button>
                <button className="button" onClick={deleteConfirm}>
                  Annulla
                </button>
              </footer>
            </div>
          </div>
        )}

        {modal?.type === "add" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Aggiungi un servizio</p>
                <button className="delete" aria-label="close" onClick={closeModal}></button>
              </header>
              <section className="modal-card-body">
                <form>
                  <div className="field">
                    <div className="control">
                      <input className={`input is-normal`} value={newName} onChange={changeNewName} type="text" placeholder="Titolo" autoFocus="" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input className={`input is-normal`} value={newDescription} onChange={changeNewDescription} type="text" placeholder="Descrizione" />
                    </div>
                  </div>

                  <div className="p-4">
                    <p className="is-size-6 pb-4">Durata</p>
                    <InputRange
                      minValue={15}
                      maxValue={1440}
                      value={newDuration}
                      onChange={(val) => changeNewDuration(val)}
                      step={15}
                      disabled={false}
                      formatLabel={(value) => `${Math.floor((value / 60) * 100) / 100}h`}
                    />
                  </div>
                  <div className="p-4">
                    <p className="is-size-6  pb-4">Range (min e max partecipanti)</p>
                    <InputRange
                      minValue={1}
                      maxValue={40}
                      value={{ min: newRange.min, max: newRange.max }}
                      onChange={(val) => changeNewRange(val)}
                      disabled={false}
                      formatLabel={(value) => `${value}pax`}
                      allowSameValues={true}
                    />
                  </div>

                  <div className="p-4">
                    <p className="is-size-6  pb-4">Età (min e max)</p>
                    <InputRange
                      minValue={0}
                      maxValue={100}
                      value={{ min: newAgeRange.min, max: newAgeRange.max }}
                      onChange={(val) => changeNewAgeRange(val)}
                      disabled={false}
                      formatLabel={(value) => `${value}`}
                      allowSameValues={true}
                    />
                  </div>

                  <div className="p-4">
                    <p className="is-size-6 pb-4">Massimo prenotazioni per intero slot</p>
                    <div className="field">
                      <div className="control">
                        <input
                          className={`input is-normal`}
                          value={newMaxTotal}
                          onChange={changeNewMaxTotal}
                          type="number"
                          placeholder="Numero massimo prenotazioni nello stesso slot"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="p-4">
                    <p className="is-size-6 pb-4">Prezzo per persona</p>
                    <div className="field">
                      <div className="control has-icons-left">
                        <span className="icon is-small is-right">
                          <i className="fas fa-euro-sign"></i>
                        </span>
                        <input className={`input is-normal`} value={newPrice} onChange={changeNewPrice} type="number" placeholder="Perzzo in €" />
                      </div>
                    </div>
                  </div>
                </form>
              </section>
              <footer className="modal-card-foot">
                <button className="button is-success" onClick={handleAddService}>
                  Aggiungi
                </button>
                <button className="button" onClick={closeModal}>
                  Annulla
                </button>
              </footer>
            </div>
          </div>
        )}

        {modal?.type === "edit" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Modifica servizio #{modal.serviceId}</p>
                <button className="delete" aria-label="close" onClick={closeModal}></button>
              </header>
              <section className="modal-card-body">
                <form>
                  <div className="field">
                    <div className="control">
                      <input className={`input is-normal`} value={newName} onChange={changeNewName} type="text" placeholder="Titolo" autoFocus="" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input className={`input is-normal`} value={newDescription} onChange={changeNewDescription} type="text" placeholder="Descrizione" />
                    </div>
                  </div>

                  <div className="p-4">
                    <p className="is-size-6 pb-4">Durata</p>
                    <InputRange
                      minValue={15}
                      maxValue={1440}
                      value={newDuration}
                      onChange={(val) => changeNewDuration(val)}
                      step={15}
                      disabled={false}
                      formatLabel={(value) => `${Math.floor((value / 60) * 100) / 100}h`}
                    />
                  </div>
                  <div className="p-4">
                    <p className="is-size-6  pb-4">Range (min e max partecipanti)</p>
                    <InputRange
                      minValue={1}
                      maxValue={40}
                      value={{ min: newRange.min, max: newRange.max }}
                      onChange={(val) => changeNewRange(val)}
                      disabled={false}
                      formatLabel={(value) => `${value}pax`}
                      allowSameValues={true}
                    />
                  </div>

                  <div className="p-4">
                    <p className="is-size-6  pb-4">Età (min e max)</p>
                    <InputRange
                      minValue={0}
                      maxValue={100}
                      value={{ min: newAgeRange.min, max: newAgeRange.max }}
                      onChange={(val) => changeNewAgeRange(val)}
                      disabled={false}
                      formatLabel={(value) => `${value}`}
                      allowSameValues={true}
                    />
                  </div>                  

                  <div className="p-4">
                    <p className="is-size-6 pb-4">Massimo prenotazioni per intero slot</p>
                    <div className="field">
                      <div className="control">
                        <input
                          className={`input is-normal`}
                          value={newMaxTotal}
                          onChange={changeNewMaxTotal}
                          type="number"
                          placeholder="Numero massimo prenotazioni nello stesso slot"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="p-4">
                    <p className="is-size-6 pb-4">Prezzo per persona</p>
                    <div className="field">
                      <div className="control has-icons-left">
                        <span className="icon is-small is-right">
                          <i className="fas fa-euro-sign"></i>
                        </span>
                        <input className={`input is-normal`} value={newPrice} onChange={changeNewPrice} type="number" placeholder="Perzzo in €" />
                      </div>
                    </div>
                  </div>

                </form>
              </section>
              <footer className="modal-card-foot">
                <button className="button is-success" onClick={handleEditService}>
                  Salva
                </button>
                <button className="button" onClick={closeModal}>
                  Annulla
                </button>
              </footer>
            </div>
          </div>
        )}

        {modal?.type === "notification" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="box">
                <div className="block">
                  <p className={`is-size-6 ${modal?.subtype === "error" ? "has-text-danger" : "has-text-success"}`}>{modal?.message}</p>
                </div>
                <button
                  className={`button ${modal?.subtype === "error" ? "is-danger" : "is-success"}`}
                  onClick={
                    modal?.action === "openAddModal"
                      ? openAddModal
                      : modal?.action === "reOpenAddModal"
                      ? reOpenAddModal
                      : modal?.action === "openEditModal"
                      ? (e) => openEditModal(e, { serviceId: modal?.serviceId })
                      : closeModal
                  }
                >
                  Chiudi
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Resources;
