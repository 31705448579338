import React, { useState, useEffect, useRef } from "react";
import { fetchBookings, exportBookings, fetchPrivacyUrl } from "./../../model/admin";
//CALENDAR
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import itLocale from "@fullcalendar/core/locales/it";
import { Link } from "react-router-dom";
import _ from "lodash";
//CALENDAR
import dayjs from "dayjs";

const BookingsList = (props) => {
  //const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState({ from: false, to: false });
  const [details, setDetails] = useState(false);

  const calendarRef = useRef(null);

  const handleFetchEvents = async () => {
    let response = await fetchBookings(dateRange.from, dateRange.to);
    //console.log(response);
    if (response.status === "OK") {
      setEvents(response.data);
    } else {
      setEvents([]);
    }
  };

  const handleFetchPrivacyUrl = async (e, member_id, booking_id) => {
    e.preventDefault();
    let response = await fetchPrivacyUrl(member_id, booking_id);
    //console.log(response);
    if (response.status === "OK") {
      console.log(response.data);
      window.open(`${window.location.origin}/privacy/${response.data.privacyToken}`, '_blank').focus();
    } else {
      console.log("error");
    }
  };

  const _datesSet = (e) => {
    const _startDate = dayjs(e.startStr).format("YYYY-MM-DD");
    const _endDate = dayjs(e.endStr).format("YYYY-MM-DD");

    if (_startDate !== dateRange.from || _endDate !== dateRange.to) {
      setDateRange({ from: _startDate, to: _endDate });
    }
  };

  useEffect(() => {
    if (dateRange.from !== false && dateRange.to !== false) handleFetchEvents();
  }, [dateRange, props.service_id, props.slug]);


  const handleEventClick = (e) => {
    setDetails(e.event._def.extendedProps);
  };

  const handleExport = async () =>{
    await exportBookings(dayjs().startOf('year').format('YYYY-MM-DD'), dayjs().endOf('year').format('YYYY-MM-DD'));
  }

  return (
    <div className="has-background-warning p-3 resources">

      <div className="container">
        <h2 className="title is-2 pt-6">Prenotazioni</h2>
        <h4 className="subtitle is-4 pb-4">Visualizza le prenotazioni o {" "}
          <button className="button is-primary is-small" onClick={handleExport}
            ><span>esporta</span>
            <span className="icon">
              <i className="fas fa-file-csv"></i>
            </span>
    </button>
      </h4>

        <FullCalendar
          locale={itLocale}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
          headerToolbar={{
            left: "prev,next", //today
            center: "title",
            right: "_listWeek,_listDay _grid",
          }}
          customButtons={{
            //_listMonth: { text: 'Mese', click: ()=> { calendarRef.current.getApi().changeView("listMonth"); }},
            _listWeek: {
              text: "Settimana",
              click: () => {
                calendarRef.current.getApi().changeView("listWeek");
              },
            },
            _listDay: {
              text: "Giorno",
              click: () => {
                calendarRef.current.getApi().changeView("listDay");
              },
            },
            _grid: {
              text: "Griglia",
              click: () => {
                calendarRef.current.getApi().changeView("dayGridWeek");
              },
            },
            //_grid: { text: 'Griglia', click: ()=> { calendarRef.current.getApi().changeView("timeGridWeek"); }},
          }}
          initialView={"listWeek"}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          height={400}
          eventClick={handleEventClick}
          events={events}
          ref={calendarRef}
          validRange={{
            start: process?.env?.NODE_ENV === "development" ? dayjs().subtract(1, "years").format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
            end: dayjs().add(1, "years").format("YYYY-MM-DD"),
          }}
          datesSet={_datesSet}
          noEventsText="Non ci sono prenotazioni in questo range di date"
        />
      </div>
      { details !== false &&
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Dettagli</p>
                <button className="delete" aria-label="close" onClick={()=>{setDetails(false)}}></button>
              </header>
              <section className="modal-card-body"> 
              <div className="table-container">
              <table className="table is-bordered is-striped is-hoverable is-fullwidth">
                <tbody>
                  <tr>
                    <td>Stato prenotazione</td>
                    <th className={details.members[0].booking_status === "active" ? "has-text-success" : "has-text-danger"}>
                      {details.members[0].booking_status === "active" ? "Attiva" : "Cancellata"}
                      {details.members[0].booking_status !== "active" && details.members[0].cancellation_date !== null && <span> il {dayjs(details.members[0].cancellation_date).format("YYYY/MM/DD hh:mm")}</span>}
                      </th>
                  </tr>                  
                  <tr>
                    <td>Persone</td>
                    <th>{details.members[0].booking_pax}</th>
                  </tr>
                  <tr>
                    <td>Pagamento</td>
                    <th>{details.members[0].payment_status === "total" ? "Saldato" : "Biglietti da pagare"}</th>
                  </tr>
                  <tr>
                    <td>Id Pagamento</td>
                    <th>{details.members[0].payment_id}</th>
                  </tr>
                  {details.members.map((m, index)=>{
                    return(
                      <tr key={m.id+"-"+m.booking_id}>
                      <td>Partecipante n.{index+1}</td>
                      <td>{m.nome} {m.cognome} <br/>
                        Tessera: <b>{ m.status === "emitted" ? "emessa" : "pagata, da emettere" }<br/></b>
                        { m.status === "payed" && <><Link target={"_blank"} to={`/card/${m.nome}/${m.cognome}/${m.uuid}`}>Vai al form di teseramento</Link><br/></> }
                        <div className="sep"></div>
                        {"Consenso informato:"} <b>{m.mb_consenso_informato !== null && m.mb_consenso_informato !== 0 ? "Si" : "No"}</b><br/>
                        { <><Link target={"_blank"} href="#" onClick={(e)=>handleFetchPrivacyUrl(e, m.id, m.booking_id)}>Vai al form di consenso informato</Link> <br/></>}
                        <div className="sep"></div>                        
                        { (m.codice_fiscale !== null) && <>"Codice fiscale: "+m.codice_fiscale <br/></>}
                        { (m.straniero === 0 || m.straniero === "0") ? "Residente Italia" : "Residente Estero" }<br/>
                        { (m.data_nascita !== null) && <>{"Data di nascita: "+dayjs(m.data_nascita).format("YYYY/MM/DD")}<br/></> }
                        { (m.stato_nascita !== null) && <>{"Stato di nascita: "+m.stato_nascita}<br/></> }
                        { (m.sesso !== null) && <>{"Stato di nascita: "+m.sesso }<br/></>}
                        { (m.privacy_commerciale !== null) && <>{`Privacy commerciale: ${m.privacy_commerciale ? "Si" : "No"}`}<br/></> }
                        Email: <b>{ m.email}</b><br/>
                        { (m.telefono !== null ) && <>{"Telefono: "}<b>{m.telefono}</b><br/></>}                        
                        { (m.responsabile_minore_termini !== null ) && <>{`Accettazione termini resp. genit./tutoria: ${m.responsabile_minore_termini ? "Si" : "No"}` }<br/></>}
                        { (m.responsabile_minore_nome !== null ) && <>{`Nome responsabile: ${m.responsabile_minore_nome}` }<br/></>}
                        { (m.responsabile_minore_cognome !== null ) && <>{`Cognome responsabile: ${m.responsabile_minore_cognome}` }<br/></>}
                      </td>
                    </tr>                      
                    )
                  })}

                </tbody>
              </table>
              </div>
                {/* <pre>
                  {JSON.stringify(details, null, 2)}
                </pre>                */}
              </section>
              <footer className="modal-card-foot">
                <button className="button is-primary" onClick={()=>{setDetails(false)}}>
                  Chiudi
                </button>
{/* <pre style={{overflow:"auto", height:300}}>{JSON.stringify(details.members[0], null, 2)}</pre> */}
              </footer>
            </div>
          </div>
      }

    </div>
  );
};

export default BookingsList;
