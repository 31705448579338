import React, { useEffect, useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { fetchLogin } from "./../../model/auth";
import {AppContext} from "./../../store/AppContext"

const Login = (props) => {
  const {context, dispatch} = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };
  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try{
      const response = await fetchLogin(email, password);
      if (response.status === "OK") {
        dispatch({type: "SET_TOKEN", token: response.token, email: response.email});
      }else{
        setError(response?.error);        
      }
    }catch(e){
      setError({error: {generic: "Si è verificato un errore"}});
    }finally{
      setIsLoading(false);
    }

  };

  useEffect(()=>{
    if(error !== false){
      setError(false);
    }
  },[email, password])

  if(context?.token) return <Redirect to="/admin/dashboard" />

  return (
      <div className="hero is-fullheight is-link">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              {/* <h3 className="title has-text-white">Login</h3>
              <hr className="login-hr" />
              <p className="subtitle has-text-white">Please login to see our cool stuff!</p> */}
              <div className="box">
                <img src="/img/firaft-logo.png" className="authLogo" alt="logo" />
                <p className="title has-text-grey is-5">Login</p>
                <form>
                  <div className="field">
                    <div className="control">
                    {props?.match?.params?.type === "reset" && <p className="is-size-6 has-text-success">Password modificata con success, effettua il login</p>}
                    {error?.generic && <p className="is-size-6 has-text-danger">{error?.generic}</p>}
                      {error?.email && <p className="is-size-6 has-text-danger">{error?.email}</p>}
                      <p className="control has-icons-left">
                        <input className={`input is-medium ${error?.email && "is-danger"}`} value={email} onChange={changeEmail} type="email" placeholder="Email" autoFocus="" />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control is-medium">
                    {error?.password && <p className="is-size-6 has-text-danger">{error?.password}</p>}
                      <p className="control has-icons-left">
                        <input className="input is-medium" value={password} onChange={changePassword} type="password" placeholder="Password" />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                  <button className={`button is-block is-danger is-medium is-fullwidth ${isLoading && "is-loading"}`} onClick={handleLogin}>
                    Login
                  </button>
                </form>
              </div>
              <p className="has-text-white">
                <Link to="/admin/lostpassword">Password dimenticata</Link> &nbsp;·&nbsp;
                <a href="mailto:segreteria@federrafting.it">Hai bisogno di aiuto?</a>
              </p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;
