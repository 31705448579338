import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchForgot } from "./../../model/auth";

const LostPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleForgot = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetchForgot(email);
      if (response.status === "OK") {
        setSuccess(response.message);
      } else {
        setError(response?.error);
      }
      console.log(response);
    } catch (e) {
      setError({ error: { generic: "Si è verificato un errore" } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error !== false) {
      setError(false);
    }
  }, [email]);

  return (
      <div className="hero is-fullheight is-link">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              {/* <h3 className="title has-text-white">Login</h3>
              <hr className="login-hr" />
              <p className="subtitle has-text-white">Please login to see our cool stuff!</p> */}
              <div className="box">
                <img src="/img/firaft-logo.png" className="authLogo" alt="logo" />

                {!success ? (
                  <>
                    <p className="title has-text-grey is-5">Inserisci la tua email per recuperare la password</p>

                    <form>
                      <div className="field">
                        <div className="control">
                          {error?.generic && <p className="is-size-6 has-text-danger">{error?.generic}</p>}
                          {error?.email && <p className="is-size-6 has-text-danger">{error?.email}</p>}
                          <p className="control has-icons-left">
                            <input
                              className={`input is-medium ${error?.email && "is-danger"}`}
                              value={email}
                              onChange={changeEmail}
                              type="email"
                              placeholder="Email"
                              autoFocus=""
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                      <button className={`button is-block is-danger is-medium is-fullwidth ${isLoading && "is-loading"}`} onClick={handleForgot}>
                        Recupera
                      </button>
                    </form>
                  </>
                ) : (
                  <p className="is-size-6 has-text-success">{success}</p>
                )}
              </div>
              <p className="has-text-white">
                <Link to="/admin/login">Login</Link> &nbsp;·&nbsp;
                <a href="mailto:segreteria@federrafting.it">Hai bisogno di aiuto?</a>
              </p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default LostPassword;
