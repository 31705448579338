import React, { useState, useEffect } from "react";
import Error404 from "./../../template/Error404";
import Loader from "./../../template/Loader";
import { Select as SelectVirtualized } from "react-select-virtualized";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import _ from "lodash";
import { fetchPrivacy, savePrivacy } from "./../../model/privacy";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { legal_booking_confirmation_it, legal_booking_confirmation_underage_it } from "../../legal/booking_confirmation_it";
import { legal_booking_confirmation_en, legal_booking_confirmation_underage_en } from "../../legal/booking_confirmation_en";
import { legal_booking_confirmation_de, legal_booking_confirmation_underage_de } from "../../legal/booking_confirmation_de";
import { legal_booking_confirmation_fr, legal_booking_confirmation_underage_fr } from "../../legal/booking_confirmation_fr";

const Card = (props) => {
  const { t, i18n } = useTranslation();

  const [exist, setExist] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [privacyData, setPrivacyData] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    if (props.match?.params?.token) {
      handleFetchPrivacy(props.match?.params?.token);
    }
  }, [props.match?.params]);

  const handleFetchPrivacy = async (token) => {
    let _data = await fetchPrivacy({ token });
    console.log(_data);
    if (_data.status === "OK") {
      setPrivacyData(_data);
    } else {
      setExist(false);
    }
    setIsLoading(false);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    let error = {};
    if (formData["terms"] !== "1") {
      error[`terms`] = t("Per confermare la prenotazione devi accettare i termini");
    }
    if (privacyData?.data?.minorenne) {
      if (!formData.hasOwnProperty("responsabile_minore_nome") || formData["responsabile_minore_nome"].length < 2) {
        error[`responsabile_minore_nome`] = t("Inserire il nome del responsabile. Minimo 2, massimo 30 caratteri.");
      }
      if (!formData.hasOwnProperty("responsabile_minore_cognome") || formData["responsabile_minore_cognome"].length < 2) {
        error[`responsabile_minore_cognome`] = t("Inserire il cognome del responsabile. Minimo 2, massimo 30 caratteri.");
      }
    }

    setFormError(error);

    if (_.isEmpty(error)) {
      const args = { token: props.match?.params?.token, body: formData };
      let newPrivacy = await savePrivacy(args);
      if (newPrivacy.status === "OK") {
        setPrivacyData(newPrivacy);
      }
    } else {
      error[`generic`] = t("Verifica i campi contrassegnati in rosso e invia nuovamente.");
      // window.scrollTo(0, 400);
    }
    setIsSending(false);
  };

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }
  if (!exist) return <Error404 />;

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="column is-12">
            <h2 className="subtitle is-3">{t("Consenso informato all'attività")}</h2>
            <hr />

            <div className="box has-background-warning">
              <p className="pt-2">
                {t("Nome e Cognome")}:{" "}
                <b>
                  {privacyData.data.nome} {privacyData.data.cognome}
                </b>
              </p>
              <p className="pt-2">
                {t("Servizio")}: <b>{privacyData.data.resource_service_name}</b>
              </p>
            </div>

            <div className="legalParagraph">
              <div className="is-size-7">
                <ReactMarkdown className="has-markdown" linkTarget="_blank">
                  {i18n.language === "en"
                    ? legal_booking_confirmation_en
                    : i18n.language === "de"
                    ? legal_booking_confirmation_de
                    : i18n.language === "fr"
                    ? legal_booking_confirmation_fr
                    : legal_booking_confirmation_it}
                </ReactMarkdown>
              </div>
            </div>

            {privacyData.data.consenso_informato === null ? (
              <div className="column is-12 ">
                <div className="control pt-3">
                  <label className="radio">
                    <input type="radio" onChange={onChange} name={`terms`} value={1} /> <b>{t("Acconsento")}</b>
                  </label>
                  <label className="radio">
                    <input type="radio" onChange={onChange} name={`terms`} value={0} /> <b>{t("Nego il consenso")}</b>
                  </label>
                  {formError[`terms`] && <p className="has-text-danger p-1">{formError[`terms`]}</p>}
                </div>
              </div>
            ) : null}
            <hr />

            {privacyData?.data?.minorenne && privacyData.data.consenso_informato === null ? (
              <>
                <p className={"title is-6"}>{t("Dati dell’esercente responsabilità genitoriale/tutoria")}</p>
                <div className="columns">
                  <div className="column field">
                    <label className="label">{t(`Nome`)}</label>
                    <div className="control">
                      <input
                        className={`input is-normal`}
                        type="text"
                        placeholder={t(`Nome`)}
                        name={`responsabile_minore_nome`}
                        value={formData[`responsabile_minore_nome`] ? formData[`responsabile_minore_nome`] : ""}
                        onChange={onChange}
                      />
                      {formError[`responsabile_minore_nome`] && <p className="has-text-danger p-1">{formError[`responsabile_minore_nome`]}</p>}
                    </div>
                  </div>
                  <div className="column field">
                    <label className="label">{t(`Cognome`)}</label>
                    <div className="control">
                      <input
                        className={`input is-normal`}
                        type="text"
                        placeholder={t(`Cognome`)}
                        name={`responsabile_minore_cognome`}
                        value={formData[`responsabile_minore_cognome`] ? formData[`responsabile_minore_cognome`] : ""}
                        onChange={onChange}
                      />
                      {formError[`responsabile_minore_cognome`] && <p className="has-text-danger p-1">{formError[`responsabile_minore_cognome`]}</p>}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {privacyData?.data?.minorenne && privacyData.data.consenso_informato !== null ? (
              <>
                <p className="is-size-5">{i18n.t("Dati dell’esercente responsabilità genitoriale/tutoria")}</p>
                <p className="pb-2">
                  {i18n.t("Nome")} : <b>{privacyData?.data?.responsabile_minore_nome}</b>
                  <br />
                  {i18n.t("Cognome")} : <b>{privacyData?.data?.responsabile_minore_cognome}</b>
                </p>
              </>
            ) : (
              ''
            )}

            {privacyData?.data?.minorenne && (
              <div className={"is-size-7"}>
                <ReactMarkdown className="has-markdown" linkTarget="_blank">
                  {i18n.language === "en"
                    ? legal_booking_confirmation_underage_en
                    : i18n.language === "de"
                    ? legal_booking_confirmation_underage_de
                    : i18n.language === "fr"
                    ? legal_booking_confirmation_underage_fr
                    : legal_booking_confirmation_underage_it}
                </ReactMarkdown>
              </div>
            )}

            <div className="buttons is-centered">
              <div className="column is-12 has-text-centered">{formError[`generic`] && <p className="has-text-danger p-1">{formError[`generic`]}</p>}</div>

              {privacyData.data.consenso_informato === null ? (
                <button
                  className={`button is-centered is-primary block is-medium is-link ${isSending && "is-loading"}`}
                  disabled={isSending}
                  type="submit"
                  onClick={onSubmit}
                >
                  {t("Invia")}
                </button>
              ) : (
                <button
                  className={`button is-centered is-primary is-light block is-medium is-link ${isSending && "is-loading"}`}
                  disabled={true}
                  type="submit"
                  onClick={() => null}
                >
                  {t("Consenso già fornito")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
