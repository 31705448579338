import React from "react";
import {Link} from "react-router-dom"

const Home = (props) => {
  
  return (
    <div className="hero is-fullheight is-light">
      <div className="hero-body">
      <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              <img className="headLogo" src="/img/firaft-logo.png" alt="FIRAFT" />
              <h2 className="title is-2">FIRAFT Booking</h2>
              <h3 className="title is-4">Per effettuare l'accesso chiedi informazioni alle associazioni aderenti.</h3>
              { props.mode === "admin" &&
                <Link className="button is-danger is-light is-medium is-centered" to={"/admin/dashboard"}>Torna alla dashboard</Link>              
              }
            </div>
          </div>
      </div>
    </div>
  );
};

export default Home;
