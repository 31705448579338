import React, { useState, useEffect, useRef } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import dayjs from "dayjs";
import _ from "lodash";
import { getBirthState, validateSubscriptions } from "./../../../model/booking";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSVReader } from "react-papaparse";
import ReactMarkdown from 'react-markdown'
import legal_booking_it from "./../../../legal/booking_it";
import legal_booking_en from "./../../../legal/booking_en";
import legal_booking_de from "./../../../legal/booking_de";
import legal_booking_fr from "./../../../legal/booking_fr";

registerLocale("it", it);

const BookingForm = (props) => {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [paxNumber, setPaxNumber] = useState(props.bookingData.service.min_pax);
  const [birthState, setBirthState] = useState([]);
  const [isformLoading, setIsFormLoading] = useState(false);
  const [membersData, setMembersData] = useState(false);
  const [importerVisibile, setImporterVisibile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetBirthState();
    return null;
  }, []);

  const handleGetBirthState = async () => {
    const response = await getBirthState();
    let bs = [];
    if (response?.status === "OK") {
      let data = response.data;
      for (let i = 0; i < data.length; i++) {
        bs.push({ label: data[i].denominazione, value: data[i].denominazione });
      }
    }
    setBirthState(bs);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const changePaxNumber = (value, e) => {
    if (!_.isUndefined(e)) {
      e.preventDefault();
    }
    setPaxNumber(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    let error = {};
    for (let i = 0; i < paxNumber; i++) {
      if (_.isUndefined(formData[`email-${i}`]) || formData[`email-${i}`].length < 1) {
        error[`email-${i}`] = t("Inserisci un indirizzo email");
      } else {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData[`email-${i}`])) {
          error[`email-${i}`] = t("Inserisci un indirizzo email valido");
        }
      }
      
      // rimosso dopo segnalazione aqqua
      // if (i == 0 && ( dayjs().diff(dayjs(formData[`birthDate-${i}`]), "year") < 18 ) ) {
      //   error[`birthDate-${i}`] = t("Devi essere maggiorenne per gestire la prenotazione");
      // }
      if (i == 0 && (_.isUndefined(formData[`telefono-${i}`]) || formData[`telefono-${i}`].length < 1)) {
        error[`telefono-${i}`] = t("Inserisci il telefono");
      }
      if (_.isUndefined(formData[`firstName-${i}`]) || formData[`firstName-${i}`].length < 1) {
        error[`firstName-${i}`] = t("Inserisci il nome");
      }
      if (_.isUndefined(formData[`lastName-${i}`]) || formData[`lastName-${i}`].length < 1) {
        error[`lastName-${i}`] = t("Inserisci il cognome");
      }
      if (_.isUndefined(formData[`birthDate-${i}`]) || !dayjs().isValid(formData[`birthDate-${i}`])) {
        error[`birthDate-${i}`] = t("Inserisci una data di nascita valida");
      }else{
        if ( dayjs().diff(dayjs(formData[`birthDate-${i}`]), "year") < props.bookingData.service.min_age || dayjs().diff(dayjs(formData[`birthDate-${i}`]), "year") > props.bookingData.service.max_age ){
          error[`birthDate-${i}`] = t("Limiti di età")+": "+props.bookingData.service.min_age+"-"+props.bookingData.service.max_age;      
        }
      }
      if (_.isUndefined(formData[`foreigner-${i}`]) || (formData[`foreigner-${i}`] !== "0" && formData[`foreigner-${i}`] !== "1")) {
        error[`foreigner-${i}`] = t("Seleziona nazionalità");
      } else {
        if (formData[`foreigner-${i}`] === "0") {
          // italiano
          if (_.isUndefined(formData[`CF-${i}`]) || formData[`CF-${i}`].length < 10) {
            error[`CF-${i}`] = t("Inserisci un codice fiscale valido");
          }
        } else {
          // straniero
          if (_.isUndefined(formData[`birthState-${i}`]) || formData[`birthState-${i}`].length < 3) {
            error[`birthState-${i}`] = t("Inserisci uno stato di nascita");
          }
          if (_.isUndefined(formData[`gender-${i}`]) || (formData[`gender-${i}`] !== "M" && formData[`gender-${i}`] !== "F")) {
            error[`gender-${i}`] = t("Inserisci il genere");
          }
        }
      }
      if (_.isUndefined(formData[`terms`]) || formData[`terms`] !== "1") {
        error[`terms`] = t("Per confermare la prenotazione devi accettare i termini");
      }
    }

    setFormError(error);

    if (_.isEmpty(error)) {
      checkSubscription();
    } else {
      error[`generic-0`] = t("Verifica i campi contrassegnati in rosso e invia nuovamente.");
      window.scrollTo(0, 400);
      setIsFormLoading(false);
    }
  };

  const checkSubscription = async () => {
    setIsFormLoading(true);
    let error = {};
    let members = await formatFormData();

    let validations = await validateSubscriptions(members);
    for (let i = 0; i < validations.length; i++) {
      if (validations[i].status !== "OK") {
        error[`generic-${i}`] = t("Si è verificato un errore nella verifica della tessera, controlla i dati e riprova.");
      } else {
        members[i].alreadyMember = validations[i].alreadyMember;
      }
    }

    if (_.isEmpty(error)) {
      setMembersData(members);
      props.handleSetMembersData(members);
    } else {
      setFormError(error);
    }

    setIsFormLoading(false);
  };

  const formatFormData = async () => {
    let members = [];

    for (let i = 0; i < paxNumber; i++) {
      members.push({
        email: formData[`email-${i}`],
        nome: formData[`firstName-${i}`],
        cognome: formData[`lastName-${i}`],
        data_nascita: dayjs(formData[`birthDate-${i}`]).format("YYYY-MM-DD"),
        //data_nascita: dayjs(formData[`birthDate-${i}`].split("/").reverse().join("-")).format("YYYY-MM-DD"),
        straniero: formData[`foreigner-${i}`],
      });

      if (formData[`foreigner-${i}`] === "0") {
        //italiano
        members[i].codice_fiscale = formData[`CF-${i}`];
      } else {
        //straniero
        members[i].stato_nascita = formData[`birthState-${i}`];
        members[i].sesso = formData[`gender-${i}`];
      }
      if (i === 0) {
        members[i].telefono = formData[`telefono-${i}`];
      }
    }

    return members;
  };

  const PaxIcon = ({ value }) => {
    return (
      <>
        {value} <i className="fas fa-user has-text-primary" aria-hidden="true"></i>
        {/* {Array(value)
          .fill()
          .map((value, index) => {
            return <i key={`PaxIcon-${index}`} className="fas fa-user has-text-primary" aria-hidden="true"></i>;
          })} */}
      </>
    );
  };

  const exportCsv = async (e) => {
    e.preventDefault();
    try {
      let csvData =
        "Info: 1 utente per riga - gli utenti in eccesso rispetto al massimo consentito in questo servizio non verranno importati \n#Email,#Nome,#Cognome,#Data di nascita (es:21/12/1970),#Residente in Italia (si/no),#Telefono (solo il primo utente),#Codice Fiscale (solo se naz. Italiana),#Sesso (F/M se non res. Italia),#Stato di nascita (se non res. Italia)";
      if (i18n.language === "en") {
        csvData =
          "Info: 1 user per row - users in excess of the maximum allowed in this service will not be imported \n#Email,#First Name,#Last Name,#Birth date (eg:21/12/1970),#Italian nationality (yes/no),#Phone (only the first user),#Fiscal Code (only for Italian),#Gender (F/M only for non Italian),#Birth State (only for non Italian)";
      }
      if (i18n.language === "de") {
        csvData =
          "Info: 1 Benutzer pro Zeile - Benutzer, die die in diesem Dienst zulässige Höchstzahl überschreiten, werden nicht importiert \n#Email,#Vorname,#Nachname,#Geburtstag (zB:21/12/1970),#Italienische Staatsangehörigkeit (yes/no),#Telefon (nur der erste Benutzer),#Abgabenordnung (nur für italienisch),#Geschlecht (F/M nur für Nichtitaliener),#Geburtsstatus (nur für Nichtitaliener)";
      }
      const type = "text/csv; charset=utf-8";
      const blob = new Blob([csvData], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "group.csv";
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnDrop = (data) => {
    console.log("-----QUI mettere id, compilare e dispatch event per ogni campo");
    console.log("---------------------------");
    console.log(data, data.length);
    const _data = [];
    console.log("---------------------------");
    for (let i = 0; i < data.length; i++) {
      if (!data[i].data[0].toUpperCase().startsWith("INFO:") && data[i].data[0].toUpperCase() !== "#EMAIL") {
        _data.push(data[i]);
      }
    }
    console.log(_data);

    setPaxNumber(Math.max(Math.min(_data.length, props.bookingData.service.max_pax), props.bookingData.service.min_pax));

    const event = new Event("input", { bubbles: true });

    let _formData = {};

    for (let i = 0; i < _data.length; i++) {
      let row = _data[i].data;
      for (let j = 0; j < row.length; j++) {
        if (j === 0 && document.getElementsByName("email-" + i).length > 0) {
          _formData["email-" + i] = row[j];
        }
        if (j === 1 && document.getElementsByName("firstName-" + i).length > 0) {
          _formData["firstName-" + i] = row[j];
        }
        if (j === 2 && document.getElementsByName("lastName-" + i).length > 0) {
          _formData["lastName-" + i] = row[j];
        }
        if (j === 3 && document.getElementsByClassName("birthDate-" + i).length > 0) {
          // let formatted = dayjs(row[j], "DD-MM-YYYY").format("YYYY-MM-DD");
          // let _date = new Date(formatted);
          let _date = new Date(row[j].split("/").reverse().join("-"));
          console.log(_date);
          if (_date.toString() !== "Invalid Date") {
            _formData["birthDate-" + i] = _date;
          }
        }
        if (j === 4 && document.getElementsByName("foreigner-" + i).length === 2) {
          _formData["foreigner-" + i] = ["si", "s", "yes", "y", "SI", "Si", "YES", "Y"].includes(row[j]) ? "0" : "1";
          ["si", "s", "yes", "y", "SI", "Si", "YES", "Y"].includes(row[j])
            ? document.getElementsByName("foreigner-" + i)[0].click()
            : document.getElementsByName("foreigner-" + i)[1].click();
        }
        if (j === 5 && document.getElementsByName("telefono-" + i).length > 0) {
          _formData["telefono-" + i] = row[j];
        }
        if (j === 6 && document.getElementsByName("CF-" + i).length > 0) {
          _formData["CF-" + i] = row[j];
        }
        if (j === 7 && document.getElementsByName("gender-" + i).length > 0) {
          _formData["gender-" + i] = ["f", "F"].includes(row[j]) ? "F" : "M";
          ["f", "F"].includes(row[j]) ? document.getElementsByName("gender-" + i)[0].click() : document.getElementsByName("gender-" + i)[1].click();
        }
        if (j === 8 && document.getElementsByName("birthState-" + i).length > 0) {
          _formData["birthState-" + i] = row[j].toUpperCase();
        }
      }
    }

    setFormData(_formData);
    
    setTimeout(()=>{
      alert(i18n.t("Importazione completa. Verifica eventuali dati mancanti ed invia"));
    },1500)

    // const event = new Event('input', { bubbles: true })
    // document.getElementsByName('email-0')[0].value = 'compilare qui';
    // document.getElementsByName('email-0')[0].dispatchEvent(event)
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  return (
    <div className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <h3 className="subtitle is-4">{t("Compila il form per confermare la prenotazione")}</h3>

          <div className="box has-background-warning">
            <h3 className="subtitle is-3">
              <button
                className="button is-warning block"
                onClick={() => {
                  props.clearBooking();
                }}
              >
                <span className="icon">
                  <i className="fas fa-arrow-left"></i>
                </span>
              </button>{" "}
              {t("Riepilogo")}
            </h3>
            <p className="pt-2">
              {t("Associazione")}:{" "}
              <b>
                {props.bookingData.resource.username}
                {props.bookingData.resource?.display_name && " | " + props.bookingData.resource?.display_name}
              </b>
            </p>
            <p className="pt-2">
              {t("Servizio")}:{" "}
              <b>
                {props.bookingData.resource.name} | {props.bookingData.service.name}
              </b>
            </p>
            <p className="pt-2">
              {t("Limiti di età")}: <b>{props.bookingData.service.min_age}-{props.bookingData.service.max_age}</b>
            </p>
            <p className="pt-2">
              {t("Durata")}: <b>{props.bookingData.service.duration} minuti</b>
            </p>
            <p className="pt-2">
              {t("Prezzo per persona")}:{" "}
              <b>
                {props.bookingData.service?.pax_price !== 0 ? props.bookingData.service.pax_price + " €" : "0 €"} (
                {t("escluso eventuale costo del tesseramento")} {t("pari a")} 2€)
              </b>
            </p>
            <p className="pt-2">
              {t("Data e ora")}: <b>{dayjs(props.bookingData.slot?.date_start).format("DD/MM/YYYY HH:mm")}</b>
            </p>
          </div>

          <div className="box has-background-light">
            <h4 className="subtitle is-4">
              {t("Per quante persone vuoi prenotare")} (min: {props.bookingData.service.min_pax} max: {props.bookingData.service.max_pax})
            </h4>
            <div className="field">
              <div className="control p-3">
                <InputRange
                  minValue={props.bookingData.service.min_pax}
                  maxValue={props.bookingData.service.max_pax}
                  value={paxNumber}
                  onChange={(value) => {
                    changePaxNumber(value);
                  }}
                  disabled={false}
                  formatLabel={(value) => <PaxIcon value={value} />}
                />
              </div>
            </div>

            <div className="has-text-centered">
              <button className="button is-primary is-small is-outlined openCsvImporter" onClick={() => setImporterVisibile(!importerVisibile)}>
                <span className="icon">
                  <i className={ importerVisibile ? "fas fa-arrow-up" : "fas fa-arrow-down"}></i>
                </span>
                <span className="icon">
                  <i className="fas fa-users"></i>
                </span>
              </button>
            </div>

            {importerVisibile && (
              <div className="csvImporter">

                <span className="csvImporterInfo">{i18n.t('Utilizza un csv per importare i dati degli utenti.')} <a href="#" onClick={exportCsv}>{i18n.t('Scarica il template')} <i className="fas fa-file-csv"></i></a></span>

                <CSVReader
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  style={{
                    dropArea: {
                      borderColor: "#03a9f4",
                      borderRadius: 5,
                      padding: "10px",
                    },
                    dropAreaActive: {
                      borderColor: "red",
                    },
                    dropFile: {
                      width: "100%",
                      height: 100,
                      background: "#03a9f4",
                      borderRadius: 5,
                    },
                    fileSizeInfo: {
                      color: "#fff",
                      backgroundColor: "transparent",
                      lineHeight: 1,
                      marginBottom: "0.5em",
                      padding: "0 0.4em",
                      fontSize: 12,
                    },
                    fileNameInfo: {
                      color: "#fff",
                      backgroundColor: "transparent",
                      borderRadius: 3,
                      fontWeight: "bold",
                      fontSize: 15,
                      lineHeight: 1,
                      padding: "0 0.4em",
                    },
                    progressBar: {
                      backgroundColor: "#ffdd57",
                    },
                  }}
                  config={{}}
                  addRemoveButton
                  onRemoveFile={handleOnRemoveFile}
                  removeButtonColor="#333"
                >
                  <span>
                    {i18n.t('Clicca o Trascina il csv con i dati degli utenti per compilare in automatico tutti i campi')}                  
                  </span>
                </CSVReader>
              </div>
            )}
          </div>

          <form>
            {Array(paxNumber)
              .fill()
              .map((item, index) => {
                return <PaxForm key={`PaxForm-${index}`} p={index} formData={formData} formError={formError} birthState={birthState} onChange={onChange} />;
              })}

            {paxNumber < props.bookingData.service.max_pax && (
              <div className="has-text-centered block">
                <button
                  className="button is-primary is-outlined block"
                  onClick={(e) => {
                    changePaxNumber(paxNumber + 1, e);
                  }}
                >
                  <span className="icon">
                    <i className="fas fa-plus"></i>
                  </span>
                </button>
              </div>
            )}

            <div className="box has-background-light">
              {/* {i18n.language === "en" ? (
                <p className="is-size-7">
                  I, the undersigned, having acquired the information contained in privacy policy under the article 13, EU Regulation 679/2016 (“GDPR”) and
                  having read{" "}
                  <Link className={"has-text-danger"} to="/terms/utenti-prenotazione" target="_blank">
                    the information <i className="fas fa-link"></i>
                  </Link>
                  , agree to my personal data processing, enclosing the data of the other people included in the reservation, according to the procedures and
                  purposes specified in point 1a of this privacy policy, closely related and instrumental to the application of the booking request. I hereby
                  declare to have received the prior consent of those for whom I intend to book, to the processing of their related data..
                </p>
              ) : i18n.language === "de" ? (
                <p className="is-size-7">
                  Der/die Unterzeichnete, welcher/e die{" "}
                  <Link className={"has-text-danger"} to="/terms/utenti-prenotazione" target="_blank">
                    Informationen <i className="fas fa-link"></i>
                  </Link>
                  gemäß Artikel 13 DS-GVO zur Kenntnis genommen hat, willigt der Verarbeitung personenbezogener Daten ein. Die Verarbeitung bezieht sich auf die
                  eigenen Daten und auf Daten Dritter, für welche man eine Buchung vornehmen möchte. Die Daten werden lediglich für die Verwaltung und
                  Durchführung der Buchung verarbeitet. Die Art und den Zweck der Verarbeitung können Sie aus Ziffer 1 entnehmen. Mit der Unterzeichnung stimmen
                  Sie zu, dass Sie vorab auch die Einwilligung zur Datenverarbeitung Dritter eingeholt haben, für welche Sie eine Buchung vornehmen möchten.
                </p>
              ) : (
                <p className="is-size-7">
                  Il/la sottoscritto/a, acquisite le informazioni di cui all’articolo 13, Regolamento (UE) 2016/679 (“GDPR") e letta{" "}
                  <Link className={"has-text-danger"} to="/terms/utenti-prenotazione" target="_blank">
                    l’informativa <i className="fas fa-link"></i>
                  </Link>
                  , acconsente al trattamento dei dati personali, propri e di coloro per conto dei quali si intende prenotare, nelle modalità e per le finalità
                  indicate al punto 1a, strettamente connesse e strumentali alla gestione della sua richiesta di prenotazione. Con la sottoscrizione dichiara
                  altresì di aver acquisito, per quanto di competenza, il preventivo consenso al trattamento dei rispettivi dati da parte di coloro per conto
                  dei quali si intende prenotare.
                </p>
              )} */}

              <div className="legalParagraph">
                <div className="is-size-7">
                  <ReactMarkdown className="has-markdown" linkTarget="_blank">{ i18n.language === "en" ? legal_booking_en : i18n.language === "de" ? legal_booking_de : i18n.language === "fr" ? legal_booking_fr : legal_booking_it}</ReactMarkdown>
                </div>
              </div>

              <div className="column field">
                <div className="control">
                  <label className="radio pt-3">
                    <input type="radio" onChange={onChange} name={`terms`} value={1} /> <b>{t("Acconsento")}</b>
                  </label>
                  <label className="radio">
                    <input type="radio" onChange={onChange} name={`terms`} value={0} /> <b>{t("Nego il consenso")}</b>
                  </label>
                  {formError[`terms`] && <p className="has-text-danger p-1">{formError[`terms`]}</p>}
                </div>
              </div>
            </div>

            <button
              className={`button is-primary block is-large is-link is-fullwidth ${isformLoading && "is-loading"}`}
              disabled={isformLoading}
              type="submit"
              onClick={onSubmit}
            >
              {t("Conferma prenotazione")}
            </button>
          </form>

          {/* <code className="has-text-primary">{JSON.stringify(formData, null, 2)}</code>
          <code className="has-text-primary">{JSON.stringify(props.bookingData, null, 2)}</code> */}
        </div>
      </div>
    </div>
  );
};

export default BookingForm;

const PaxForm = ({ p, onChange, formData, formError, birthState }) => {
  const { t } = useTranslation();

  const _onChange = (e) => {
    onChange(e);
  };

  return (
    <div className="box has-background-light">
      <h4 className="subtitle is-4">
        {p + 1}.{t("Dati partecipante")} { p + 1 === 1 && <span className="tag is-success is-medium">{t("Gestore della prenotazione")}</span> }
      </h4>
      {formError[`generic-${p}`] && <p className="has-text-danger p-2">{formError[`generic-${p}`]}</p>}

      {/* Email */}
      <div className="columns">
        <div className="column field">
          <label className="label">{`Email`}</label>
          <div className="control">
            <input
              className={`input is-normal`}
              type="email"
              placeholder={t(`Email`)}
              name={`email-${p}`}
              value={formData[`email-${p}`] ? formData[`email-${p}`] : ""}
              onChange={_onChange}
            />
            {formError[`email-${p}`] && <p className="has-text-danger p-1">{formError[`email-${p}`]}</p>}
          </div>
        </div>

        {p === 0 && (
          <div className="column field">
            <label className="label">{`Telefono`}</label>
            <div className="control">
              <input
                className={`input is-normal`}
                type="text"
                placeholder={t(`Telefono`)}
                name={`telefono-${p}`}
                value={formData[`telefono-${p}`] ? formData[`telefono-${p}`] : ""}
                onChange={_onChange}
              />
              {formError[`telefono-${p}`] && <p className="has-text-danger p-1">{formError[`telefono-${p}`]}</p>}
            </div>
          </div>
        )}
      </div>

      {/* Nome e Cognome */}
      <div className="columns">
        <div className="column field">
          <label className="label">{t(`Nome`)}</label>
          <div className="control">
            <input
              className={`input is-normal`}
              type="text"
              placeholder={t(`Nome`)}
              name={`firstName-${p}`}
              value={formData[`firstName-${p}`] ? formData[`firstName-${p}`] : ""}
              onChange={_onChange}
            />
            {formError[`firstName-${p}`] && <p className="has-text-danger p-1">{formError[`firstName-${p}`]}</p>}
          </div>
        </div>
        <div className="column field">
          <label className="label">{t(`Cognome`)}</label>
          <div className="control">
            <input
              className={`input is-normal`}
              type="text"
              placeholder={t(`Cognome`)}
              name={`lastName-${p}`}
              value={formData[`lastName-${p}`] ? formData[`lastName-${p}`] : ""}
              onChange={_onChange}
            />
            {formError[`lastName-${p}`] && <p className="has-text-danger p-1">{formError[`lastName-${p}`]}</p>}
          </div>
        </div>
      </div>

      {/* Data di nascita e Nazionalità */}
      <div className="columns">
        <div className="column field">
          <label className="label">{t(`Data di nascita`)}</label>
          <div className="control">
            <DatePicker
              showTimeSelect={false}
              selected={formData[`birthDate-${p}`] ? formData[`birthDate-${p}`] : ""}
              onChange={(data) => _onChange({ target: { name: `birthDate-${p}`, value: data } })}
              className={`input is-normal birthDate-${p}`}
              //maxDate={dayjs().subtract(16, "years")}
              dateFormat={"dd/MM/yyyy"}
              showYearDropdown
              showMonthDropdown
              peekNextMonth
              locale={it}
              dropdownMode="select"
              placeholderText=" GG/MM/AAAA"
            />
            {formError[`birthDate-${p}`] && <p className="has-text-danger p-1">{formError[`birthDate-${p}`]}</p>}
          </div>
        </div>
        <div className="column field">
          <div className="control">
            <p className={`has-text-weight-bold`}>{t(`Nazionalità`)}</p>
            <label className="radio pt-3">
              <input type="radio" onChange={_onChange} name={`foreigner-${p}`} value={"0"} /> {t("Italiano")}
            </label>
            <label className="radio">
              <input type="radio" onChange={_onChange} name={`foreigner-${p}`} value={"1"} /> {t("Straniero")}
            </label>
            {formError[`foreigner-${p}`] && <p className="has-text-danger p-1">{formError[`foreigner-${p}`]}</p>}
          </div>
        </div>
      </div>

      {/* Condizionali Straniero : foreigner=1 */}
      {formData[`foreigner-${p}`] === "0" && (
        <div className="columns">
          <div className="column field is-half">
            <label className="label">{t(`Codice Fiscale`)}</label>
            <input
              className={`input is-normal`}
              type="text"
              placeholder={t(`Codice Fiscale`)}
              name={`CF-${p}`}
              value={formData[`CF-${p}`] ? formData[`CF-${p}`] : ""}
              onChange={_onChange}
            />
            {formError[`CF-${p}`] && <p className="has-text-danger p-1">{formError[`CF-${p}`]}</p>}
          </div>
        </div>
      )}

      {formData[`foreigner-${p}`] === "1" && (
        <div className="columns">
          <div className="column field">
            <label className="label">
              {t(`Stato di Nascita`)}: {formData[`birthState-${p}`]}
            </label>
            <Select
              defaultValue={""}
              name={`birthState-${p}`}
              options={birthState}
              isClearable={true}
              isSearchable={true}
              className={`basic-multi-select`}
              classNamePrefix="select"
              onChange={(data) => _onChange({ target: { name: `birthState-${p}`, value: data !== null ? data.value : "" } })}
              placeholder={t(`Stato di Nascita`)}
            />
            {formError[`birthState-${p}`] && <p className="has-text-danger p-1">{formError[`birthState-${p}`]}</p>}
          </div>
          <div className="column field">
            <div className="control">
              <p className={`has-text-weight-bold`}>{t(`Sesso`)}</p>
              <label className="radio pt-3">
                <input type="radio" onChange={_onChange} name={`gender-${p}`} value={"F"} /> {t("F")}
              </label>
              <label className="radio">
                <input type="radio" onChange={_onChange} name={`gender-${p}`} value={"M"} /> {t("M")}
              </label>
              {formError[`gender-${p}`] && <p className="has-text-danger p-1">{formError[`gender-${p}`]}</p>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
