import React, { useEffect, useState } from "react";
import Breadcumb from "./components/Breadcumb";
import Loader from "./../../template/Loader";

import { getResources, addResource, editResource, deleteResource } from "./../../model/admin";
import { Link } from "react-router-dom";

const Resources = (props) => {
  const [resources, setResources] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");

  useEffect(() => {
    handleGetResources();
  }, []);

  const handleGetResources = async () => {
    setIsLoading(true);
    try {
      const data = await getResources();
      setResources(data);
    } catch (e) {
      setResources([]);
    } finally {
      setIsLoading(false);
    }
  };

  const changeNewName = (e) => {
    setNewName(e.target.value);
  };

  const changeNewDescription = (e) => {
    setNewDescription(e.target.value);
  };

  const openAddModal = (e) => {
    e.preventDefault();
    setModal({ type: "add" });
  };

  const openEditModal = (e, args) => {
    e.preventDefault();
    if (args?.name && args.description) {
      setNewName(args.name);
      setNewDescription(args.description);
    }
    setModal({ type: "edit", ...args });
  };

  const handleAddResource = async (e) => {
    e.preventDefault();
    try {
      const data = await addResource(newName, newDescription);
      if (data.status === "OK") {
        handleGetResources();
        setNewName("");
        setNewDescription("");
        setModal({ type: "notification", subtype: "success", message: data.message, action: "closeModal" });
      } else {
        setModal({ type: "notification", subtype: "error", message: data.message, action: "openAddModal" });
      }
    } catch (e) {
      console.log("resources.js handleAddResource", e);
    }
  };

  const deleteConfirm = (e, args) => {
    e.preventDefault();
    if (modal === false) {
      setModal(args);
    } else {
      setModal(false);
    }
  };

  const handleDeleteResource = async (id) => {
    try {
      const data = await deleteResource(id);
      if (data.status === "OK") {
        setModal({ type: "notification", subtype: "success", message: data.message, action: "closeModal" });
        handleGetResources();
      } else {
        setModal({ type: "notification", subtype: "error", message: data.message, action: "closeModal" });
        //return data;
      }
    } catch (e) {
      console.log("Resources.js handleDeleteResource", e);
    }
  };

  const handleEditResource = async () => {
    try {
      const data = await editResource(newName, newDescription, modal.resourceId);
      console.log(data);
      if (data.status === "OK") {
        setModal({ type: "notification", subtype: "success", message: data.message, action: "closeModal" });
        handleGetResources();
      } else {
        //return data;
        setModal({ type: "notification", subtype: "error", message: data.message, action: "openEditModal", resourceId: modal.resourceId });
      }
    } catch (e) {
      console.log("resources.js handleEditResource", e);
    }
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModal(false);
  };

  const SingleResource = (props) => {

    return (
      <div className="card block">
        <header className="card-header">
          <p className="card-header-title">Attività #{props.id}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <p className="title is-5 is-spaced">{props.name}</p>
            <p className="subtitle is-6">{props.description}</p>
            {/* <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time> */}
          </div>
        </div>
        <footer className="card-footer">
          <a
            href="/"
            className="card-footer-item"
            onClick={(e) => {
              openEditModal(e, { resourceId: props.id, name: props.name, description: props.description });
            }}
          >
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-pencil-alt"></i>
              </span>
              <span className="is-hidden-mobile">Modifica</span>
            </span>
          </a>

          <a href="/" className="card-footer-item" onClick={(e) => deleteConfirm(e, { type: "delete", name: props.name, description: props.description, id: props.id })}>
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-trash-alt"></i>
              </span>
              <span className="is-hidden-mobile">Elimina</span>
            </span>
          </a>

          <Link to={`/admin/services/${props.id}`} className="card-footer-item">
            <span className="is-hidden-mobile">Servizi</span>
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-arrow-right"></i>
              </span>
            </span>
          </Link>
        </footer>
      </div>
    );
  };

  return (
    <div className="has-background-warning p-3 resources">
      <Loader isLoading={isLoading} />

      <div className="container">
        <Breadcumb />

        <h2 className="title is-2">Attività</h2>
        <h4 className="subtitle is-4">Utilizza le attività per suddividere le discipline praticate</h4>

        <div className="columns is-multiline">
          {(resources || []).map((item, index) => {
            return (
              <div className="column is-half" key={`res-${item.id}`}>
                <SingleResource name={item.name} description={item.description} id={item.id} />
              </div>
            );
          })}
        </div>

        {!resources && (
          <div className="block has-text-centered">
            <span>Ancora nessun servizio in questa attività.</span>
          </div>
        )}

        <div className="block has-text-centered p-6">
          <button className="button is-primary is-outlined is-medium is-centered" onClick={openAddModal}>
            <span>Aggiungi una Attività</span>
            <span className="icon is-small">
              <i className="fas fa-plus"></i>
            </span>
          </button>
        </div>

        {modal?.type === "delete" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Attività #{modal.id}</p>
                <button className="delete" aria-label="close" onClick={deleteConfirm}></button>
              </header>
              <section className="modal-card-body">
                Se vuoi davvero eliminare l'attività <b>{modal.name}</b> clicca su Elimina
              </section>
              <footer className="modal-card-foot">
                <button className="button is-danger" onClick={() => handleDeleteResource(modal.id)}>
                  Elimina
                </button>
                <button className="button" onClick={deleteConfirm}>
                  Annulla
                </button>
              </footer>
            </div>
          </div>
        )}

        {modal?.type === "add" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Aggiungi una Attività</p>
                <button className="delete" aria-label="close" onClick={closeModal}></button>
              </header>
              <section className="modal-card-body">
                <form>
                  <div className="field">
                    <div className="control">
                      <input className={`input is-normal`} value={newName} onChange={changeNewName} type="text" placeholder="Titolo" autoFocus="" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <textarea className="textarea" placeholder="Descrizione" rows="3" onChange={changeNewDescription} defaultValue={newDescription} />
                    </div>
                  </div>
                </form>
              </section>
              <footer className="modal-card-foot">
                <button className="button is-success" onClick={handleAddResource}>
                  Aggiungi
                </button>
                <button className="button" onClick={closeModal}>
                  Annulla
                </button>
              </footer>
            </div>
          </div>
        )}

        {modal?.type === "edit" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Modifica attività #{modal.resourceId}</p>
                <button className="delete" aria-label="close" onClick={closeModal}></button>
              </header>
              <section className="modal-card-body">
                <form>
                  <div className="field">
                    <div className="control">
                      <input className={`input is-normal`} value={newName} onChange={changeNewName} type="text" placeholder="Titolo" autoFocus="" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <textarea className="textarea" placeholder="Descrizione" rows="3" onChange={changeNewDescription} defaultValue={newDescription} />
                    </div>
                  </div>
                </form>
              </section>
              <footer className="modal-card-foot">
                <button className="button is-success" onClick={handleEditResource}>
                  Salva
                </button>
                <button className="button" onClick={closeModal}>
                  Annulla
                </button>
              </footer>
            </div>
          </div>
        )}

        {modal?.type === "notification" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="box">
                <div className="block">
                  <p className={`is-size-6 ${modal?.subtype === "error" ? "has-text-danger" : "has-text-success"}`}>{modal?.message}</p>
                </div>
                <button
                  className={`button ${modal?.subtype === "error" ? "is-danger" : "is-success"}`}
                  onClick={
                    modal?.action === "openAddModal" ? openAddModal : modal?.action === "openEditModal" ? (e) => openEditModal(e, { resourceId: modal?.resourceId }) : closeModal
                  }
                >
                  Chiudi
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default Resources;
