const legal_card_de = 
`
**Der/die Unterfertigte bittet um Durchführung der Tesserierung beim Italienischen Raftingverband als "Tourist-Amateur" über den verbunden Verein {{affiliato}}.**

Der/die Unterfertigte erklärt durch Absenden dieses Formulars, dass:
- 1. Er/sie keinerlei Kenntnis einer Pathologie hat, welche eine Kontraindikation für die Tätigkeit darstellen könnte, für welche eine Tesserierung angefragt worden ist;
- 2. Er/sie ausdrücklich alle Bestimmungen des Statuts und des Regelwerks der F.I.Raft akzeptiert und sich verpflichtet diese ausnahmslos zu beachten und erfüllen;
- 3. Er/sie den verbundenen Vereinen und seinen Mitarbeiter ermächtigt, Bilder und/oder Videoaufnahmen durchzuführen und zu verwenden, die ihn/sie darstellen. Er/sie erklärt auch, dass ihm/ihr weder jetzt noch in Zukunft irgendein rechtlicher oder materieller Anspruch für die Verwendung des oben genannten Foto- und/oder Videomaterials zusteht. Das Foto- und/oder Videomaterial kann auch für die die Erstellung von Broschüren, für die Website, Plakate, Albumaufkleber, Kalender verwendet werden, wobei der Nutzer von jeglicher Haftung und Verantwortung ausgeschlossen ist;
- 4. Er/sie in die Verarbeitung der personenbezogenen Daten gemäß Artikel 13 DS-GVO und insbesondere der in den zur Kenntnis genommenen [**Datenschutzhinweisen**](https://firaftbooking.it/terms/utenti-prenotazione) und den unter Ziffer 1 genannten Zwecke und Modalitäten, welche insbesondere für die Verwaltung und Durchführung der im Rahmen der Statuten geregelten Tesserierung notwendig sind, einwilligt;

In Anbetracht der bisherigen Ausführungen und mit Versand dieses Formulars (verpflichtende Voraussetzung für die Teilnahme an der gebuchten sportlichen Tätigkeit) unterzeichnet man die in den Ziffern 1 bis 4 beschriebenen Inhalt gemäß Artikel 1341 ff. des Italienischen Zivilgesetzbuches.
`;

const legal_card_underage_de = 
`
**Der/die Unterzeichnende erklärt in seiner/ihrer Eigenschaft als Erziehungsberechtigter** mit der Übersendung dieses
Formulars, **dass er/sie**:
- 1. im Namen und im Auftrag des Minderjährigen **zu unterschreiben, wie im Mitgliedsantrag und/oder in der
informierten Zustimmung zu der oben genannten Aktivität angegeben**;
- 2. in die Verarbeitung ihrer personenbezogenen Daten gemäß Artikel 13 der DSGVO einzuwilligen, nachdem sie die
 [**Informationen**](https://firaftbooking.it/terms/tutori) in der unter Punkt 1 angegebenen Weise und zu den dort
genannten Zwecken gelesen haben.
In Anbetracht der bisherigen Ausführungen und mit Versand dieses Formulars (verpflichtende Voraussetzung für die
Teilnahme an der gebuchten sportlichen Tätigkeit) unterzeichnet man die in den Ziffern 1 und 2 beschriebenen Inhalt gemäß
Artikel 1341 ff. des Italienischen Zivilgesetzbuches.
`;

const legal_card_marketing_de = 
`
Zudem erklären Sie sich auch mit der unter Ziffer 1b beschrieben Verarbeitung und Übermittlung der personenbezogenen Daten an Dritte zu Marketing- und Werbezwecken einverstanden. Letztere befinden sich in vertraglichen Beziehungen mit dem Italienischen Olympischen Komitee oder Italienischem Raftingverband. Die Verarbeitung ist für die Erfüllung der gesetzlich und vertraglich vorgesehenen Verpflichtungen erforderlich.
`;

module.exports = {legal_card_de, legal_card_underage_de, legal_card_marketing_de};
