import React, { useContext, useEffect } from "react";
import { AppContext } from "./../store/AppContext";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const GuestHead = () => {
  const { context, dispatch } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  const changeLanguage = async (lng) => {
    dispatch({ type: "SET_LANGUAGE", language: lng });
  };

  useEffect(() => {
    i18n.changeLanguage(context.language);
  }, [context.language]);

  return (
    <nav className="navbarLanguage" role="navigation" aria-label="main navigation">
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons has-addons">
            <button onClick={() => changeLanguage("it")} className={`button is-small ${i18n.language !== "en" && i18n.language !== "de" && i18n.language !== "fr" && " is-primary is-outlined is-light is-selected"}`}><img className="it" src="/img/it.png" width={"18"} alt="Italiano" /></button>
            <button onClick={() => changeLanguage("en")} className={`button is-small ${i18n.language === "en" && " is-primary is-outlined is-light is-selected"}`}><img className="it" src="/img/en.png" width={"18"} alt="Inglese" /></button>
            <button onClick={() => changeLanguage("de")} className={`button is-small ${i18n.language === "de" && " is-primary is-outlined is-light is-selected"}`}><img className="it" src="/img/de.png" width={"18"} alt="Tedesco" /></button>
            <button onClick={() => changeLanguage("fr")} className={`button is-small ${i18n.language === "fr" && " is-primary is-outlined is-light is-selected"}`}><img className="it" src="/img/fr.png" width={"18"} alt="Francese" /></button>
            </div>
          </div>
        </div>
    </nav>
  );
};

export default GuestHead;
