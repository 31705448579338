import React, { useEffect, useState } from "react";
import Breadcumb from "./components/Breadcumb";
import Loader from "./../../template/Loader";
import Forbidden from "./../../template/Forbidden";
import "react-input-range/lib/css/index.css";
import { getSlots, addSlot, deleteSlot } from "./../../model/admin";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import config from "./../../config/config.json";

const Slots = (props) => {
  const [resource, setResource] = useState(false);
  const [service, setService] = useState(false);
  const [slots, setSlots] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [newStartDate, setNewStartDate] = useState(new Date());
  const [newEndDate, setNewEndDate] = useState(new Date());
  const [newIsRecurrent, setNewIsRecurrent] = useState(false);
  const [newWeekday, setNewWeekday] = useState([]);
  const [newHours, setNewHours] = useState([]);
  const [isForbidden, setIsForbidden] = useState(false);

  useEffect(() => {
    handleGetSlots();
  }, []);

  const closeModal = (e) => {
    e.preventDefault();
    setModal(false);
  };

  const changeNewWeekday = (v) => {
    setNewWeekday(v);
  };

  const changeNewHours = (v) => {
    setNewHours(v);
  };

  const openAddModal = (e) => {
    e.preventDefault();
    setNewStartDate(new Date());
    setNewEndDate(new Date());
    setNewIsRecurrent(false);
    setNewWeekday([]);
    setNewHours([]);
    setModal({ type: "add" });
  };

  const reOpenAddModal = (e) => {
    e.preventDefault();
    setModal({ type: "add" });
  };

  const openEditModal = (e) => {
    e.preventDefault();
    setNewStartDate(new Date());
    setNewEndDate(new Date());
    setNewIsRecurrent(false);
    setNewWeekday([]);
    setNewHours([]);
    setModal({ type: "add" });
  };

  const handleGetSlots = async () => {
    setIsLoading(true);
    try {
      const data = await getSlots(props.match.params.service_id);
      if (data?.status === "FORBIDDEN"){
        setIsForbidden(true);
      }
      setResource(data.resource);
      setService(data.service);
      setSlots(data.slots);
    } catch (e) {
      
      setResource([]);
      setService([]);
      setSlots([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSlot = async (e) => {
    e.preventDefault();
    try {
      // fix date for no recurrent
      let _startDate = newStartDate;
      if(!newIsRecurrent){
        _startDate = dayjs(_startDate).format("YYYY-MM-DDTHH:mm:ss");
      }

      
      const data = await addSlot(props.match.params.service_id, _startDate, newEndDate, newIsRecurrent, newWeekday, newHours);
      if (data.status === "OK") {
        handleGetSlots();
        setNewStartDate(new Date());
        setNewEndDate(new Date());
        setNewIsRecurrent(false);
        setNewWeekday([]);
        setNewHours([]);
        setModal({ type: "notification", subtype: "success", message: data.message, action: "closeModal" });
      } else {
        setModal({ type: "notification", subtype: "error", message: data.message, action: "reOpenAddModal" });
      }
    } catch (e) {
      console.log("resources.js handleAddService", e);
    }
  };

  const deleteConfirm = (e, args) => {
    e.preventDefault();
    if (modal === false) {
      setModal(args);
    } else {
      setModal(false);
    }
  };

  const handleDeleteService = async (id) => {
    try {
      const data = await deleteSlot(id);
      if (data.status === "OK") {
        setModal({ type: "notification", message: data.message });
        handleGetSlots();
      } else {
        setModal({ type: "notification", message: data.message });
        //return data;
      }
    } catch (e) {
      console.log("Resources.js handleDeleteResource", e);
    }
  };

  const SingleSlot = (props) => {
    return (
      <div className="card block">
        <header className="card-header">
          <p className="card-header-title">Slot #{props.id}</p>
        </header>
        <div className="card-content">
          <div className="content">
            {props.recurrent === 0 && (
              <>
                <p className="is-size-6">
                  Data: <b>{props.start_date}</b>
                </p>
              </>
            )}

            {props.recurrent === 1 && (
              <>
                <p className="is-size-6">
                  Dal <b>{props.start_date}</b> al <b>{props.end_date}</b>
                </p>
                <div className="field is-grouped is-grouped-multiline">
                  {(props.weekdayArray || []).map((tag) => {
                    return <Tags key={"day-" + tag.value} type={"D"} name={"G"} value={tag.label} />;
                  })}
                </div>

                <div className="field is-grouped is-grouped-multiline">
                  {(props.hoursArray || []).map((tag) => {
                    return <Tags key={"hour-" + tag.value} type={"H"} name={"H"} value={tag.label} />;
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <footer className="card-footer">
          {/* <a
            href="/"
            className="card-footer-item"
            onClick={(e) => {
              console.log(e);
            }}
          >
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-pencil-alt"></i>
              </span>
              <span className="is-hidden-mobile">Modifica</span>
            </span>
          </a> */}

          <a href="/" className="card-footer-item" onClick={ (e) => deleteConfirm(e, { type: "delete", id: props.id }) }>
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-trash-alt"></i>
              </span>
              <span className="is-hidden-mobile">Elimina</span>
            </span>
          </a>

          {/* <Link to={`/admin/slots/${props.id}`} className="card-footer-item">
            <span className="is-hidden-mobile">Slots</span>
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-arrow-right"></i>
              </span>
            </span>
          </Link> */}
        </footer>
      </div>
    );
  };

  const Tags = ({ type, name, value }) => {
    return (
      <div className="control">
        <div className="tags has-addons">
          <span className={`tag ${type === "D" ? "" : ""}`}>{name}</span>
          <span className={`tag ${type === "D" ? "is-primary" : "is-link"}`}>{value}</span>
        </div>
      </div>
    );
  };

  if (isForbidden){
    return <Forbidden/>
  }

  return (
    <div className="has-background-warning p-3 services">
      <Loader isLoading={isLoading} />

      <div className="container">
        <Breadcumb service={service} slot={slots.length > 0 && slots[0]} />

        <h2 className="title is-2">Slots</h2>
        <h4 className="subtitle is-4">
          Uno slot definisce le date e gli orari in cui è possibile prenotare un servizio. Uno slot può essere una singola data o può rappresentare date ricorrenti.
        </h4>

        <div slots="columns is-multiline">
          {(slots || []).map((item, index) => {
            return (
              <div className="column is-full" key={`res-${item.id}`}>
                <SingleSlot
                  id={item.id}
                  start_date={ item.recurrent === 0 ? dayjs(item.start_date).format("DD-MM-YYYY HH:mm") : dayjs(item.start_date).format("DD-MM-YYYY")}
                  recurrent={item.recurrent}
                  end_date={dayjs(item.end_date).format("DD-MM-YYYY")}
                  weekday={item.weekday}
                  hours={item.hours}
                  weekdayArray={item.weekdayArray}
                  hoursArray={item.hoursArray}
                />
              </div>
            );
          })}
        </div>

        {!slots && (
          <div className="block has-text-centered">
            <span>Ancora nessuno slot in questo servizio.</span>
          </div>
        )}

        <div className="block has-text-centered p-6">
          <button className="button is-primary is-outlined is-medium is-centered" onClick={openAddModal}>
            <span> Aggiungi uno Slot</span>
            <span className="icon is-small">
              <i className="fas fa-plus"></i>
            </span>
          </button>
        </div>

        {modal?.type === "add" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Aggiungi uno slot</p>
                <button className="delete" aria-label="close" onClick={closeModal}></button>
              </header>
              <section className="modal-card-body">
                <form>
                  <p className="is-size-5">
                    <label className="checkbox">
                      <input type="checkbox" value={newIsRecurrent} onChange={() => setNewIsRecurrent(!newIsRecurrent)} /> Ricorrente?
                    </label>
                  </p>
                  <hr />
                  <div className="field">
                    <p className="is-size-5">{newIsRecurrent ? "Data inizio" : "Data e ora"}</p>
                    <DatePicker
                      showTimeSelect={newIsRecurrent ? false : true}
                      selected={newStartDate}
                      onChange={(date) => setNewStartDate(date)}
                      className="input is-info is-medium"
                      minDate={new Date()}
                      dateFormat={newIsRecurrent ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm"}
                      timeFormat="HH:mm"
                      maxDate={new Date(new Date().getFullYear(), 11, 31)}
                    />
                  </div>
                  {!newIsRecurrent && (
                    <>
                      <hr />
                      <p className="is-size-5 has-text-info ">
                        Utilizzare questo formato per gestire una singola data, abilitare l'opzione <b>Ricorrente</b> per settare uno slot che si ripete nel tempo.
                      </p>
                      <hr />
                    </>
                  )}

                  {newIsRecurrent && (
                    <>
                      <div className="field">
                        <p className="is-size-5">Data fine</p>
                        <DatePicker
                          showTimeSelect={newIsRecurrent ? false : true}
                          selected={newEndDate}
                          onChange={(date) => setNewEndDate(date)}
                          className="input is-info is-medium"
                          minDate={new Date()}
                          dateFormat={newIsRecurrent ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm"}
                          timeFormat="HH:mm"
                          maxDate={new Date(new Date().getFullYear(), 11, 31)}
                        />
                        <hr />
                      </div>

                      <div className="field">
                        <p className="is-size-5">Giorni di ripetizione</p>
                        <Select
                          defaultValue={newWeekday}
                          isMulti
                          name="Giorni e Orari"
                          options={config.days}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={changeNewWeekday}
                        />
                      </div>
                      <div className="field">
                        <p className="is-size-5">Orari di ripetizione</p>
                        <Select
                          defaultValue={newHours}
                          isMulti
                          name="Giorni e Orari"
                          options={config.hours}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={changeNewHours}
                        />
                        <hr />
                      </div>
                    </>
                  )}

                  <div className="buttons">
                    <button className="button is-success" onClick={modal?.type === "add" ? handleAddSlot : null}>
                      Aggiungi
                    </button>
                    <button className="button" onClick={closeModal}>
                      Annulla
                    </button>
                  </div>
                </form>
              </section>

              <footer className="modal-card-foot"></footer>
            </div>
          </div>
        )}

        {modal?.type === "delete" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Servizio #{modal.id}</p>
                <button className="delete" aria-label="close" onClick={deleteConfirm}></button>
              </header>
              <section className="modal-card-body">
                Se elimini lo slot non riceverai nuove prenotazioni, le prenotazioni già confermate rimarranno attive, se vuoi annullarle dovrai farlo da <b>gestione prenotazioni</b>. Se vuoi davvero eliminare la slot <b>#{modal.id}</b> clicca su Elimina
              </section>
              <footer className="modal-card-foot">
                <button className="button is-danger" onClick={() => handleDeleteService(modal.id)}>
                  Elimina
                </button>
                <button className="button" onClick={deleteConfirm}>
                  Annulla
                </button>
              </footer>
            </div>
          </div>
        )}

        {modal?.type === "notification" && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="box">
                <div className="block">
                  <p className={`is-size-6 ${modal?.subtype === "error" ? "has-text-danger" : "has-text-success"}`}>{modal?.message}</p>
                </div>
                <button
                  className={`button ${modal?.subtype === "error" ? "is-danger" : "is-success"}`}
                  onClick={
                    modal?.action === "openAddModal"
                      ? openAddModal
                      : modal?.action === "reOpenAddModal"
                      ? reOpenAddModal
                      : modal?.action === "openEditModal"
                      ? (e) => openEditModal(e, { serviceId: modal?.serviceId })
                      : closeModal
                  }
                >
                  Chiudi
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default Slots;

/*
  `id` int(11) unsigned NOT NULL AUTO_INCREMENT,
  `service_id` int(11) unsigned NOT NULL,
  `start_date` datetime NOT NULL,
  `timezone` varchar(60) NOT NULL,
  `recurrent` tinyint(3) unsigned NOT NULL,
  `end_date` datetime NOT NULL,
  `weekday` varchar(7) DEFAULT NULL,
  `hours` varchar(500) DEFAULT NULL,
*/
