import React, { useEffect, useState, Suspense } from "react";
import Breadcumb from "./components/Breadcumb";
import Loader from "./../../template/Loader";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { getPaymentsList } from "./../../model/admin";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";


const PaymentsList = (props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [theResults, setTheResults] = useState([]);
  const [theData, setTheData] = useState([]);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortType, setSortType] = useState("asc");

  const [chooseDate, setChooseDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


  async function handleGetPaymentsList() {
    const response = await getPaymentsList(dayjs().startOf("year").format("YYYY-MM-DD"), dayjs().endOf("year").format("YYYY-MM-DD"));
    setTheData(response.data.payments);
    setTheResults(response.data.payments);
    console.log(response.data.payments);
  }

  async function handleGetPaymentsListTimeFrame() {
    setChooseDate(false);
    const response = await getPaymentsList(dayjs(startDate).format("YYYY-MM-DD"), dayjs(endDate).format("YYYY-MM-DD"));
    setTheData(response.data.payments);
    setTheResults(response.data.payments);
    console.log(response.data.payments);
  }

  useEffect(() => {
    handleGetPaymentsList();
  }, []);

  async function handleSortColumn(_sortColumn, _sortType) {
    setSortColumn(_sortColumn);
    setSortType(_sortType);

    let _theData = theData;

    console.log(theData);

    _theData.sort((a, b) => {

      if(a[_sortColumn] < b[_sortColumn]) { return _sortType === "asc" ? -1 : 1 }
      if(a[_sortColumn] > b[_sortColumn]) { return _sortType === "asc" ? 1 : -1 }
      return 0;

      // let x = a[_sortColumn];
      // let y = b[_sortColumn];
      // if (typeof x === "string") {
      //   x = x.charCodeAt();
      // }
      // if (typeof y === "string") {
      //   y = y.charCodeAt();
      // }
      // if (_sortType === "asc") {
      //   return x - y;
      // } else {
      //   return y - x;
      // }
    });

    setTheData(_theData);
  }

  async function onlyBulk() {
    let _theData = theResults;
    let filtered = _.filter(_theData, { origine: "bulk" });
    setTheData(filtered);
  }

  async function showAll() {
    setTheData(theResults);
  }

  return (
    <div className="has-background-warning p-3 resources">
      <Loader isLoading={isLoading} />

      <div className="container">
        <Breadcumb />

        <h2 className="title is-2">Lista tessere emesse</h2>

        <div className="content pt-5">
          <h5>Filtri:</h5>
          <div className="buttons">
            <button className="button is-link is-small" onClick={showAll}>
              <span>Tutto</span>
            </button>

            {/* <button className="button is-primary is-small" onClick={()=>setChooseDate(!chooseDate)}>
              <span>Filtra per data</span>
            </button> */}

            <CSVLink data={theData}
              filename={"export.csv"}
              className="button is-primary is-small"
            >Scarica csv</CSVLink>
          </div>

          {/* {chooseDate && (
            <div className="content pb-5">
            <span>Selezionare un range di date, al 1° click la data di inizio ed al 2° la data di fine</span>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              minDate={new Date(new Date().getFullYear(), 0, 1)}
              dateFormat={"dd/MM/yyyy"}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              selectsRange
              inline
            />
            <button className="button is-primary is-small" onClick={handleGetPaymentsListTimeFrame}>
            <span>Filtra per data {dayjs(startDate).format("YYYY-MM-DD")} - {dayjs(endDate).format("YYYY-MM-DD")} </span>
          </button>
          </div>
          )} */}

          <Table
            // virtualized
            className="dataTable"
            autoHeight
            hover
            bordered
            sortColumn={sortColumn}
            sortType={sortType}
            data={theData}
            affixHeader={true}
            onSortColumn={handleSortColumn}
            onRowClick={(data) => {
              console.log(data);
            }}>
            <Column align="center" width={70} resizable fixed sortable>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="booking_id" />
            </Column>
            <Column align="center" width={150} resizable sortable>
              <HeaderCell>Date</HeaderCell>
              <Cell dataKey="date" />
            </Column>
            <Column align="center" width={150} resizable sortable>
              <HeaderCell>Book Date</HeaderCell>
              <Cell dataKey="book_date" />
            </Column>
            <Column align="center" width={60} resizable sortable>
              <HeaderCell>Pax</HeaderCell>
              <Cell dataKey="pax" />
            </Column>
            <Column align="center" width={240} resizable sortable>
              <HeaderCell>Payment Id</HeaderCell>
              <Cell dataKey="payment_id" />
            </Column>
            <Column align="center" width={90} resizable sortable>
              <HeaderCell>€ Tessere</HeaderCell>
              <Cell dataKey="cards_amount" />
            </Column>      
            <Column align="center" width={90} resizable sortable>
              <HeaderCell>€ Ticket</HeaderCell>
              <Cell dataKey="ticket_amount" />
            </Column>      
            <Column align="center" width={90} resizable sortable>
              <HeaderCell>€ Commissioni</HeaderCell>
              <Cell dataKey="payment_fee" />
            </Column>      
            <Column align="center" width={90} resizable sortable>
              <HeaderCell>€ Incasso netto</HeaderCell>
              <Cell dataKey="net_amount" />
            </Column>     
            <Column align="center" width={90} resizable sortable>
              <HeaderCell>Nome gestore prenotazione</HeaderCell>
              <Cell dataKey="nome" />
            </Column>                  
            <Column align="center" width={90} resizable sortable>
              <HeaderCell>Cognome gestore prenotazione</HeaderCell>
              <Cell dataKey="cognome" />
            </Column>     
            <Column align="center" width={250} resizable sortable>
              <HeaderCell>Email gestore prenotazione</HeaderCell>
              <Cell dataKey="email" />
            </Column>    
            <Column align="center" width={120} resizable sortable>
              <HeaderCell>Telefono gestore prenotazione</HeaderCell>
              <Cell dataKey="telefono" />
            </Column>    
            <Column align="center" width={220} resizable sortable>
              <HeaderCell>Cancellazione (se cancellata)</HeaderCell>
              <Cell dataKey="cancellation_date" />
            </Column>                            
          </Table>
       
        </div>
      </div>
      {/* <pre>{JSON.stringify(theResults, null, 2)}</pre> */}
    </div>
  );
};

export default PaymentsList;
