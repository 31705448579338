import axios from "axios";
import config from "../config/config.json";

const base = process?.env?.NODE_ENV === "development" ? config.devApiBaseUrl : config.apiBaseUrl;

const fetchAuthApi = axios.create();
fetchAuthApi.interceptors.request.use(async (axiosConfig) => {
  axiosConfig.baseURL = base;
  return axiosConfig;
});


const fetchLogin = async(email, password)=>{
  const body = {email, password};
  try{
    const response = await fetchAuthApi.post("auth/login", body);
    if(response.status === 200){
      return {status: "OK", token: response.data.token, email: response.data.email };
    }else{
      return {status: "KO", error: {generic:"Si è verificato un errore" }};
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data}
  }
}

const fetchForgot = async(email)=>{
  let baseUrl = window.location.origin;
  const body = {email, baseUrl};
  try{
    const response = await fetchAuthApi.post("auth/forgot-password", body);
    if(response.status === 200){
      return {status: "OK", message: response?.data?.success};
    }else{
      return {status: "KO", error: {generic:"Si è verificato un errore" }};
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data}
  }
}


const fetchChangePassword = async (newPassword, actionToken) => {
  const body = { newPassword, actionToken };
  try {
    const response = await fetchAuthApi.post("auth/change-password", body);
    if(response.status === 200){
      return {status: "OK", message: response?.data?.success};
    }else{
      return {status: "KO", error: {generic:"Si è verificato un errore" }};
    }
  } catch (e) {
    if (e?.response?.data?.error?.name === "TokenExpiredError"){
      return {status: "KO", error: {generic:"Codice di autorizzazione scaduto, generano uno nuovo e riprova."}};
    }else{
      return {status: "KO", error: e?.response?.data};
    }
  }
};




export {fetchLogin, fetchForgot, fetchChangePassword};