import React from "react";

const AdmFoot = () => {

  return (
<footer className="footer">
  <div className="content has-text-centered">
    <p>
      <strong>FIRAFT Bookings</strong> by <a href="https://www.beinternet.it/" target="_blank" rel="noreferrer">Be Internet</a>.
      <span className="is-size-7 mx-2 has-text-grey-lighter">CS04</span>
    </p>
  </div>
</footer>
  );
};

export default AdmFoot;
