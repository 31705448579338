import React from "react";
import {Link} from "react-router-dom"
import { useTranslation } from 'react-i18next';

const Error404 = (props) => {
  const { t } = useTranslation();

  return (
    <div className="hero is-fullheight is-danger">
      <div className="hero-body">
      <div className="container has-text-centered">
            <div className="column is-6 is-offset-3">
              <img className="headLogo" src="/img/firaft-logo.png" alt="FIRAFT" />
              <h2 className="title is-2">{t("Pagina non trovata")}</h2>
              { props.mode === "admin" &&
                <Link className="button is-danger is-light is-medium is-centered" to={"/admin/dashboard"}>Torna alla dashboard</Link>              
              }
            </div>
          </div>
      </div>
    </div>
  );
};

export default Error404;
