import axios from "axios";
import config from "../config/config.json";
import _ from "lodash";

const base = process?.env?.NODE_ENV === "development" ? config.devApiBaseUrl : config.apiBaseUrl;

const fetchPrivacyApi = axios.create();
fetchPrivacyApi.interceptors.request.use(async (axiosConfig) => {
  axiosConfig.baseURL = base;
  return axiosConfig;
});


const fetchPrivacy = async(args)=>{
  try{
    const response = await fetchPrivacyApi.get(`privacy/${args.token}`);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}

const savePrivacy = async(args)=>{
  try{
    const response = await fetchPrivacyApi.post(`privacy/${args.token}`, args.body);
    if(response.status === 200){
      return {status: "OK", data: response.data };
    }else{
      return {status: "KO", error: response.data.error };
    }
  }catch(e){
    return {status: "KO", error: e?.response?.data.error}
  }
}

export {fetchPrivacy, savePrivacy};